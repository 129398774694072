import React, { useState } from "react";
import {
  ChevronDownIcon,
  CubeIcon,
  PlusIcon,
  DocumentDuplicateIcon,
  TrashIcon,
  PencilIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline";
import { Popconfirm } from "antd";
import BoxTypePopover from "./BoxTypePopover";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";

const PlannedBox = ({
  displayName,
  dimensions,
  onDuplicate,
  onDelete,
  onAddItems,
  items = [],
  boxIndex,
  orderId,
  onUpdateItems,
  onUpdateWeight,
  onSelectBoxType,
  boxTypes,
}) => {
  const [isEditingItems, setIsEditingItems] = useState(false);
  const [editableItems, setEditableItems] = useState([]);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [totalWeightInput, setTotalWeightInput] = useState(0);

  const {
    length,
    width,
    height,
    weight = 0,
    totalWeight = 0,
    type = "Custom Box",
  } = dimensions;

  const handleDeleteItem = (uniqueId) => {
    setEditableItems((prevItems) =>
      prevItems.filter((item) => item.uniqueId !== uniqueId),
    );
  };

  const handleSaveItems = () => {
    const updatedItems = editableItems.filter((item) => item.editQuantity > 0);
    onUpdateItems(orderId, boxIndex, updatedItems);
    setIsEditingItems(false);
  };

  const handleEditItems = () => {
    setIsEditingItems(true);
    setEditableItems(
      items.map((item) => ({ ...item, editQuantity: item.packQuantity })),
    );
  };

  const handleCancelEdit = () => {
    setIsEditingItems(false);
  };

  const handleQuantityChange = (uniqueId, value) => {
    const newValue = Math.max(
      0,
      Math.min(
        value,
        items.find((item) => item.uniqueId === uniqueId).quantity,
      ),
    );
    setEditableItems((prevItems) =>
      prevItems.map((item) =>
        item.uniqueId === uniqueId ? { ...item, editQuantity: newValue } : item,
      ),
    );
  };

  return (
    <div className="mt-4 rounded-lg border border-gray-200 p-4">
      <div className="mb-4 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <CubeIcon className="h-5 w-5 text-gray-500" />
          <span className="font-medium">{displayName}</span>
        </div>
        <div className="flex gap-2">
          <PrimaryButton
            height="2rem"
            minWidth="7rem"
            variant="secondary"
            onClick={() => onAddItems(orderId, boxIndex)}
            className="flex items-center justify-center p-0 hover:bg-gray-100">
            <span>Add items</span>
            <PlusIcon className="ml-2 h-5 w-5 text-gray-500" />
          </PrimaryButton>
          <button
            onClick={onDuplicate}
            className="rounded p-1 hover:bg-gray-100"
            title="Duplicate box">
            <DocumentDuplicateIcon className="h-5 w-5 text-gray-500" />
          </button>
          <button
            onClick={onDelete}
            className="rounded p-1 hover:bg-gray-100"
            title="Delete box">
            <TrashIcon className="h-5 w-5 text-red-500" />
          </button>
        </div>
      </div>

      <div className="mt-4 grid grid-cols-7 justify-center gap-4">
        <div>
          <div className="text-sm text-gray-500">Box Type</div>
          <div className="mt-2 font-medium">{type}</div>
        </div>
        <div>
          <div className="text-sm text-gray-500">Length</div>
          <div className="mt-2 font-medium">{length}</div>
        </div>
        <div>
          <div className="text-sm text-gray-500">Width</div>
          <div className="mt-2 font-medium">{width}</div>
        </div>
        <div>
          <div className="text-sm text-gray-500">Height</div>
          <div className="mt-2 font-medium">{height}</div>
        </div>
        <div>
          <div className="text-sm text-gray-500">Box Weight</div>
          <div className="mt-2 font-medium">{weight}</div>
        </div>
        <div>
          <div className="text-sm text-gray-500">Total Weight</div>
          <input
            type="number"
            min="0"
            step="0.1"
            className="mt-2 w-20 rounded border border-gray-300 p-1 [appearance:textfield] focus:border-primaryAccent focus:ring focus:ring-primaryAccent [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
            value={totalWeight}
            onChange={(e) => {
              const newTotalWeight = parseFloat(e.target.value);
              // const newBoxWeight = Math.max(
              //   0,
              //   newTotalWeight - totalItemWeight,
              // );
              onUpdateWeight(orderId, boxIndex, newTotalWeight, true);
            }}
          />
        </div>
        <div>
          <div className="text-sm text-gray-500">Action</div>
          <BoxTypePopover
            boxTypes={boxTypes}
            onSelectBox={(boxType) => {
              onSelectBoxType(orderId, boxIndex, boxType);
              setIsPopoverOpen(false);
            }}
            boxIndex={boxIndex}
            isOpen={isPopoverOpen}
            setIsOpen={setIsPopoverOpen}
            trigger={
              <button
                className="mt-2 rounded p-1 hover:bg-gray-100"
                title="Edit box dimensions"
                onClick={() => setIsPopoverOpen(true)}>
                <PencilIcon className="h-5 w-5 text-gray-500" />
              </button>
            }
          />
        </div>
      </div>

      <div className="mt-4">
        <div className="flex items-center">
          <span className="text-sm text-gray-500">Packed Items:</span>
          <button
            onClick={handleEditItems}
            className="ml-2 rounded p-1 hover:bg-gray-100"
            title="Edit packed items">
            <PencilIcon className="h-4 w-4 text-gray-500" />
          </button>
        </div>
        {items?.length > 0 ? (
          <div className="mt-2">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left text-sm font-normal text-gray-500">
                    Item
                  </th>
                  <th className="text-right text-sm font-normal text-gray-500">
                    Quantity
                  </th>
                  {isEditingItems && <th className="w-10"></th>}
                </tr>
              </thead>
              <tbody>
                {(isEditingItems ? editableItems : items).map((item) => (
                  <tr key={item.uniqueId} className="border-t border-gray-100">
                    <td className="py-2 text-sm text-gray-700">{item.name}</td>
                    <td className="py-2 text-right text-sm text-gray-600">
                      {isEditingItems ? (
                        <input
                          type="number"
                          min="0"
                          max={item.quantity}
                          className="w-20 rounded border border-gray-300 p-1 text-right focus:border-primaryAccent focus:ring focus:ring-primaryAccent"
                          value={item.editQuantity}
                          onChange={(e) =>
                            handleQuantityChange(
                              item.uniqueId,
                              parseInt(e.target.value) || 0,
                            )
                          }
                        />
                      ) : (
                        item.packQuantity
                      )}
                    </td>
                    {isEditingItems && (
                      <td className="py-2 text-right">
                        <Popconfirm
                          title="Delete this item?"
                          onConfirm={() => handleDeleteItem(item.uniqueId)}
                          okText="Yes"
                          cancelText="No">
                          <button
                            className="rounded p-1 hover:bg-gray-100"
                            title="Remove item from box">
                            <TrashIcon className="h-4 w-4 text-red-500" />
                          </button>
                        </Popconfirm>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            {isEditingItems && (
              <div className="mt-4 flex justify-end gap-2">
                <PrimaryButton
                  height="2rem"
                  width="5rem"
                  variant="secondary"
                  onClick={handleCancelEdit}>
                  Cancel
                </PrimaryButton>
                <PrimaryButton
                  height="2rem"
                  width="5rem"
                  variant="primary"
                  onClick={handleSaveItems}>
                  Save
                </PrimaryButton>
              </div>
            )}
          </div>
        ) : (
          <div className="mt-1 text-gray-700">No items added yet</div>
        )}
      </div>
    </div>
  );
};

export default PlannedBox;
