import { useEffect, useState } from "react";
import _ from "lodash";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { useQuery } from "#hooks/useQuery";
import { transformCostPrice } from "#utils/helper-functions";
import CatalogDefaultIcon from "#static/images/catalogplaceholder.png";
import Checkbox from "#components/utils/Checkbox";
import CatalogProductActions from "./CatalogProductActions";
import { GET_PRODUCT_VARIANT_COMPONENT } from "#queries";
import PriceBreakdownPopOver from "./PriceBreakdownPopOver";

export default function GridViewProducts({
  products,
  selectProduct,
  selectedProducts,
  selectAllRows,
  allRowsSelected,
  getCatalog,
  actionsOnProduct,
  isRemoveSelectAllCheckBox,
  showSalesAndCostPrice,
  filters,
  fromAddEditCatalog = false,
  setlockedInPriceForSingleProduct = () => {},
  setAllVariantComponent = () => {},
  selectedCatalog,
  openCatalogAddEditModal,
}) {
  const [shiftKeyPressed, setShiftKeyPressed] = useState(false);
  const [variantComponentIds, setVariantComponentIds] = useState([]);
  const [allVariantComponents, setAllVariantComponents] = useState([]);

  const productComponentQuery = useQuery(GET_PRODUCT_VARIANT_COMPONENT);

  const fetchProductVariantComponent = async (variantIds) => {
    try {
      const productComponentResponse = await productComponentQuery.fetchData({
        perPage: variantIds.length,
        pageNumber: 1,
        filters: {
          componentId: variantIds,
        },
      });

      if (
        productComponentResponse?.data?.getProductVariantComponent?.entities
          ?.length > 0
      ) {
        setAllVariantComponents(
          productComponentResponse.data.getProductVariantComponent.entities,
        );
      }
    } catch (e) {}
  };

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Shift") {
        setShiftKeyPressed(true);
      }
    }

    function handleKeyUp(event) {
      if (event.key === "Shift") {
        setShiftKeyPressed(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    if (products?.length > 0) {
      const allVariantComponents = new Set();

      products.forEach((product) => {
        const costPriceDetails = product?.costPrice?.length
          ? product?.costPrice
          : null;

        if (!costPriceDetails) return;

        const hasComponentDetails = costPriceDetails?.some(
          (item) => item?.componentDetails,
        );

        const allVariantComponentsId = hasComponentDetails
          ? costPriceDetails[0]?.componentDetails?.map((item) => item?.id) || []
          : Object.keys(costPriceDetails[0]).filter((key) =>
              _.isObject(costPriceDetails[0][key]),
            );

        allVariantComponentsId?.forEach((id) => allVariantComponents?.add(id));
      });

      const uniqueVariantComponents = Array?.from(allVariantComponents);

      const mergedVariantComponents = Array?.from(
        new Set([...variantComponentIds, ...uniqueVariantComponents]),
      );

      if (!_.isEqual(variantComponentIds, mergedVariantComponents)) {
        setVariantComponentIds(mergedVariantComponents);
      }
    }
  }, [products, variantComponentIds, fromAddEditCatalog]);

  useEffect(() => {
    if (variantComponentIds.length > 0) {
      fetchProductVariantComponent(variantComponentIds);
    }
  }, [variantComponentIds]);

  useEffect(() => {
    if (allVariantComponents?.length > 0) {
      setAllVariantComponent(allVariantComponents);
    }
  }, [allVariantComponents]);

  return (
    <>
      <div className="flex space-x-2 p-2">
        {!isRemoveSelectAllCheckBox && (
          <>
            <Checkbox
              role="checkbox"
              onChange={selectAllRows}
              name="selectAllRows"
              checked={allRowsSelected}
            />
            <div className="text-[#717679 ] text-lg">Select All</div>
          </>
        )}
      </div>
      <ul
        role="list"
        className="mb-8 mt-2 grid h-[51vh] grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {products.map((product, rowIndex) => {
          const costPrice = {};
          if (product?.costPrice?.length === 1) {
            const hasComponentDetails = product?.costPrice?.some((item) =>
              item.hasOwnProperty("componentDetails"),
            );
            if (hasComponentDetails) {
              const costPrice = transformCostPrice(product.costPrice, false);
              product.costPrice = costPrice;
            }
            for (const costPriceKey in product.costPrice[0]) {
              if (costPriceKey !== "currency") {
                let componentPrice;
                if (!_.isObject(product.costPrice[0][costPriceKey])) {
                  componentPrice = Number(product.costPrice[0][costPriceKey]);
                  if (costPriceKey !== "discount" && costPriceKey !== "total") {
                    costPrice[costPriceKey] = componentPrice / 100;
                  } else costPrice[costPriceKey] = componentPrice;
                } else {
                  const selectedComponent = allVariantComponents.find(
                    (variantObject) => variantObject.id === costPriceKey,
                  );
                  if (selectedComponent) {
                    componentPrice =
                      (Number(selectedComponent?.price || "") /
                        Number(selectedComponent?.quantity || "")) *
                      Number(product.costPrice[0][costPriceKey].quantity || "");
                    costPrice[selectedComponent?.componentName] =
                      componentPrice / 100;
                  }
                }
                if (costPriceKey !== "discount") {
                  if (costPriceKey !== "total") {
                    costPrice["total"] =
                      (costPrice["total"] || 0) + componentPrice;
                  }
                }
              } else
                costPrice[costPriceKey] = product.costPrice[0][costPriceKey];
            }
            if (costPrice?.discount) {
              costPrice.total =
                (costPrice.total - (costPrice?.discount || 0)) / 100;
              costPrice.discount = costPrice?.discount / 100;
            } else costPrice.total = Number(costPrice.total || 0) / 100;
          }
          return (
            <li
              key={product.id}
              className="relative col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg border border-[#DDDFE0] bg-white text-center">
              <div className="absolute p-4">
                <Checkbox
                  role="checkbox"
                  onChange={(_) => {
                    selectProduct(product.id, false, products);
                  }}
                  name="product"
                  value={product.id}
                  checked={selectedProducts?.includes(product.id)}
                />
              </div>
              <div
                className="cursor-pointer p-1"
                onClick={() => getCatalog(product.id)}>
                <div className="flex flex-1 flex-col">
                  <img
                    className="mx-auto h-52 w-full shrink-0"
                    src={product.images?.[0]?.url || CatalogDefaultIcon}
                    alt=""
                  />
                </div>
                <div className="flex items-start justify-start truncate text-left capitalize">
                  <div className="space-y-1 p-2">
                    <div className="flex flex-col gap-2">
                      <h3 className="w-auto max-w-[200px] truncate text-sm text-lightGray">
                        SKU: {product.sku}
                      </h3>
                      {product?.source && openCatalogAddEditModal && (
                        <h3 className="w-auto max-w-[200px] truncate text-sm text-lightGray">
                          Source: {product?.source}
                        </h3>
                      )}
                      <p className="w-auto max-w-[200px] truncate text-base font-medium text-[#111827]">
                        {truncate(product.name)}
                      </p>
                    </div>
                    {(fromAddEditCatalog || !filters?.catalog?.length) &&
                      showSalesAndCostPrice && (
                        <div>
                          <div className="flex-col space-y-1">
                            <div className="flex items-center space-x-2">
                              <span className="text-xs text-[#111827]">
                                Cost Price:{" "}
                              </span>
                              <span className="text-xs font-semibold text-[#111827]">
                                {costPrice?.total ? (
                                  <PriceBreakdownPopOver
                                    data={""}
                                    costPrice={costPrice}
                                    type="costPrice"
                                    showPopup={false}
                                  />
                                ) : (
                                  "N/A"
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    {!fromAddEditCatalog && filters?.catalog?.length > 0 && (
                      <div>
                        <div className="flex-col space-y-1">
                          <div className="flex space-x-2">
                            <span className="text-xs text-[#111827]">
                              Dynamic Sales Price:{" "}
                            </span>
                            <span className="text-xs font-semibold text-[#111827]">
                              {costPrice?.total && product?.salesPrice ? (
                                <PriceBreakdownPopOver
                                  data={product?.salesPrice}
                                  costPrice={costPrice}
                                  type="sales"
                                  selectedCatalog={selectedCatalog}
                                  showPopup={false}
                                />
                              ) : (
                                "N/A"
                              )}
                            </span>
                          </div>
                          <div className="flex space-x-2">
                            <span className="text-xs text-[#111827]">
                              Locked In Price:
                            </span>
                            <span className="text-xs font-semibold text-[#111827]">
                              {costPrice?.total > 0 &&
                              product?.lockedInPrice ? (
                                <PriceBreakdownPopOver
                                  data={product?.lockedInPrice}
                                  costPrice={costPrice}
                                  selectedCatalog={selectedCatalog}
                                  type="lockedIn"
                                  showPopup={false}
                                />
                              ) : (
                                "N/A"
                              )}
                            </span>
                          </div>
                          <div className="flex space-x-2">
                            <span className="text-xs text-[#111827]">
                              Lock In Date:
                            </span>
                            <span className="text-xs font-semibold text-[#111827]">
                              {costPrice?.total > 0 &&
                              product?.lockedInPrice ? (
                                <PriceBreakdownPopOver
                                  data={product?.lockedInPrice}
                                  selectedCatalog={selectedCatalog}
                                  type=""
                                  showPopup={false}
                                />
                              ) : (
                                "N/A"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {Boolean(Number(costPrice?.total)) &&
                      fromAddEditCatalog &&
                      selectedProducts?.includes(product?.id) && (
                        <div>
                          <button
                            className="flex items-center space-x-1 text-sm text-primaryAccent hover:text-primaryAccent"
                            onClick={() =>
                              setlockedInPriceForSingleProduct(product)
                            }>
                            <span>Set Locked In Price</span>
                            <InformationCircleIcon className="h-5 w-5 cursor-pointer text-primaryAccent hover:text-primaryAccent" />
                          </button>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="absolute -top-2 right-0 pr-4">
                <CatalogProductActions
                  actionsOnProduct={actionsOnProduct}
                  product={product}
                />
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
}

function truncate(str) {
  return str?.length > 20 ? str.substring(0, 20) + "..." : str;
}
