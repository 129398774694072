import React, { useContext, useState } from "react";
import { Popover } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import ModalV3 from "#components/utils/ModalV3";
import _ from "lodash";

const PricePopover = ({
  data,
  type,
  customerList = [],
  labelFormatter = (length) => `${String(length).padStart(2, "0")} Price`,
}) => {
  const [showAllChannels, setShowAllChannels] = useState(false);
  const [searchSalesChannel, setSearchSalesChannel] = useState("");
  const isCostPrice = type === "cost";
  let salesPriceWithChannelsName = [];
  let newCostPriceData = {};

  if (isCostPrice && data && !_.isEmpty(data)) {
    newCostPriceData = { ...data };
    if (
      !_.size(_.omit(newCostPriceData, ["currency", "discount", "total"])) > 0
    ) {
      newCostPriceData = {
        ...newCostPriceData,
        "Cost Price":
          Number(newCostPriceData?.total || 0) +
          Number(newCostPriceData?.discount || 0),
      };
    }
    if (newCostPriceData) {
      if (_.has(newCostPriceData, "discount")) {
        const { discount, ...restComponent } = { ...newCostPriceData };
        newCostPriceData = {
          ...restComponent,
          Discount: discount,
        };
      }
    }
  }

  if (!isCostPrice && data && data.length > 0) {
    salesPriceWithChannelsName = data.map((salesPriceObject) => {
      return {
        ...salesPriceObject,
        channelName:
          customerList.find(
            (customerObject) =>
              customerObject.id === salesPriceObject?.salesChannel,
          )?.name || "",
      };
    });
  }

  const getSalesPriceWithChannel = (getAllData = false) => {
    const showSalesChannelWithData = [...salesPriceWithChannelsName];
    if (!getAllData && data.length > 4) {
      showSalesChannelWithData.splice(4);
    }
    return showSalesChannelWithData
      ?.filter((salesPriceObject) =>
        salesPriceObject?.channelName
          ?.toLowerCase()
          .includes((searchSalesChannel || "").trim().toLowerCase()),
      )
      .map((salesPriceObject, index) => {
        let total;
        if (!_.has(salesPriceObject, "salesChannelTotal")) {
          total =
            _.values(
              _.omit(salesPriceObject, [
                "salesChannel",
                "currency",
                "discount",
                "channelName",
              ]),
            ).reduce((accumulator, value) => accumulator + value, 0) / 100;

          if (salesPriceObject?.discount) {
            total = (total * 100 - (salesPriceObject.discount || "")) / 100;
          }
        } else total = salesPriceObject.salesChannelTotal / 100;
        return (
          <div key={`sales-channel-price-${index}`}>
            <div className={`mx-3 mb-3 mt-4`}>
              <div className="flex justify-between gap-3">
                <div className="max-w-64 overflow-hidden text-ellipsis whitespace-nowrap text-sm font-normal">
                  {salesPriceObject.channelName}
                </div>
                <div className="text-base font-medium">{`$${Number(
                  total,
                ).toFixed(2)}`}</div>
              </div>
            </div>
            {((!getAllData &&
              (index !== showSalesChannelWithData.length - 1 ||
                salesPriceWithChannelsName.length > 4)) ||
              (getAllData &&
                index !== showSalesChannelWithData.length - 1)) && (
              <div className="mx-3 border-b border-[#DADBDC]"></div>
            )}
            {!getAllData &&
              index === showSalesChannelWithData.length - 1 &&
              salesPriceWithChannelsName.length > 4 && (
                <div
                  onClick={() => setShowAllChannels(true)}
                  className="my-2 cursor-pointer text-center text-sm font-semibold leading-6 text-blueBackground">
                  View More
                </div>
              )}
          </div>
        );
      });
  };

  return (
    <>
      <Popover className="relative bg-white">
        {({ open }) => (
          <div
            onMouseEnter={(e) =>
              e.currentTarget.querySelector("button")?.click()
            }
            onMouseLeave={(e) =>
              e.currentTarget.querySelector("button")?.click()
            }
            className="relative">
            {isCostPrice
              ? newCostPriceData.total > 0 && (
                  <Popover.Button>{`${newCostPriceData?.currency} ${Number(
                    newCostPriceData.total,
                  ).toFixed(2)}`}</Popover.Button>
                )
              : newCostPriceData.length > 0 && (
                  <Popover.Button>
                    <div className="text-base font-normal text-primaryAccent">
                      {labelFormatter(newCostPriceData.length)}
                    </div>
                  </Popover.Button>
                )}

            <Popover.Panel className="absolute z-10 max-h-80 overflow-y-auto">
              <div className="w-auto rounded-xl border border-borderGray bg-white">
                {isCostPrice ? (
                  <div className="mx-4 mb-4 mt-2">
                    <div className="flex justify-between gap-8 text-sm font-normal">
                      <div>
                        {_.keys(
                          _.omit(newCostPriceData, ["total", "currency"]),
                        ).map((breakdownName, index) => (
                          <div
                            key={`costPriceBreakdown-${index}`}
                            className={`mb-2 max-w-64 overflow-hidden text-ellipsis whitespace-nowrap ${breakdownName === "Discount" ? "text-green-400" : "text-[#6E7173]"}`}>
                            {breakdownName}:
                          </div>
                        ))}
                      </div>
                      <div>
                        {_.values(
                          _.omit(newCostPriceData, ["total", "currency"]),
                        ).map((breakdownValue, index) => (
                          <div
                            key={`costPriceBreakdown-value-${index}`}
                            className={`mb-2 ${
                              newCostPriceData?.Discount &&
                              _.values(
                                _.omit(newCostPriceData, ["total", "currency"]),
                              )?.length -
                                1 ===
                                index
                                ? "text-green-400"
                                : ""
                            }`}>
                            {`$${Number(breakdownValue).toFixed(2)}`}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      className={
                        _.values(
                          _.omit(newCostPriceData, ["total", "currency"]),
                        )?.length > 0
                          ? "border-t border-[#DADBDC]"
                          : ""
                      }></div>
                    <div className="mt-2 flex justify-between gap-2 text-sm font-medium">
                      <div className="text-[#6E7173]">Total:</div>
                      <div>{`$${Number(newCostPriceData.total).toFixed(2)}`}</div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="flex justify-between gap-20 bg-neutral-50 px-3 py-4 font-inter text-sm font-medium text-[#717679]">
                      <div>Sales Channel</div>
                      <div>Pricing</div>
                    </div>
                    {getSalesPriceWithChannel()}
                  </div>
                )}
              </div>
            </Popover.Panel>
          </div>
        )}
      </Popover>
      {showAllChannels && (
        <ModalV3
          isOpen={showAllChannels}
          onClose={() => {
            setShowAllChannels(false);
            if (searchSalesChannel) setSearchSalesChannel("");
          }}
          title="Sales Channel Price">
          <div className="min-w-3xl modalV3 p-4">
            <div className="flex items-center justify-between pb-4 font-inter">
              <span className="font-inter text-2xl font-bold text-[#454A4F]">
                Sales Channel with Price
              </span>
              <XIcon
                className="h-6 w-6 cursor-pointer"
                onClick={() => {
                  setShowAllChannels(false);
                  if (searchSalesChannel) setSearchSalesChannel("");
                }}
              />
            </div>
            <input
              type="search"
              name="search"
              id="search"
              className="mb-4 block w-full rounded-md border-0 py-2 text-sm text-lightGray ring-1 ring-inset ring-gray-300 placeholder:text-lightGray focus:ring-2 focus:ring-inset focus:ring-primaryAccent"
              placeholder="Search Sales channel"
              onChange={(e) => {
                setSearchSalesChannel(e.target.value);
              }}
              value={searchSalesChannel}
            />
            <div className="max-h-80 min-h-72 overflow-y-auto">
              <div className="flex justify-between gap-20 bg-neutral-50 px-3 py-4 font-inter text-sm font-medium text-[#717679]">
                <div>Sales Channel</div>
                <div>Pricing</div>
              </div>
              {getSalesPriceWithChannel(true)}
            </div>
          </div>
        </ModalV3>
      )}
    </>
  );
};

export default PricePopover;
