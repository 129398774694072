import { useReducer, createContext } from "react";
import _ from "lodash";

const initialState = {
  entities: [],
  total: 0,
  displayEntities: [],
  perPage: 25,
  pageNumber: 1,
  selectedEntities: [],
  filters: {},
  sort: "-createdAt",
  unreadTotal: 0,
  notification: "",
  markAsRead: false,
  notificationLoader: true,
  selectedTab: "ALL",
  notificationAlertsTabs: [],
};

const NotificationContext = createContext(initialState);

function notificationReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "SET_ENTITIES": {
      const { entities, total, pageNumber, paginated, ...rest } = payload;
      return {
        ...state,
        entities: paginated ? [...state.entities, ...entities] : [...entities],
        total,
        displayEntities: entities,
        pageNumber,
        ...rest,
      };
    }
    case "PAGINATE": {
      const { pageNumber } = payload;
      const { perPage, entities } = state;
      return {
        ...state,
        pageNumber,
        displayEntities: entities.slice(
          (pageNumber - 1) * perPage,
          pageNumber * perPage,
        ),
      };
    }
    case "SET_FILTER": {
      return {
        ...state,
        filters: payload,
      };
    }
    case "RESET_ENTITIES": {
      return {
        ...initialState,
      };
    }
    case "SET_PER_PAGE": {
      return {
        ...state,
        perPage: payload.perPage,
      };
    }
    case "SET_SORT": {
      return {
        ...state,
        sort: payload.sort,
      };
    }
    case "SET_MARK_AS_READ": {
      const { markAsRead } = payload;
      return {
        ...state,
        markAsRead: markAsRead,
      };
    }
    case "SET_PAGE_NUMBER": {
      const { pageNumber, total, unreadTotal } = payload;
      return {
        ...state,
        pageNumber,
        total,
        unreadTotal,
        selectedTab: payload?.selectedTab ? payload?.selectedTab : "ALL",
      };
    }
    case "SET_NOTIFICATION_lOADER": {
      const { notificationLoader } = payload;
      return {
        ...state,
        notificationLoader,
      };
    }
    case "SET_NOTIFICATIONS_TABS": {
      const { notificationAlertsTabs } = payload;
      return {
        ...state,
        notificationAlertsTabs,
        selectedTab: payload?.selectedTab ? payload?.selectedTab : "ALL",
      };
    }
    default:
      return state;
  }
}

function NotificationProvider(props) {
  const [state, dispatch] = useReducer(notificationReducer, initialState);

  function resetEntities() {
    dispatch({
      type: "RESET_ENTITIES",
    });
  }

  function setEntities(payload) {
    dispatch({
      type: "SET_ENTITIES",
      payload,
    });
  }

  function paginate(payload) {
    dispatch({
      type: "PAGINATE",
      payload,
    });
  }

  function setFilters(payload) {
    dispatch({
      type: "SET_FILTER",
      payload,
    });
  }

  function setPerPage(payload) {
    dispatch({
      type: "SET_PER_PAGE",
      payload,
    });
  }

  function setSort(payload) {
    dispatch({
      type: "SET_SORT",
      payload,
    });
  }

  function setMarkAsRead(payload) {
    dispatch({
      type: "SET_MARK_AS_READ",
      payload,
    });
  }

  function setPageNumber(payload) {
    dispatch({
      type: "SET_PAGE_NUMBER",
      payload,
    });
  }

  function setNotificationLoader(payload) {
    dispatch({
      type: "SET_NOTIFICATION_lOADER",
      payload,
    });
  }

  function setNotificationsTabs(payload) {
    dispatch({
      type: "SET_NOTIFICATIONS_TABS",
      payload,
    });
  }

  return (
    <NotificationContext.Provider
      value={{
        ...state,
        setEntities,
        resetEntities,
        paginate,
        setFilters,
        setPerPage,
        setSort,
        setMarkAsRead,
        setPageNumber,
        setNotificationLoader,
        setNotificationsTabs,
      }}
      {...props}
    />
  );
}

export { NotificationContext, NotificationProvider };
