import React, { useState, useEffect, useContext, useRef } from "react";
import { MailOpenIcon, CheckIcon } from "@heroicons/react/outline";
import { Tooltip } from "antd";
import { Avatar, Badge } from "antd";
import moment from "moment-timezone";
import { twMerge } from "tailwind-merge";
import { NOTIFICATION_ALERTS_ENUM } from "#newUiComponents/notificationV2/NotificationAlertsDashboard";
import { formateInitials } from "#utils/helper-functions";
import { NotificationAlertsLoaderUI } from "#newUiComponents/notificationV2/NotificationsLoader";
import { NotificationContext } from "#contexts/notification";

const AlertsNotifications = ({
  notificationsAlertsList,
  markAllAsRead,
  selectedTab,
  onClose,
  updateNotificationsV1,
  handleLinkClick,
  loadMoreNotifications,
  markAllAsReadLoader,
}) => {
  const notification = useContext(NotificationContext);
  const [selectedAlertsNotification, setSelectedAlertsNotification] =
    useState(null);
  const [notificationAlerts, setNotificationAlerts] = useState(null);
  const notificationsRef = useRef(null);

  useEffect(() => {
    if (selectedTab) {
      setSelectedAlertsNotification((prev) => ({
        ...prev,
        ...selectedTab,
      }));
      setNotificationAlerts([...notificationsAlertsList]);
    }
  }, [selectedTab, notificationsAlertsList]);

  const getShortTimeAgo = (dateString) => {
    const localTime = moment(dateString).local();
    const duration = moment.duration(moment().diff(localTime));

    if (duration.asSeconds() < 60)
      return `${Math.floor(duration.asSeconds())} sec ago`;
    if (duration.asMinutes() < 60)
      return `${Math.floor(duration.asMinutes())} min ago`;
    if (duration.asHours() < 24)
      return `${Math.floor(duration.asHours())} hr ago`;
    if (duration.asDays() < 30) return `${Math.floor(duration.asDays())} d ago`;
    if (duration.asMonths() < 12)
      return `${Math.floor(duration.asMonths())} mo ago`;
    return `${Math.floor(duration.asYears())} yr ago`;
  };

  if (
    (!notificationAlerts ||
      notificationAlerts?.length === 0 ||
      selectedTab?.key !== notification?.selectedTab) &&
    !markAllAsReadLoader
  ) {
    return (
      <NotificationAlertsLoaderUI className="h-[70vh] overflow-y-scroll" />
    );
  }

  const renderMessage = (alert) => {
    const dynamicContent =
      alert?.deliveryChannels?.message
        ?.split(/({.*?})/g)
        ?.map((part, index, array) => {
          const match = part.match(/{(.*?)}/);

          if (match && NOTIFICATION_ALERTS_ENUM[match[1]]) {
            const dynamicKey = match[1];
            const dynamicValue =
              alert?.mappingData?.[dynamicKey] || alert?.mappingData?.entityId;

            const isClickable =
              !alert?.mappingData?.download &&
              NOTIFICATION_ALERTS_ENUM?.reports !== alert?.href &&
              NOTIFICATION_ALERTS_ENUM?.users !== alert?.href;

            return (
              <span
                key={index}
                className={`font-semibold ${isClickable ? "cursor-pointer underline" : ""}`}
                onClick={
                  isClickable ? () => handleLinkClick(alert) : undefined
                }>
                {dynamicValue}
              </span>
            );
          }

          const isLastIndex = index === array.length - 1;
          if (isLastIndex) {
            const isDownload =
              alert?.mappingData?.download &&
              alert?.href !== NOTIFICATION_ALERTS_ENUM?.users;
            const isViewDetails =
              alert?.href === NOTIFICATION_ALERTS_ENUM?.users;

            if (isDownload || isViewDetails) {
              return (
                <span key={index}>
                  {part}
                  <span
                    className="ml-1 cursor-pointer font-semibold underline"
                    onClick={() => handleLinkClick(alert)}>
                    {isDownload ? "Download here." : "View more details here."}
                  </span>
                </span>
              );
            }
          }

          return part;
        }) || "";

    return <span>{dynamicContent}</span>;
  };

  const handleScroll = () => {
    const element = notificationsRef.current;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      loadMoreNotifications();
    }
  };

  return (
    <div className="w-full space-y-4 font-inter">
      {selectedAlertsNotification && (
        <div
          className="h-[70vh] overflow-y-scroll pr-2"
          ref={notificationsRef}
          onScroll={handleScroll}>
          {(selectedAlertsNotification?.key === NOTIFICATION_ALERTS_ENUM?.all ||
            selectedAlertsNotification?.key ===
              NOTIFICATION_ALERTS_ENUM?.unRead) && (
            <div className="mt-1 flex w-full items-center justify-end">
              <div className="space-x-4">
                <button
                  className={twMerge(
                    `flex items-center p-0 text-sm font-semibold text-primaryAccent ${
                      markAllAsRead ? "cursor-pointer" : ""
                    }`,
                  )}
                  onClick={() =>
                    markAllAsRead
                      ? updateNotificationsV1({ updateAll: true })
                      : {}
                  }>
                  {markAllAsRead ? (
                    <MailOpenIcon className="mr-1 h-5 w-5 text-primaryAccent" />
                  ) : (
                    <CheckIcon className="text-green mr-1 h-5 w-5" />
                  )}
                  Mark all as read
                </button>
              </div>
            </div>
          )}
          {notificationAlerts &&
            notificationAlerts.map((alert, index) => (
              <div
                className="flex w-full flex-1 flex-row gap-4 px-0"
                key={"Alerts" + index}>
                <div className="flex w-full items-center border-b px-0 py-4">
                  <div className="flex flex-1 items-center justify-between">
                    <div className="flex flex-row items-center justify-start space-x-2">
                      {!alert?.isRead ? (
                        <div className="h-10 w-10">
                          <Badge dot status="warning" size="small">
                            <Avatar
                              size={36}
                              style={{
                                backgroundColor: "#F1F5F9",
                                color: "#374151",
                                width: 36,
                                height: 36,
                                flexShrink: 0,
                              }}>
                              <span className="text-sm font-semibold">
                                {`${alert?.name ? formateInitials(alert?.name) : "NA"}`}
                              </span>
                            </Avatar>
                          </Badge>
                        </div>
                      ) : (
                        <div className="h-10 w-10">
                          <Avatar
                            size={36}
                            style={{
                              backgroundColor: "#F1F5F9",
                              color: "#374151",
                            }}>
                            <span className="text-sm font-semibold">
                              {`${alert?.name ? formateInitials(alert?.name) : "NA"}`}
                            </span>
                          </Avatar>
                        </div>
                      )}
                      <div>
                        <p className="text-sm text-gray-500">
                          {alert?.deliveryChannels?.message &&
                            renderMessage(alert)}
                        </p>
                        <p className="text-xs text-gray-500">
                          {alert?.createdAt && (
                            <Tooltip
                              title={moment(alert?.createdAt)
                                .local()
                                .format("DD/MM/YYYY, hh:mm A")}>
                              {getShortTimeAgo(alert?.createdAt)}
                            </Tooltip>
                          )}
                        </p>
                      </div>
                    </div>
                    {!alert?.isRead && (
                      <Tooltip title="Mark as read">
                        <div>
                          <MailOpenIcon
                            onClick={() =>
                              alert?.notificationId
                                ? updateNotificationsV1({
                                    id: alert?.notificationId,
                                  })
                                : {}
                            }
                            className="mr-2 h-5 w-5 cursor-pointer text-primaryAccent"
                          />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default AlertsNotifications;
