import Modal from "#components/utils/Modal";
import _ from "lodash";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import Toggle from "#components/utils/Toggle";
import { getFilteredCustomerList } from "../../utils/getFilteredCustomerList";

const InventoryFilters = ({
  onSubmit,
  negativeAction,
  onChangeFilter,
  filters,
  customers,
  warehouses,
}) => (
  <Modal
    title={"Filters"}
    negativeAction={negativeAction}
    positiveAction={onSubmit}
    initialFocus={null}>
    <div className="mt-4 flex flex-col space-y-6">
      <div className="flex justify-between space-x-6">
        <div className="flex-1">
          <label className="mb-2 block text-left">
            <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
              Warehouses
            </span>
          </label>
          <MultiSelectAutoComplete
            options={warehouses}
            labelKey="name"
            valueKey="id"
            setValues={(selectedOptions) =>
              onChangeFilter("warehouse", selectedOptions)
            }
            values={filters["warehouse"]}
            rounded={true}
          />
        </div>

        <div className="flex-1">
          <label className="mb-2 block text-left">
            <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
              Clients
            </span>
          </label>
          <MultiSelectAutoComplete
            options={getFilteredCustomerList(filters["warehouse"], customers)}
            labelKey="name"
            valueKey="id"
            setValues={(selectedOptions) =>
              onChangeFilter("customer", selectedOptions)
            }
            values={filters["customer"]}
            rounded={true}
          />
        </div>
      </div>

      <div className="flex items-center space-x-2">
        <Toggle
          enabled={filters.includeOutOfStock === false}
          setEnabled={(enabled) =>
            onChangeFilter("includeOutOfStock", !enabled)
          }
        />
        <span className="font-montserrat text-sm text-454A4F">
          Hide Out of Stock Items
        </span>
      </div>
    </div>
  </Modal>
);

export default InventoryFilters;
