import NotFound from "#components/layout/NotFound";
import { AppStateContext } from "../contexts/appState";
import { useContext } from "react";

const Error404 = () => {
  const appState = useContext(AppStateContext);

  if (!appState?.tenant) return null;

  return (
    <NotFound
      title="Page Not Found"
      description="The page you're looking for doesn't exist"
    />
  );
};

export default Error404;
