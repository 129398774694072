import { useEffect, useState } from "react";
import { SearchIcon } from "@heroicons/react/outline";
import useDebouncedEffect from "#hooks/useDebouncedEffect";
import { useQuery } from "#hooks/useQuery";
import { GET_PRODUCTS } from "#queries";
import AnalyticsTabs from "#components/catalogs/AnalyticsTabs";
import ProductDetails from "#components/catalogs/ProductDetails";
import { DEFAULT_SEARCH_FIELDS } from "#components/catalogs/catalogTabs/CatalogEnhancedSearch";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import noSearchResultsIcon from "#static/images/noSearchResults.jpg";

const Analytics = ({}) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [shouldOpenModel, setShouldOpenModel] = useState(false);
  const [product, setProduct] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [isProductSearch, setIsProductSearch] = useState(false);
  const [allVariantComponents, setAllVariantComponents] = useState([]);
  const productsQuery = useQuery(GET_PRODUCTS);
  const searchFields = DEFAULT_SEARCH_FIELDS?.map((fieldObject) => {
    if (fieldObject?.value === "name" || fieldObject?.value === "sku")
      return { ...fieldObject, enabled: true };
    return fieldObject;
  });

  const getMatchedProducts = async (keyword) => {
    setIsProductSearch(true);
    const response = await productsQuery.fetchData({
      perPage: 25,
      pageNumber: 1,
      filters: {
        keyword: keyword,
        searchFields: searchFields.filter((i) => i.enabled).map((i) => i.value),
      },
      sort: "-createdAt",
    });
    setIsProductSearch(false);
    if (response?.data?.products) {
      const products =
        response.data.products?.entities?.filter((i) => !i.parentProduct) || [];
      if (products?.length > 0) setSearchResults(products);
    }
    if (response?.error) setSearchResults([]);
  };

  const onSelectProduct = (selectedProduct) => {
    setProduct(selectedProduct);
    setShouldOpenModel(false);
  };

  const searchFocusHandler = () => {
    if (searchResults?.length > 0) setShouldOpenModel(true);
  };

  useEffect(() => {
    if (searchKeyword.trim() === "" && product) {
      setProduct(null);
      setShouldOpenModel(false);
      setSearchResults([]);
    }
  }, [searchKeyword]);

  useDebouncedEffect(
    () => {
      if (searchKeyword && searchKeyword.trim() !== "") {
        getMatchedProducts(searchKeyword);
      }
    },
    500,
    [searchKeyword],
  );

  return (
    <>
      <div className="min-h-screen flex-1 flex-col bg-white">
        <div className="hs-catalog-wrapper">
          <div className="relative flex items-center justify-between">
            <div className="hs-pageTitle">Catalogue Analytics</div>
            <div className="relative flex-col">
              <div className="relative rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="search"
                  name="search"
                  id="search"
                  className="block w-full rounded-md border-0 py-3 pl-10 text-lg text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryAccent"
                  placeholder="Search Products"
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                    setSearchResults([]);
                    if (e.target.value?.trim() !== "") setShouldOpenModel(true);
                    else setShouldOpenModel(false);
                  }}
                  onFocus={searchFocusHandler}
                  value={searchKeyword}
                />
              </div>
              {shouldOpenModel && searchKeyword && (
                <div className="absolute right-0 z-20 mt-4 max-h-64 w-[320px] overflow-y-auto rounded-md border-2 border-[#DDDFE0] bg-white p-4">
                  <div className="flex-col">
                    {isProductSearch && (
                      <div className="m-auto h-full w-full flex-col items-center justify-center pb-10 text-center">
                        <LoadingIndicator shouldShowOnPage={false} />
                      </div>
                    )}
                    {(!searchResults || searchResults.length === 0) &&
                    !isProductSearch ? (
                      <div className="m-auto h-full w-full flex-col items-center justify-center pb-10 text-center">
                        <img
                          src={noSearchResultsIcon}
                          className="mx-auto h-48"
                        />
                        <div className="mt-4 text-lg text-black">
                          No Results To Display
                        </div>
                      </div>
                    ) : !isProductSearch && searchResults?.length > 0 ? (
                      searchResults?.map((product) => {
                        return (
                          <div
                            className="mb-2 flex cursor-pointer items-center"
                            onClick={() => onSelectProduct(product)}>
                            {(product?.images?.[0]?.url ||
                              product?.images?.[0]?.display_url) && (
                              <div className="h-[66px] w-16 shrink-0">
                                <img
                                  className="h-[66px] w-16 rounded"
                                  src={
                                    product.images?.[0]?.display_url
                                      ? product.images?.[0]?.display_url
                                      : product.images?.[0]?.url
                                  }
                                  alt=""
                                />
                              </div>
                            )}
                            <div className="ml-2 flex flex-col gap-1 font-inter">
                              <div className="w-auto max-w-[200px] truncate text-base font-medium text-primaryAccent">
                                {product?.name}
                              </div>
                              <div className="w-auto max-w-[200px] truncate font-inter text-sm text-lightGray">
                                SKU: {product?.sku}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="py-4">
            <ProductDetails
              product={product}
              allVariantComponents={allVariantComponents}
              setAllVariantComponents={setAllVariantComponents}
            />
            <AnalyticsTabs
              product={product}
              allVariantComponents={allVariantComponents}
            />
          </div>
        </div>
      </div>
    </>
  );
};

// @ts-ignore
export default Analytics;
