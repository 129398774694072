import { useEffect, useState, useContext } from "react";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import SalesPrice from "./SalesPrice";
import CostPrice from "./CostPrice";
import { ALERT_TIMEOUT_IN_MS } from "#components/HOC/withProductsLogic";

const ProductPrices = ({
  selectedProduct,
  setSelectedProduct,
  onChange,
  // salesPrice,
  // setSalesPrice,
  isRenderingFromCatalogEdit = false,
  customerList,
}) => {
  const [isCostPriceSectionActive, setIsCostPriceSectionActive] =
    useState(true);
  const [costPrice, setCostPrice] = useState([]);
  const appState = useContext(AppStateContext);

  const defaultSalesPriceComponent = [
    {
      key: "Expected Margin",
      value: "",
    },
    {
      key: "Shipping and Handling",
      value: "",
    },
    {
      key: "Labor Cost",
      value: "",
    },
  ];

  const defaultSalesPriceComponentKey = defaultSalesPriceComponent.map(
    (componentObject) => componentObject.key.toLowerCase(),
  );

  const sourceIsNotHopStack =
    selectedProduct?.id &&
    (selectedProduct?.source || "").toLowerCase() !== "hopstack";
  const costPriceBreakdown = _.omit(selectedProduct?.costPrice, [
    "total",
    "currency",
    "discount",
  ]);
  const isDisabledCostPriceField = !_.isEmpty(costPriceBreakdown);

  const totalCostValue =
    _.values(costPriceBreakdown).reduce((initVal, nxtVal) => {
      if (_.isObject(nxtVal) && nxtVal !== null)
        return initVal + Number(nxtVal?.selectedPrice) * 100;
      else return initVal + Number(nxtVal) * 100;
    }, 0) / 100;

  let costPriceWithDiscount =
    totalCostValue || selectedProduct?.costPrice?.total;
  if (selectedProduct?.costPrice?.discount) {
    costPriceWithDiscount =
      (Number(costPriceWithDiscount * 100) -
        Number(selectedProduct.costPrice.discount * 100)) /
      100;
  }

  let costPriceInputFieldValue =
    totalCostValue !== 0
      ? totalCostValue
      : selectedProduct?.costPrice?.total || "";
  if (isDisabledCostPriceField)
    costPriceInputFieldValue = Number(costPriceInputFieldValue).toFixed(2);

  const salesPriceObjectWithCostSummation = {
    valueObj: {
      "Cost Summation": costPriceWithDiscount,
    },
    salesChannel: [],
  };

  const addBreakdownCost = () => {
    const costPriceData = [...costPrice];
    costPriceData.push({ key: "", value: "" });
    setCostPrice(costPriceData);
  };

  const onCostPriceChange = (e, indx) => {
    const costPriceData = [...costPrice];
    costPriceData[indx][e.target.name] = e.target.value;
    setCostPrice(costPriceData);
  };

  const onRemoveCostPrice = (indx) => {
    const costPriceData = [...costPrice];
    costPriceData.splice(indx, 1);
    setCostPrice(costPriceData);
  };

  const onRemoveCostPriceSalesPrice = (name, type) => {
    if (type === "costPrice") {
      if (selectedProduct?.[type]?.["total"]) {
        onChange({
          target: {
            name: "total",
            value: "",
            type: "costPrice",
          },
        });
      }
    }
    onChange({
      target: {
        name,
        type,
        value: "delete",
      },
    });

    /*Commenting code for now if there is no need in feature then it will be removed*/
    /*code for remove sales price section*/
    // if (type === "salesPrice") {
    //   const salesPriceData = [...salesPrice];
    //   salesPriceData.splice(name, 1);
    //   setSalesPrice(salesPriceData);
    // }
  };

  /*Commenting code for now if there is no need in feature then it will be removed*/
  /*code for remove default sales price component*/
  // const onReomveSalesPrice = (key, type, name) => {
  //   let value = { ...selectedProduct[type][name].valueObj };
  //   if (value?.total) {
  //     value["total"] = "";
  //   }
  //   value = _.omit(value, [key]);
  //   onChange({
  //     target: {
  //       name,
  //       type,
  //       value: {
  //         ...selectedProduct?.[type]?.[name],
  //         valueObj: value,
  //       },
  //     },
  //   });
  // };

  const onAddCostPrice = (costPriceData, indx) => {
    if (
      (costPriceData.key || "").trim().toLowerCase() !== "total" &&
      costPriceData.value > 0
    ) {
      if (selectedProduct?.costPrice?.total) {
        onChange({
          target: {
            name: "total",
            value: "",
            type: "costPrice",
          },
        });
      }
      if (!selectedProduct?.costPrice?.currency) {
        onChange({
          target: {
            name: "currency",
            value: "USD-$",
            type: "costPrice",
          },
        });
      }
      onChange({
        target: {
          name: costPriceData.key.trim(),
          value: costPriceData.value,
          type: "costPrice",
        },
      });
      onRemoveCostPrice(indx);
    }
  };

  const updateComponentQuantity = (key, e) => {
    const componentObject = { ...selectedProduct.costPrice?.[key] };
    if (componentObject) {
      componentObject.selectedQuantity = e.target.value;

      componentObject.selectedPrice =
        ((Number(componentObject.price || "") /
          Number(componentObject.quantity || "")) *
          Number(componentObject.selectedQuantity || "")) /
        100;

      onChange({
        target: {
          name: key,
          value: componentObject,
          type: "costPrice",
        },
      });
    }
  };

  /*Commenting code for now if there is no need in feature then it will be removed*/
  /*Add element for component name and its value for add component with value in sales prices*/
  // const addBreakdownSalesPrice = (index, salesPriceKey = "") => {
  //   const salesPriceArrayData = [...salesPrice];
  //   if (salesPriceKey) {
  //     salesPriceArrayData[index][salesPriceKey].push({ key: "", value: "" });
  //   } else {
  //     if (
  //       (selectedProduct?.salesPrice?.length > 0 &&
  //         selectedProduct.salesPrice.reduce((initValue, salesObject) => {
  //           if (salesObject?.salesChannel?.length > 0)
  //             return [...initValue, ...salesObject.salesChannel];
  //           return initValue;
  //         }, [])?.length !== customerList.length) ||
  //       !selectedProduct?.salesPrice ||
  //       selectedProduct?.salesPrice?.length === 0
  //     ) {
  //       salesPriceArrayData.forEach((_, index) => {
  //         salesPriceArrayData[index].active = false;
  //       });
  //       salesPriceArrayData.push({
  //         active: true,
  //         salesPriceData: defaultSalesPriceComponent,
  //       });
  //       if (costPriceWithDiscount > 0) {
  //         const selectedProductData = { ...selectedProduct };
  //         if (selectedProductData?.salesPrice?.length > 0)
  //           selectedProductData.salesPrice.push({
  //             ...salesPriceObjectWithCostSummation,
  //             valueObj: {
  //               ...salesPriceObjectWithCostSummation.valueObj,
  //               currency: selectedProduct?.costPrice?.currency || "USD-$",
  //             },
  //           });
  //         else
  //           selectedProductData["salesPrice"] = [
  //             {
  //               ...salesPriceObjectWithCostSummation,
  //               valueObj: {
  //                 ...salesPriceObjectWithCostSummation.valueObj,
  //                 currency: selectedProduct?.costPrice?.currency || "USD-$",
  //               },
  //             },
  //           ];
  //         setSelectedProduct(selectedProductData);
  //       }
  //     } else {
  //       appState.setAlert(
  //         `You have already selected all sales channels`,
  //         "error",
  //         ALERT_TIMEOUT_IN_MS,
  //       );
  //     }
  //   }
  //   setSalesPrice(salesPriceArrayData);
  // };

  /*Commenting code for now if there is no need in feature then it will be removed*/
  /*add component name and its value on state based on chnage*/
  // const onSalesPriceChange = (e, dataIndx, indx, salesPriceKey) => {
  //   const salesPriceDataArray = [...salesPrice];
  //   salesPriceDataArray[indx][salesPriceKey][dataIndx][e.target.name] =
  //     e.target.value;
  //   setSalesPrice([...salesPriceDataArray]);
  // };

  /*Commenting code for now if there is no need in feature then it will be removed*/
  /*Function for remove sales price component*/
  // const onRemoveSalesPrice = (indx, dataIndx, salesPriceKey) => {
  //   const salesPriceData = [...salesPrice];
  //   salesPriceData[indx][salesPriceKey].splice(dataIndx, 1);
  //   setSalesPrice(salesPriceData);
  // };

  /*Commenting code for now if there is no need in feature then it will be removed*/
  /*Add component and its value on cost price and sales price main state*/
  // const onAddSalesPrice = (salesPriceData, indx, dataIndx) => {
  //   if (salesPriceData.key && salesPriceData.value > 0) {
  //     const selectedProductData = { ...selectedProduct?.salesPrice?.[indx] };
  //     const salesPriceBreakdown = {
  //       [salesPriceData.key.trim()]: salesPriceData.value,
  //     };
  //     if (selectedProductData?.valueObj?.total) {
  //       salesPriceBreakdown["total"] = "";
  //     }
  //     if (!selectedProductData?.valueObj?.currency) {
  //       salesPriceBreakdown["currency"] = "USD-$";
  //     }
  //     const salesPriceDetails = {
  //       ...selectedProductData,
  //       valueObj: { ...selectedProductData?.valueObj, ...salesPriceBreakdown },
  //     };
  //     onChange({
  //       target: {
  //         name: indx,
  //         value: salesPriceDetails,
  //         type: "salesPrice",
  //       },
  //     });
  //     onRemoveSalesPrice(indx, dataIndx, "salesPriceData");
  //   }
  // };

  /*Commenting code for now if there is no need in feature then it will be removed*/
  /*Code for Expand and collapse sales price section*/
  // const expandCollapseSalesPriceSection = (indx) => {
  //   const salesPriceData = [...salesPrice];
  //   salesPriceData[indx] = {
  //     ...salesPriceData[indx],
  //     active: !salesPriceData[indx].active,
  //   };
  //   setSalesPrice(salesPriceData);
  // };

  useEffect(() => {
    if (costPriceWithDiscount > 0 && !selectedProduct?.costPrice?.currency) {
      onChange({
        target: {
          name: "currency",
          value: "USD-$",
          type: "costPrice",
        },
      });
    }
  }, [costPriceWithDiscount]);

  /*Commenting code for now if there is no need in feature then it will be removed*/
  /*useEffect for add cost price latest value in sales price*/
  // useEffect(() => {
  //   if (selectedProduct?.salesPrice?.length > 0) {
  //     let productSalesPrice = [...selectedProduct.salesPrice];
  //     productSalesPrice = productSalesPrice.map((salesPriceObject, index) => {
  //       const modifiedSalesPriceObject = { ...salesPriceObject };
  //       if (costPriceWithDiscount > 0) {
  //         modifiedSalesPriceObject.valueObj = {
  //           ...modifiedSalesPriceObject?.valueObj,
  //           "Cost Summation": costPriceWithDiscount,
  //         };
  //       } else
  //         modifiedSalesPriceObject.valueObj = _.omit(
  //           modifiedSalesPriceObject.valueObj,
  //           ["Cost Summation"],
  //         );
  //       return {
  //         ...modifiedSalesPriceObject,
  //       };
  //     });
  //     setSelectedProduct({
  //       ...selectedProduct,
  //       salesPrice: [...productSalesPrice],
  //     });
  //   } else if (!selectedProduct?.id && salesPrice?.length > 0) {
  //     const productSalesPrice = [];
  //     salesPrice.forEach((_) => {
  //       if (costPriceWithDiscount > 0) {
  //         productSalesPrice.push({
  //           ...salesPriceObjectWithCostSummation,
  //           valueObj: {
  //             ...salesPriceObjectWithCostSummation.valueObj,
  //             currency: selectedProduct?.costPrice?.currency || "USD-$",
  //           },
  //         });
  //       }
  //     });
  //     setSelectedProduct({
  //       ...selectedProduct,
  //       salesPrice: [...productSalesPrice],
  //     });
  //   }
  // }, [costPriceWithDiscount]);

  /*Commenting code for now if there is no need in feature then it will be removed*/
  /*map all sales price data in sales price section*/
  // useEffect(() => {
  //   if (salesPrice.length > 0) {
  //     let salesPriceArray = [...salesPrice];
  //     if (selectedProduct?.salesPrice?.length > 0) {
  //       selectedProduct.salesPrice.forEach((salesPriceObject, index) => {
  //         const salesPriceComponent = [...defaultSalesPriceComponent].filter(
  //           ({ key }) => !salesPriceObject?.valueObj?.[key],
  //         );
  //         salesPriceArray[index] = {
  //           ...salesPriceArray[index],
  //           salesPriceData: [...salesPriceComponent],
  //         };
  //       });
  //     } else if (selectedProduct?.id) {
  //       salesPriceArray = [];
  //     } else {
  //       salesPriceArray = salesPriceArray.map((salesPriceObject) => {
  //         return {
  //           ...salesPriceObject,
  //           salesPriceData: [...defaultSalesPriceComponent],
  //         };
  //       });
  //     }
  //     setSalesPrice(salesPriceArray);
  //   }
  // }, []);

  return (
    <div className="my-6">
      <CostPrice
        isRenderingFromCatalogEdit={isRenderingFromCatalogEdit}
        costPriceWithDiscount={costPriceWithDiscount}
        selectedProduct={selectedProduct}
        setIsCostPriceSectionActive={setIsCostPriceSectionActive}
        isCostPriceSectionActive={isCostPriceSectionActive}
        sourceIsNotHopStack={sourceIsNotHopStack}
        costPriceInputFieldValue={costPriceInputFieldValue}
        onChange={onChange}
        isDisabledCostPriceField={isDisabledCostPriceField}
        costPriceBreakdown={costPriceBreakdown}
        updateComponentQuantity={updateComponentQuantity}
        onRemoveCostPriceSalesPrice={onRemoveCostPriceSalesPrice}
        costPrice={costPrice}
        onCostPriceChange={onCostPriceChange}
        onAddCostPrice={onAddCostPrice}
        onRemoveCostPrice={onRemoveCostPrice}
        addBreakdownCost={addBreakdownCost}
      />
      {/*Commenting code for now if there is no need in feature then it will be removed*/}
      {/*Hide Sales Price component*/}
      {/* <SalesPrice
        sourceIsNotHopStack={sourceIsNotHopStack}
        isRenderingFromCatalogEdit={isRenderingFromCatalogEdit}
        addBreakdownSalesPrice={addBreakdownSalesPrice}
        salesPrice={salesPrice}
        selectedProduct={selectedProduct}
        expandCollapseSalesPriceSection={expandCollapseSalesPriceSection}
        onChange={onChange}
        customerList={customerList}
        onReomveSalesPrice={onReomveSalesPrice}
        defaultSalesPriceComponentKey={defaultSalesPriceComponentKey}
        onSalesPriceChange={onSalesPriceChange}
        onAddSalesPrice={onAddSalesPrice}
        onRemoveSalesPrice={onRemoveSalesPrice}
        onRemoveCostPriceSalesPrice={onRemoveCostPriceSalesPrice}
      /> */}
    </div>
  );
};

export default ProductPrices;
