import React, { useState, useMemo } from "react";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/outline";
import CustomTable from "#newUiComponents/commons/CustomTable";
import NewModal from "#newUiComponents/commons/NewModal";
import OrderHeader from "./OrderHeader";
import OrderDetailsModal from "../outboundPlan/OrderDetailsModal";

const OrderDetailsModal2 = ({
  isOpen,
  onClose,
  orders,
  groupOrders,
  warehouses,
  customers,
}) => {
  const [expandedOrderId, setExpandedOrderId] = useState(null);

  return (
    <NewModal
      isOpen={isOpen}
      onClose={onClose}
      title="Orders Details"
      maxWidth="800px">
      <div className="max-h-[70vh] overflow-y-auto p-4 font-inter">
        <div className="mb-2 flex gap-3">
          <h3>
            Warehouse:{" "}
            {warehouses.find(
              (warehouse) => warehouse.id === orders?.[0].warehouse,
            )?.name || ""}
          </h3>
        </div>
        {groupOrders.map((groupOrder) => {
          const orderDetails = orders.find(
            (o) => o.orderId === groupOrder.order_id,
          );
          if (!orderDetails) return null;

          return (
            <div
              key={orderDetails.id}
              className="mb-4 rounded-lg border border-gray-200">
              <div
                className="flex cursor-pointer items-center justify-between p-4"
                onClick={() =>
                  setExpandedOrderId(
                    expandedOrderId === orderDetails.orderId
                      ? null
                      : orderDetails.orderId,
                  )
                }>
                <OrderHeader
                  orderId={orderDetails.orderId}
                  orderDate={orderDetails?.orderDate}
                  warehouse={""}
                  clientName={
                    customers.find(
                      (customer) => customer.id === orderDetails.customer,
                    )?.name || ""
                  }
                  orderSource={orderDetails.source || ""}
                />
                {expandedOrderId === orderDetails.orderId ? (
                  <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                ) : (
                  <ChevronRightIcon className="h-5 w-5 text-gray-500" />
                )}
              </div>

              {expandedOrderId === orderDetails.orderId && (
                <div className="border-t border-gray-200 p-4">
                  <CustomTable
                    columns={[
                      { key: "sku", title: "SKU" },
                      { key: "name", title: "Name" },
                      { key: "quantity", title: "Quantity" },
                    ]}
                    data={orderDetails.orderDetails.map((item) => ({
                      sku: item.sku,
                      name: item.name,
                      quantity: item.quantity,
                    }))}
                    isSearchable={false}
                    isPagination={false}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </NewModal>
  );
};

const AskMorpheus = ({
  data,
  isShowIds,
  createManualBatch,
  onClose,
  orders,
  customers,
  warehouses,
  selectedGroups,
  setSelectedGroups,
  groupedData,
}) => {
  const [expandedGroups, setExpandedGroups] = useState([]);
  const [expandedCategories, setExpandedCategories] = useState(
    (data || []).map((category) => category.grouping_criteria?.match_type) ||
      [],
  );

  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [selectedGroupOrders, setSelectedGroupOrders] = useState([]);

  const handleShowOrders = (e, groupOrders) => {
    e.stopPropagation();
    setSelectedGroupOrders(
      orders.filter((order) =>
        groupOrders.find((groupOrder) => groupOrder.order_id === order.orderId),
      ),
    );
    setIsOrderModalOpen(true);
  };

  const handleSelectAll = () => {
    const allGroupIds = groupedData.flatMap((category) =>
      category.groups.map((group) => group.id),
    );

    if (selectedGroups.length === allGroupIds.length) {
      setSelectedGroups([]);
    } else {
      setSelectedGroups(allGroupIds);
    }
  };

  const toggleCategory = (categoryName) => {
    setExpandedCategories((prev) =>
      prev.includes(categoryName)
        ? prev.filter((name) => name !== categoryName)
        : [...prev, categoryName],
    );
  };

  const toggleGroup = (groupId) => {
    if (!isShowIds) return;
    setExpandedGroups((prev) =>
      prev.includes(groupId)
        ? prev.filter((id) => id !== groupId)
        : [...prev, groupId],
    );
  };

  const toggleGroupSelection = (groupId) => {
    setSelectedGroups((prev) =>
      prev.includes(groupId)
        ? prev.filter((id) => id !== groupId)
        : [...prev, groupId],
    );
  };

  const totals = useMemo(() => {
    let totalGroups = 0;
    let totalOrderSet = new Set();

    groupedData.forEach((category) => {
      totalGroups += category.groups.length;
      category.groups.forEach((group) => {
        group.orders.forEach((order) => totalOrderSet.add(order.order_id));
      });
    });

    return { totalGroups, totalOrders: totalOrderSet.size };
  }, [groupedData]);

  return (
    <div className="flex h-full flex-col rounded-lg bg-white">
      <div className="grow overflow-y-auto p-4 font-inter">
        {/* Header with Select All */}
        <div className="mb-4 flex items-center justify-between border-b border-gray-200 pb-4">
          <div className="flex items-center gap-4">
            <input
              type="checkbox"
              checked={
                selectedGroups.length === totals.totalGroups &&
                totals.totalGroups > 0
              }
              onChange={handleSelectAll}
              className="h-4 w-4 cursor-pointer rounded border-gray-300 text-primaryAccent focus:ring-primaryAccent"
            />
            <span className="font-medium">Select All Groups</span>
          </div>
          <div className="flex gap-3">
            <span className="rounded-full bg-gray-100 px-3 py-1 text-sm text-gray-600">
              {totals.totalGroups} groups
            </span>
            <span className="rounded-full bg-gray-100 px-3 py-1 text-sm text-gray-600">
              {totals.totalOrders} total orders
            </span>
          </div>
        </div>

        {/* Categories and Groups */}
        <div className="space-y-4">
          {groupedData.map((category) => (
            <div
              key={category.categoryName}
              className="rounded-lg border border-gray-200">
              <div
                className="flex cursor-pointer items-center justify-between bg-gray-50 p-4"
                onClick={() => toggleCategory(category.categoryName)}>
                <span className="font-medium">{category.categoryName}</span>
                <ChevronDownIcon
                  className={`h-5 w-5 transform text-gray-500 transition-transform ${
                    expandedCategories.includes(category.categoryName)
                      ? "rotate-180"
                      : ""
                  }`}
                />
              </div>

              {expandedCategories.includes(category.categoryName) && (
                <div className="divide-y divide-gray-100 p-4">
                  {category.groups.map((group) => (
                    <div key={group.id} className="py-3">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-4">
                          <input
                            type="checkbox"
                            checked={selectedGroups.includes(group.id)}
                            onChange={() => toggleGroupSelection(group.id)}
                            className="h-4 w-4 cursor-pointer rounded border-gray-300 text-primaryAccent focus:ring-primaryAccent"
                          />
                          <div className="flex items-center gap-3">
                            <span className="font-medium">{group.name}</span>
                            <span
                              className="cursor-pointer rounded-full bg-gray-100 px-2 py-1 text-sm text-gray-600 hover:bg-gray-200"
                              onClick={(e) =>
                                handleShowOrders(e, group.orders)
                              }>
                              {group.count} orders
                            </span>
                            {group.skus.length > 0 && (
                              <div className="flex flex-wrap gap-2">
                                {group.skus.map((sku, idx) => (
                                  <span
                                    key={idx}
                                    className="rounded bg-blue-50 px-2 py-1 text-xs text-blue-700">
                                    {`SKU: ${sku.sku}`}{" "}
                                    {sku.quantity && `(Qty: ${sku.quantity})`}
                                  </span>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                        {isShowIds && (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleGroup(group.id);
                            }}
                            className="p-1">
                            {expandedGroups.includes(group.id) ? (
                              <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                            ) : (
                              <ChevronRightIcon className="h-5 w-5 text-gray-500" />
                            )}
                          </button>
                        )}
                      </div>

                      {isShowIds && expandedGroups.includes(group.id) && (
                        <div className="mt-2 grid grid-cols-3 gap-2 pl-8">
                          {group.orders.map((order) => (
                            <div
                              key={order.order_id}
                              className="rounded bg-gray-50 p-2 text-sm">
                              <div className="font-medium">
                                {order.order_id}
                              </div>
                              <div className="text-xs text-gray-500">
                                Source: {order.source}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        <OrderDetailsModal
          isOpen={isOrderModalOpen}
          onClose={() => setIsOrderModalOpen(false)}
          orders={selectedGroupOrders}
          // groupOrders={selectedGroupOrders}
          // warehouses={warehouses}
          hideShippingAddress={true}
          customers={customers}
        />
      </div>
    </div>
  );
};

export default AskMorpheus;
