import { useEffect, useState } from "react";
import SectionTabs from "./SectionTabs";
import CatalogProductOverviewForm from "./CatalogProductOverviewForm";
import CatalogProductInfoForm from "./CatalogProductInfoForm";
import CatalogProductPrices from "./CatalogProductPrices";

const CatalogEditProduct = ({
  product,
  setProduct,
  productCategories,
  saveProduct,
  customers,
  customerList,
  onChange,
  validateCostPriseSalesPrice,
  getProductVariantComponents,
  allVariantComponents,
  setAllVariantComponents,
}) => {
  const allTabsMenus = [
    { name: "Product Overview", current: true },
    { name: "Product Info", current: false },
  ];

  if (product.typeOfProduct !== "BUNDLE")
    allTabsMenus.push({ name: "Pricing & Availability", current: false });

  const [tabs, setTabs] = useState(allTabsMenus);
  /*Commenting code for now if there is no need in feature then it will be removed*/
  // const [salesPrice, setSalesPrice] = useState([
  //   {
  //     active: false,
  //     salesPriceData: [],
  //   },
  // ]);

  const renderTabContent = () => {
    const currentTab = tabs.find((tab) => tab.current).name;
    switch (currentTab) {
      case "Product Overview":
        return (
          <CatalogProductOverviewForm
            product={product}
            setProduct={setProduct}
            productCategories={productCategories}
            saveProduct={saveProduct}
            customers={customers}
          />
        );
      case "Product Info":
        return (
          <CatalogProductInfoForm
            product={product}
            setProduct={setProduct}
            saveProduct={saveProduct}
            onChange={onChange}
            validateCostPriseSalesPrice={validateCostPriseSalesPrice}
            getProductVariantComponents={getProductVariantComponents}
            allVariantComponents={allVariantComponents}
          />
        );
      case "Pricing & Availability":
        return (
          <CatalogProductPrices
            selectedProduct={product}
            setSelectedProduct={setProduct}
            // salesPrice={salesPrice}
            // setSalesPrice={setSalesPrice}
            saveProduct={saveProduct}
            customerList={customerList}
            onChange={onChange}
            validateCostPriseSalesPrice={validateCostPriseSalesPrice}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    return () => {
      setAllVariantComponents([]);
    };
  }, []);

  return (
    <>
      <div className="h-full flex-col px-6">
        <SectionTabs tabs={tabs} setTabs={setTabs} />
        <div className="h-full">{renderTabContent()}</div>
      </div>
      {/* <div className="w-full flex h-full">
      <div className="flex-1 bg-red-400 h-full">Hello</div>
      <div className="flex-1 bg-blue-400 h-full">World</div>
    </div> */}
    </>
  );
};

export default CatalogEditProduct;
