import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "#hooks/useQuery";
import {
  GET_CURRENT_INWARD_ITEM,
  GET_INVENTORY,
  GET_STATIONS,
  GET_CONSIGNMENTS,
  GET_CONSIGNMENT,
  FETCH_SPECIFIC_ORDER,
  GET_SKU,
  GET_SKU_BIN_MAPPINGS,
  GET_PARENT_SKU,
  SEARCH_LOCATIONS,
  EXPAND_STORAGE_ENTITY,
} from "#queries";
import {
  SCAN_INWARD_RECEIVER_BARCODE,
  CONFIRM_INWARD_ITEM,
  IMMEDIATE_SCAN,
  CHECK_FOR_CONSIGNMENT,
  ADD_LPN_TO_PRODUCT,
  GENERATE_LPN,
  LOGOUT_USER,
  CANCEL_RECEIVING_ITEM,
  SET_STATION,
  SUBMIT_NUMBER_OF_CARTONS,
  LOG_LABEL_PRINT,
} from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import io from "socket.io-client";
import { printShippingLabel } from "#utils/printShippingLabel";
import { printCanvasLabelRemote } from "#utils/printCanvasLabelRemote";
import { printCanvasLabelPdf } from "#utils/printCanvasLabelPdf";
import { printBol } from "#utils/printBol";
import { TENANT_FNSKU_LABEL_PRINTING_SIZE_ENUM } from "../../constants/printer-settings";
import { handleFnSkuPrinting } from "./withPrepperLogic";
import { printMultiPageLpn } from "#utils/printMultiPageLpn";
const ALERT_TIMEOUT_IN_MS = 5000;
const DEBOUNCE_TIME_FOR_LPN_CHECK = 500;
const SCROLL_DELAY_IN_MS = 100;
const HIGHLIGHT_DURATION_IN_MS = 500;

const withInwardReceiverLogic = (WrappedComponent) => {
  return () => {
    const history = useHistory();
    const location = useLocation();

    const [bolDoc, setBolDoc] = useState(null);
    const [socket, setSocket] = useState(null);
    const consignmentsQuery = useQuery(GET_CONSIGNMENTS);
    const getConsignmentQuery = useQuery(GET_CONSIGNMENT);
    const getOrderForBolPrint = useQuery(FETCH_SPECIFIC_ORDER);
    const [codeToPrint, setCodeToPrint] = useState(null);
    const [consignments, setConsignments] = useState([]);
    const [generatedLPN, setGeneratedLPN] = useState(null);
    const [printedCodes, setPrintedCodes] = useState(null);
    const [inventorySearchKeyword, setInventorySearchKeyword] = useState("");
    const [addLpnToProduct, setAddLpnToProduct] = useState(null);
    const [generateLpn, setGenerateLpn] = useState(null);
    const [availableInventory, setAvailableInventory] = useState([]);
    const appState = useContext(AppStateContext);
    const auth = useContext(AuthContext);
    const [trackingNumber, setTrackingNumber] = useState(undefined);
    const [currentScan, setCurrentScan] = useState(null);
    const [showScanner, setShowScanner] = useState(false);
    const [currentSku, setCurrentSku] = useState(null);
    const confirmItemQuery = useQuery(CONFIRM_INWARD_ITEM);
    const currentSkuQuery = useQuery(GET_CURRENT_INWARD_ITEM);
    const scanBarcodeQuery = useQuery(SCAN_INWARD_RECEIVER_BARCODE);
    const immediateScanQuery = useQuery(IMMEDIATE_SCAN);
    const checkForConsignmentQuery = useQuery(CHECK_FOR_CONSIGNMENT);
    const cancelItemQuery = useQuery(CANCEL_RECEIVING_ITEM);
    const inventoryQuery = useQuery(GET_INVENTORY);
    const addLpnQuery = useQuery(ADD_LPN_TO_PRODUCT);
    const generateLpnQuery = useQuery(GENERATE_LPN);
    const logoutQuery = useQuery(LOGOUT_USER);
    const logLabelPrintQuery = useQuery(LOG_LABEL_PRINT);

    const stationsQuery = useQuery(GET_STATIONS);
    const [stations, setStations] = useState([]);
    const [selectedStation, setSelectedStation] = useState(null);
    const setStationQuery = useQuery(SET_STATION);
    const submitNumberOfCartonsQuery = useQuery(SUBMIT_NUMBER_OF_CARTONS);
    const [consignmentsData, setConsignmentsData] = useState({});
    const [specificConsignment, setSpecificConsignment] = useState(null);
    const [currentItems, setCurrentItems] = useState(null);
    const getCurrentProductQuery = useQuery(GET_SKU);
    const getParentSkuQuery = useQuery(GET_PARENT_SKU);
    const [currentProduct, setCurrentProduct] = useState(null);
    const skuBinMappingsQuery = useQuery(GET_SKU_BIN_MAPPINGS);
    const [productSkuBinMappings, setProductSkuBinMappings] = useState([]);
    const [currentParentProduct, setCurrentParentProduct] = useState(null);
    const searchLocationsQuery = useQuery(SEARCH_LOCATIONS);
    const statusList = [
      {
        label: "Pending Orders",
        value: "pending",
        filterValue: ["RECEIVING_STARTED", "UNPROCESSED"],
      },
      {
        label: "Completed Orders",
        value: "completed",
        filterValue: ["COMPLETED"],
      },
      {
        label: "All Orders",
        value: "all",
        filterValue: ["RECEIVING_STARTED", "UNPROCESSED", "COMPLETED"],
      },
    ];
    const [addingFormFactor, setAddingFormFactor] = useState(null);
    const expandStorageEntityQuery = useQuery(EXPAND_STORAGE_ENTITY);
    const [automaticPutawayList, setAutomaticPutawayList] = useState([]);

    useEffect(() => {
      setQueryParamsToCurrentScan();
    }, [location]);

    const setQueryParamsToCurrentScan = (newScan = false) => {
      // Parse the query parameters
      const queryParams = new URLSearchParams(location.search);
      const selectedCustomer = queryParams.get("customer");
      const selectedStation = queryParams.get("station");

      if (currentScan && !newScan) {
        setCurrentScan({
          ...currentScan,
          customer: selectedCustomer,
          station: selectedStation,
        });
      } else {
        setCurrentScan({
          customer: selectedCustomer,
          station: selectedStation,
        });
      }

      if (selectedStation) {
        setSelectedStation(selectedStation);
        setStationQuery.fetchData({ station: selectedStation });
      }
    };

    useEffect(() => {
      if (currentScan && currentScan.customer) {
        if (currentScan.trackingNumber || currentScan.consignmentId) {
          return;
        }
        const filters = {
          customers: [currentScan.customer],
          status: consignmentsData.filters?.status || statusList[0].filterValue,
        };
        setConsignmentsData({
          ...consignmentsData,
          pageNumber: 1,
          perPage: 25,
          sort: "-status",
          filters,
        });
        consignmentsQuery.fetchData({
          perPage: 25,
          pageNumber: 1,
          filters,
          sort: "-status",
        });
      }
    }, [currentScan, consignmentsData.filters?.status]);

    const getParentProduct = async (parentProductId) => {
      const response = await getParentSkuQuery.fetchData({
        id: parentProductId,
      });

      if (response.error) {
        setCurrentParentProduct(null);
      }

      if (response.data) {
        setCurrentParentProduct(response.data.parentSku);
      }
    };

    useEffect(() => {
      if (getCurrentProductQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getCurrentProductQuery.data) {
        setCurrentProduct(getCurrentProductQuery.data.specificInventory);
        //fetch skubinmappings with filters productId
        if (getCurrentProductQuery.data.specificInventory?.id) {
          skuBinMappingsQuery.fetchData({
            perPage: 100,
            pageNumber: 1,
            filters: {
              productId: getCurrentProductQuery.data.specificInventory.id,
            },
          });
        }
        if (getCurrentProductQuery.data.specificInventory.parentProduct) {
          getParentProduct(
            getCurrentProductQuery.data.specificInventory.parentProduct,
          );
        } else {
          setCurrentParentProduct(null);
        }
        setCurrentSku({
          ...currentSku,
          formFactor:
            currentSkuQuery.data.getCurrentInwardItem.formFactor ||
            getCurrentProductQuery.data.specificInventory.baseUom,
        });
      }

      if (getCurrentProductQuery.error) {
        setCurrentProduct(null);
      }
    }, [
      getCurrentProductQuery.loading,
      getCurrentProductQuery.data,
      getCurrentProductQuery.error,
    ]);

    useEffect(() => {
      if (getConsignmentQuery.data) {
        setSpecificConsignment(getConsignmentQuery.data.consignment);
        if (currentItems) {
          const updatedItems = currentItems.map((item) => {
            const totalAvailableQuantity = _.sumBy(
              getConsignmentQuery.data.consignment.workingList.filter(
                (i) => i.sku === item.sku && i.formFactor === item.formFactor,
              ),
              "availableQuantity",
            );
            return {
              ...item,
              quantity: totalAvailableQuantity,
              availableQuantity: totalAvailableQuantity,
            };
          });
          setCurrentItems(updatedItems);
        }
        if (getConsignmentQuery.data.consignment.status === "COMPLETED") {
          setQueryParamsToCurrentScan(true);
          setSpecificConsignment(null);
        }
      }

      if (getConsignmentQuery.error) {
        setSpecificConsignment(null);
        appState.setAlert(
          getConsignmentQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (getConsignmentQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      getConsignmentQuery.data,
      getConsignmentQuery.loading,
      getConsignmentQuery.error,
    ]);

    useEffect(() => {
      if (logoutQuery.data) {
        appState.setAlert(logoutQuery.data.logout.message);
        auth.logout();
      }

      if (logoutQuery.error) {
        auth.logout();
      }
    }, [logoutQuery.data, logoutQuery.loading, logoutQuery.error]);

    useEffect(() => {
      if (currentScan?.consignmentId) {
        getConsignmentQuery.fetchData({
          id: currentScan.consignmentId,
        });
      } else {
        setSpecificConsignment(null);
      }
    }, [currentScan?.consignmentId]);

    useEffect(() => {
      if (currentSku?.consignmentIds) {
        consignmentsQuery.fetchData({
          perPage: 25,
          pageNumber: 1,
          filters: {
            ids: currentSku.consignmentIds,
          },
        });
      }
    }, [currentSku?.consignmentIds]);

    useEffect(() => {
      if (consignmentsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (consignmentsQuery.data) {
        setConsignmentsData({
          ...consignmentsData,
          consignments: consignmentsQuery.data.consignments.entities,
          total: consignmentsQuery.data.consignments.total,
        });
        setConsignments(consignmentsQuery.data.consignments.entities);
      }

      if (consignmentsQuery.error) {
        setConsignmentsData({});
        setConsignments([]);
      }
    }, [
      consignmentsQuery.data,
      consignmentsQuery.loading,
      consignmentsQuery.error,
    ]);

    async function fetchUnreservedLocations() {
      const response = await searchLocationsQuery.fetchData({
        warehouses: [currentSku.warehouse],
        onlyUnreservedLocations: true,
        pagination: {
          limit: 25,
          offset: 0,
        },
      });

      if (response.data) {
        const uniqueBins = [
          ...new Set(
            response.data.searchLocations.data.locations
              .map((i) => ({
                binLocation: i.code,
              }))
              .filter((i) => i.binLocation !== "VIRTUAL_DAMAGED_LOCATION"),
          ),
        ];
        setProductSkuBinMappings(uniqueBins);
        currentItems.forEach((item, idx) => {
          if (item.putawayBinLocation || item.putawayBinLocation === "") {
            return;
          }
          updateItem(idx, "putawayBinLocation", uniqueBins[0].binLocation);
        });
      }

      if (response.error) {
        setProductSkuBinMappings([]);
      }
    }

    useEffect(() => {
      if (skuBinMappingsQuery.data) {
        const uniqueBins = _.uniqBy(
          skuBinMappingsQuery.data.skuBinMappings.entities,
          "binLocation",
        );
        setProductSkuBinMappings(uniqueBins);
        if (uniqueBins.length === 0) {
          fetchUnreservedLocations();
        }
        if (
          appState.tenant?.settings?.activities?.receiving
            ?.putawayBinLocation &&
          uniqueBins.length > 0
        ) {
          currentItems.forEach((item, idx) => {
            if (item.putawayBinLocation || item.putawayBinLocation === "") {
              return;
            }
            updateItem(idx, "putawayBinLocation", uniqueBins[0].binLocation);
          });
        }
      }

      if (skuBinMappingsQuery.error) {
        appState.setAlert(
          skuBinMappingsQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        setProductSkuBinMappings([]);
      }
    }, [skuBinMappingsQuery.data, skuBinMappingsQuery.error]);

    useEffect(() => {
      if (auth?.user?.id && !socket) {
        setSocket(
          io(localStorage.socketService, {
            path: "/socket-service/socket.io",
          }),
        );

        // socket.on("printMessage", (payload) => {
        //   appState.setAlert(`Printing`, "success", ALERT_TIMEOUT_IN_MS);
        //   setCodeToPrint(payload.codes);
        //   setTimeout(() => {
        //     for (const code of payload.codes) {
        //       printCanvasLabel(`${code}_4x6`, 1);
        //     }
        //   }, 1000);
        // });
      }
    }, [auth?.user]);

    useEffect(() => {
      if (socket) {
        console.log(socket);
        socket.on("LPN", (payload) => {
          appState.setAlert(`Printing`, "success", ALERT_TIMEOUT_IN_MS);
          if (appState.subdomain === "tosprep") {
            for (const code of payload.codes) {
              printCanvasLabelRemote(
                {
                  code: `${code}`,
                  quantity: 1,
                  dimensions:
                    TENANT_FNSKU_LABEL_PRINTING_SIZE_ENUM[appState.subdomain] ||
                    TENANT_FNSKU_LABEL_PRINTING_SIZE_ENUM.default,
                },
                null,
                appState,
              );
            }
          } else {
            setCodeToPrint(payload.codes);
            setTimeout(() => {
              for (const code of payload.codes) {
                printCanvasLabelPdf(
                  `${code}`,
                  1,
                  null,
                  null,
                  TENANT_FNSKU_LABEL_PRINTING_SIZE_ENUM[appState.subdomain] ||
                    TENANT_FNSKU_LABEL_PRINTING_SIZE_ENUM.default,
                  appState,
                  true,
                );
              }
            }, 1000);
          }
          for (let code of payload.codes) {
            logLabelPrintQuery.fetchData({
              type: "LPN",
              customer: payload.customer,
              quantity: 1,
              code,
            });
          }
        });

        socket.on("SHIPPING_LABEL", (payload) => {
          appState.setAlert(
            `Printing shipping label`,
            "success",
            ALERT_TIMEOUT_IN_MS,
          );
          printShippingLabel(
            payload.codes[0],
            appState,
            "Datamax I-4212 (Printer 1)",
          );
          logLabelPrintQuery.fetchData({
            type: "SHIPPING_LABEL",
            customer: payload.customer,
            quantity: 1,
            code: payload.codes[0],
          });
        });

        socket.on("BOL", (payload) => {
          // appState.setAlert(`Printing BOL`, "success", ALERT_TIMEOUT_IN_MS);
          // printShippingLabel(payload.codes[0], appState, "RICOH IM 5000 PCL 6");
          getOrderForBolPrint.fetchData({ orderId: payload.codes[0] });
          // logLabelPrintQuery.fetchData({
          //   type: "BOL",
          //   customer: payload.customer,
          //   quantity: 1,
          //   code: payload.codes[0],
          // });
        });

        // socket.on("printFile", (payload) => {
        //   appState.setAlert(`Printing file`, "success", ALERT_TIMEOUT_IN_MS);
        //   printShippingLabel(payload.url, appState);
        // });

        // socket.on("printFile", (payload) => {
        //   appState.setAlert(`Printing shipping label`, "success", ALERT_TIMEOUT_IN_MS);
        //   printShippingLabel(payload.url, appState);
        // });

        socket.on("connect", function () {
          console.log("connected!");
        });

        socket.on("message", (message) => {
          console.log(message);
        });
        socket.emit("subscribe", { roomId: auth.user.station });
      }
    }, [socket]);

    useEffect(() => {
      const client = new W3CWebSocket("ws://127.0.0.1:8456");
      client.onopen = () => {
        console.log("Connected to electron app");
      };
      client.onmessage = (message) => {
        console.log("message received", message);
        const parsed = JSON.parse(message.data);
        let relevant = parsed.data;
        if (relevant && relevant.data) {
          relevant = relevant.data;
        }

        switch (relevant.key) {
          case "scanner-data":
            if (relevant.message) {
              return immediateScanQuery.fetchData({
                barcode: relevant.message,
              });
            }
          case "dymo-m10-attach":
            if (relevant.message === "attached") {
            } else if (relevant.message === "detached") {
            }
            break;

          case "dymo-m10-error":
            break;
        }
      };
    }, []);

    useEffect(() => {
      if (getOrderForBolPrint.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getOrderForBolPrint.data) {
        appState.setLoading();
        setBolDoc(getOrderForBolPrint.data.order);
        setTimeout(() => {
          printBol(
            getOrderForBolPrint.data.order.orderId,
            1,
            appState.subdomain === "ctw" ? "RICOH IM 5000 PCL 6" : null,
            false,
            appState,
          );
          appState.removeLoading();
        }, 5000);
      }
    }, [
      getOrderForBolPrint.loading,
      getOrderForBolPrint.error,
      getOrderForBolPrint.data,
    ]);

    useEffect(() => {
      if (setStationQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (setStationQuery.error) {
        if (
          setStationQuery.error?.message &&
          setStationQuery.error.message.indexOf("already") !== -1
        ) {
          appState.showConfirmation(
            "Error logging in",
            setStationQuery.error.message,
            () => {
              setStationQuery.fetchData({
                station: selectedStation,
                logoutAll: true,
              });
              appState.hideConfirmation();
            },
            appState.hideConfirmation,
          );
        } else {
          appState.setAlert(
            setStationQuery.error.message,
            "error",
            ALERT_TIMEOUT_IN_MS,
          );
        }
      }

      if (setStationQuery.data && setStationQuery.data) {
        // appState.setAlert(setStationQuery.data.message, "success", ALERT_TIMEOUT_IN_MS);
        window.location = "/receiver";
        // history.push("/receiver");
      }
    }, [setStationQuery.data, setStationQuery.error, setStationQuery.loading]);

    useEffect(() => {
      stationsQuery.fetchData({
        filters: {
          hopstackModule: ["Receiving"],
          all: true,
        },
      });
    }, []);

    useEffect(() => {
      if (stationsQuery.data) {
        const foundStations = stationsQuery.data.stations.entities;
        setStations(foundStations);
      }

      if (stationsQuery.error) {
        setStations([]);
      }
    }, [stationsQuery.data, stationsQuery.loading, stationsQuery.error]);

    useEffect(() => {
      if (checkForConsignmentQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (checkForConsignmentQuery.data) {
        if (checkForConsignmentQuery.data.checkForConsignment.consignments) {
          setConsignments(
            checkForConsignmentQuery.data.checkForConsignment.consignments,
          );
        }
        if (currentScan) {
          if (
            checkForConsignmentQuery.data.checkForConsignment.consignments
              .length === 1
          ) {
            return setCurrentScan({
              ...currentScan,
              trackingNumber: null,
              consignmentId:
                checkForConsignmentQuery.data.checkForConsignment
                  .consignments[0].id,
            });
          }
          return setCurrentScan({
            ...currentScan,
            trackingNumber: currentScan.consignmentId ? null : trackingNumber,
          });
        }
      }

      if (checkForConsignmentQuery.error) {
        setConsignments([]);
        setTrackingNumber(null);
        setCurrentScan({
          ...currentScan,
          trackingNumber: null,
        });
      }
    }, [
      checkForConsignmentQuery.loading,
      checkForConsignmentQuery.error,
      checkForConsignmentQuery.data,
    ]);

    const checkConsignmentById = (e) => {
      if (e.data) {
        setCurrentScan({
          ...currentScan,
          consignmentId: e.data,
        });
        setTrackingNumber(null);
        checkForConsignmentQuery.fetchData({
          trackingNumber: null,
          customer: currentScan.customer,
          consignmentId: e.data,
        });
      }
    };

    const scanBarcode = (e) => {
      if (e.data) {
        immediateScanQuery.fetchData({ barcode: e.data });
        setShowScanner(false);
      }
    };

    const scanQueryfn = async () => {
      if (immediateScanQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (immediateScanQuery.data) {
        const barcode = immediateScanQuery.data.immediateScan.barcode;
        if (!currentScan) {
          return;
        }
        if (currentScan.trackingNumber === undefined) {
          // TRACKING NUMBER
          setTrackingNumber(barcode);
          return checkForConsignmentQuery.fetchData({
            trackingNumber: [barcode],
            customer: currentScan.customer,
          });
        }

        if (addLpnToProduct && addLpnToProduct.productId) {
          return setAddLpnToProduct({
            ...addLpnToProduct,
            lpn: barcode,
          });
        }

        if (generateLpn) {
          return setGenerateLpn({
            ...generateLpn,
            useCode: barcode,
          });
        }
        return scanBarcodeQuery.fetchData({
          code: barcode,
          trackingNumber: currentScan?.trackingNumber,
          customer: currentScan?.customer,
          consignmentId: currentScan?.consignmentId,
        });
      }
    };

    useEffect(() => {
      scanQueryfn();
    }, [
      immediateScanQuery.loading,
      immediateScanQuery.error,
      immediateScanQuery.data,
    ]);

    useEffect(() => {
      currentSkuQuery.fetchData();
    }, []);
    useEffect(() => {
      if (currentSkuQuery.loading) {
        appState.setLoading();
      }
      if (currentSkuQuery.data && currentSkuQuery.data.getCurrentInwardItem) {
        let quantity = currentSkuQuery.data.getCurrentInwardItem.quantity;
        if (currentSku && currentSku.quantity >= quantity) {
          quantity = currentSku.quantity;
        }

        if (currentSku?.attributes?.serialNumberRequired) {
          quantity = 1;
        }

        setCurrentSku({
          ...currentSkuQuery.data.getCurrentInwardItem,
          quantity,
        });
        if (!currentItems) {
          setCurrentItems([
            {
              ...currentSkuQuery.data.getCurrentInwardItem,
              quantity,
              formFactor:
                currentSkuQuery.data.getCurrentInwardItem.formFactor ||
                currentProduct?.baseUom ||
                "Each",
              status: "CONFIRMED",
            },
          ]);
        }
        setCurrentScan({
          trackingNumber:
            currentSkuQuery.data.getCurrentInwardItem.trackingNumber,
          customer: currentSkuQuery.data.getCurrentInwardItem.customer,
          consignmentId:
            currentSkuQuery.data.getCurrentInwardItem.consignmentId,
        });
        appState.removeLoading();
        // setLoadingFirstTime(false);
        if (currentSkuQuery.data.getCurrentInwardItem.productId) {
          getCurrentProductQuery.fetchData({
            id: currentSkuQuery.data.getCurrentInwardItem.productId,
          });
        }
      }

      if (currentSkuQuery.error) {
        appState.removeLoading();
        setCurrentItems(null);
        setCurrentSku(null);
        setCurrentParentProduct(null);
        setCurrentProduct(null);
        if (currentScan?.consignmentId) {
          getConsignmentQuery.fetchData({
            id: currentScan.consignmentId,
          });
        } else if (currentScan?.trackingNumber) {
          checkForConsignmentQuery.fetchData({
            trackingNumber: currentScan.trackingNumber,
            customer: currentScan.customer,
          });
        }
      }
    }, [currentSkuQuery.loading, currentSkuQuery.data, currentSkuQuery.error]);

    useEffect(() => {
      if (confirmItemQuery.loading) {
        appState.setLoading();
      } else {
        if (
          confirmItemQuery.data &&
          confirmItemQuery.data.confirmInwardItem.message
        ) {
          appState.setAlert(
            confirmItemQuery.data.confirmInwardItem.message,
            "success",
            2000,
          );

          currentSkuQuery.fetchData();
          if (specificConsignment && specificConsignment.id) {
            getConsignmentQuery.fetchData({ id: specificConsignment.id });
            if (
              specificConsignment?.workingList?.findIndex(
                (i) => i.availableQuantity > 0,
              ) > -1
            ) {
              checkForConsignmentQuery.fetchData({
                trackingNumber: null,
                customer: specificConsignment.customer,
                consignmentId: specificConsignment.id,
              });
            }
          }
        }
        if (confirmItemQuery.error) {
          appState.setAlert(
            confirmItemQuery.error.message,
            "error",
            ALERT_TIMEOUT_IN_MS,
          );
        }
        appState.removeLoading();
        // setLoadingFirstTime(false);
      }
    }, [
      confirmItemQuery.loading,
      confirmItemQuery.data,
      confirmItemQuery.error,
    ]);

    useEffect(() => {
      if (scanBarcodeQuery.loading) {
        appState.setLoading();
      } else {
        if (
          scanBarcodeQuery.data &&
          scanBarcodeQuery.data.scanInwardReceiverBarcode &&
          scanBarcodeQuery.data.scanInwardReceiverBarcode.message
        ) {
          currentSkuQuery.fetchData();
          appState.setAlert(
            scanBarcodeQuery.data.scanInwardReceiverBarcode.message,
            "success",
            ALERT_TIMEOUT_IN_MS,
          );
        } else if (scanBarcodeQuery.error) {
          appState.setAlert(scanBarcodeQuery.error.message, "error");
        }
        appState.removeLoading();
        // setLoadingFirstTime(false);
      }
    }, [
      scanBarcodeQuery.loading,
      scanBarcodeQuery.data,
      scanBarcodeQuery.error,
    ]);

    const decreaseItemQuantity = () => {
      const item = { ...currentSku };

      if (!item) {
        return;
      }

      if (item.quantity === 1) {
        return;
      }

      item.quantity -= 1;

      item.quantity = Math.max(0, item.quantity);

      setCurrentSku({ ...item });
    };

    const increaseItemQuantity = () => {
      const item = { ...currentSku };

      if (!item) {
        return;
      }

      item.quantity += 1;

      setCurrentSku({ ...item });
    };

    const confirmPrepItem = (forcePrint = false) => {
      if (
        !appState.tenant?.settings?.activities?.receiving
          ?.disableAutomaticPrinting ||
        forcePrint
      ) {
        if (
          appState?.tenant?.features?.combinedReceiveAndPrep === true &&
          currentProduct?.source === "FBA"
        ) {
          if (
            currentItems.find(
              (item) => isNaN(item.quantity) || item.quantity <= 0,
            )
          ) {
            appState.setAlert(
              `Please input a valid quantity for all items`,
              "error",
              ALERT_TIMEOUT_IN_MS,
            );
            return;
          }

          appState.setAlert(
            "Printing FNSKU...",
            "success",
            ALERT_TIMEOUT_IN_MS,
          );

          currentItems.forEach((item) =>
            handleFnSkuPrinting(
              appState,
              currentProduct.fnSku,
              item.quantity,
              currentProduct?.name || currentProduct?.productName,
              item.bestByDate,
            ),
          );
        }
      }
    };

    /**
     * Function to filter customers with inbound workflow enabled
     * @param {Array} customers
     * @returns {Array} - returns the customers list with inbound workflow enabled
     */
    const filteredCustomersForInbound = (customers) => {
      if (!customers) return [];
      return customers.filter(
        (item) => item.settings?.workflows?.inbound?.enabled,
      );
    };

    useEffect(() => {
      if (
        !currentScan?.customer &&
        filteredCustomersForInbound(auth?.user?.customersList).length === 1
      ) {
        const updatedCurrentScan = currentScan || {};
        setCurrentScan({
          ...updatedCurrentScan,
          customer: filteredCustomersForInbound(auth.user.customersList)[0].id,
        });
        updateUrlParams(
          "customer",
          filteredCustomersForInbound(auth.user.customersList)[0].id,
        );
      }
    }, [currentScan?.customer]);

    const submitCustomerCode = (customerCodeInput) => {
      if (!customerCodeInput) {
        return appState.setAlert(
          `Please provide a customer code`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
      customerCodeInput = customerCodeInput.value;

      const customersList = filteredCustomersForInbound(
        auth.user.customersList,
      );
      if (!customersList || customersList.length === 0) {
        return appState.setAlert(
          `No customers found`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      let customer = customersList.find(
        (item) => item.id === customerCodeInput,
      );
      if (!customer) {
        return appState.setAlert(
          `No customer found with that code`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      updateUrlParams("customer", customer.id);

      setCurrentScan({ customer: customer.id });
    };

    const onDateChange = (e) => {
      const currentSkuCopy = { ...currentSku };
      currentSkuCopy.bestByDate = e;
      setCurrentSku({
        ...currentSkuCopy,
      });
    };

    useEffect(() => {
      if (inventoryQuery.loading) {
        // appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (inventoryQuery.data) {
        setAvailableInventory(
          inventoryQuery.data.inventory.entities.filter((item) => !!item.sku),
        );
        if (
          inventoryQuery.data.inventory.entities.filter((item) => !!item.sku)
            .length === 0
        ) {
          if (
            inventoryQuery.variables &&
            inventoryQuery.variables.filters &&
            inventoryQuery.variables.filters.id?.length > 0
          ) {
            return inventoryQuery.fetchData({
              perPage: 10,
              pageNumber: 1,
              filters: {
                keyword: inventorySearchKeyword,
                customer: [currentScan.customer],
              },
              paginated: false,
            });
          }
        }
      }

      if (inventoryQuery.error) {
        setAvailableInventory([]);
      }
    }, [inventoryQuery.loading, inventoryQuery.error, inventoryQuery.data]);

    const onChangeInventorySearch = () => {
      // console.log(consignments.length);
      let productIds;
      if (consignments.length > 0) {
        productIds = _.flatten(
          consignments.map((item) => item.workingList.map((i) => i.productId)),
        );
      }
      if (productIds && productIds.length > 0) {
        inventoryQuery.fetchData({
          perPage: 10,
          pageNumber: 1,
          filters: {
            keyword: inventorySearchKeyword,
            customer: [currentScan.customer],
            id: productIds,
          },
          paginated: false,
        });
      } else {
        inventoryQuery.fetchData({
          perPage: 10,
          pageNumber: 1,
          filters: {
            keyword: inventorySearchKeyword,
            customer: [currentScan.customer],
          },
          paginated: false,
        });
      }
    };

    useEffect(() => {
      if (inventorySearchKeyword) {
        debouncedInventorySearch();
      }
    }, [inventorySearchKeyword]);

    const debouncedInventorySearch = _.debounce(onChangeInventorySearch, 1000);

    useEffect(() => {
      if (addLpnQuery.error) {
        appState.setAlert(
          addLpnQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (addLpnQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (addLpnQuery.data) {
        appState.setAlert(
          addLpnQuery.data.addLpnToProduct.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        const lpn = addLpnToProduct.lpn;
        setAddLpnToProduct(null);
        return scanBarcodeQuery.fetchData({
          code: lpn,
          trackingNumber: currentScan.trackingNumber,
          customer: currentScan.customer,
          consignmentId: currentScan.consignmentId,
        });
      }
    }, [addLpnQuery.error, addLpnQuery.loading, addLpnQuery.data]);

    const submitLpn = () => {
      if (!addLpnToProduct) {
        appState.setAlert(
          `Please provide an LPN`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        return;
      }
      if (!addLpnToProduct.productId) {
        appState.setAlert(
          `Please provide a product ID`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        return;
      }
      if (!addLpnToProduct.lpn) {
        appState.setAlert(
          `Please provide an LPN`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        return;
      }
      addLpnQuery.fetchData({
        ...addLpnToProduct,
      });
    };

    const saveAndPrint = () => {
      if (!generateLpn) {
        appState.setAlert(
          `Please generate an LPN`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        return;
      }

      if (!generateLpn.formFactor) {
        appState.setAlert(
          `Please select a form factor`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        return;
      }

      if (!generateLpn.products || generateLpn.products.length === 0) {
        appState.setAlert(
          `Please select at least 1 product`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        return;
      }

      if (!generateLpn.quantity || generateLpn.quantity === 0) {
        appState.setAlert(`Minimum quantity 1`, "error", ALERT_TIMEOUT_IN_MS);
        return;
      }

      for (const product of generateLpn.products) {
        if (!product.productId) {
          appState.setAlert(
            `Please select products`,
            "error",
            ALERT_TIMEOUT_IN_MS,
          );
          return;
        }
        if (!product.quantity || product.quantity === 0) {
          appState.setAlert(
            `Please select at least 1 quantity for a product`,
            "error",
            ALERT_TIMEOUT_IN_MS,
          );
          return;
        }
        if (!product.formFactor) {
          appState.setAlert(
            `Please specify each product's form factor`,
            "error",
            ALERT_TIMEOUT_IN_MS,
          );
          return;
        }
      }
      generateLpnQuery.fetchData({
        trackingNumber: currentScan.trackingNumber
          ? [currentScan.trackingNumber]
          : null,
        customer: currentScan.customer,
        formFactor: generateLpn.formFactor,
        quantity: generateLpn.quantity,
        products: generateLpn.products,
      });
    };

    useEffect(() => {
      if (generateLpnQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (generateLpnQuery.error) {
        appState.setAlert(
          generateLpnQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (generateLpnQuery.data) {
        appState.setAlert(
          generateLpnQuery.data.generateLpn.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        // setPrintedCodes(generateLpnQuery.data.generateLpn.generatedCodes);
        setGeneratedLPN({
          codes: generateLpnQuery.data.generateLpn.generatedCodes,
          station: null,
        });
        setGenerateLpn(null);
      }
    }, [
      generateLpnQuery.error,
      generateLpnQuery.data,
      generateLpnQuery.loading,
    ]);

    useEffect(() => {
      if (cancelItemQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (cancelItemQuery.data) {
        appState.setAlert(
          cancelItemQuery.data.cancelReceivingItem.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        currentSkuQuery.fetchData();
      }

      if (cancelItemQuery.error) {
        appState.setAlert(
          cancelItemQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        currentSkuQuery.fetchData();
      }
    }, [cancelItemQuery.error, cancelItemQuery.data, cancelItemQuery.loading]);

    const cancelItem = () => {
      appState.showConfirmation(
        "Confirm",
        "This action cannot be undone.",
        () => {
          cancelItemQuery.fetchData({
            id: currentSku.id,
          }),
            appState.hideConfirmation();
        },
        appState.hideConfirmation,
      );
    };

    const confirmItemDamaged = () => {
      if (isNaN(currentSku.quantity) || currentSku.quantity <= 0) {
        appState.setAlert(
          `Please input a valid quantity`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        return;
      }
      appState.showConfirmation(
        "Confirmation",
        "Please confirm you would like to mark this item as damaged",
        () => {
          confirmItemQuery.fetchData({
            item: {
              id: currentSku.id,
              quantity: currentSku.quantity,
              bestByDate: currentSku.bestByDate,
              tote: currentSku.tote,
              binLocation: currentSku.binLocation,
              lotId: currentSku.lotId,
              palletId: currentSku.palletId,
            },
            status: "DAMAGED",
          });
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
      );
    };

    const checkPagination = (direction) => {
      let vars = {
        filters: consignmentsData.filters,
        sort: consignmentsData.sort,
        perPage: 25,
      };
      if (direction === "backward") {
        vars["pageNumber"] = consignmentsData.pageNumber - 1;
      } else {
        vars["pageNumber"] = consignmentsData.pageNumber + 1;
      }
      setConsignmentsData({
        ...consignmentsData,
        pageNumber: vars["pageNumber"],
      });
      return consignmentsQuery.fetchData(vars);
    };

    useEffect(() => {
      if (submitNumberOfCartonsQuery.data) {
        appState.setAlert(
          submitNumberOfCartonsQuery.data.submitNumberOfCartons.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        setCurrentScan({
          ...currentScan,
          trackingNumber: undefined,
        });
      }
      if (submitNumberOfCartonsQuery.error) {
        appState.setAlert(
          submitNumberOfCartonsQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
      if (submitNumberOfCartonsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      submitNumberOfCartonsQuery.error,
      submitNumberOfCartonsQuery.loading,
      submitNumberOfCartonsQuery.data,
    ]);

    const confirmNumberOfItems = (manualValue) => {
      const consignmentId = currentScan.consignmentId;

      if (!consignmentId) {
        return appState.setAlert(
          `Please choose a specific consignment`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      return submitNumberOfCartonsQuery.fetchData({
        consignmentId,
        ...manualValue,
      });
    };

    const updateItem = (idx, field, value, isUpdateQuantity = false) => {
      let itemsCopy = [...currentItems];
      itemsCopy[idx][field] = value;
      if (isUpdateQuantity) {
        itemsCopy[idx].availableQuantity = value;
      }
      if (field === "palletId") {
        debounceHandlePalletIdAdded(idx, value, itemsCopy[idx].warehouse);
      }

      if (field === "formFactor") {
        // set total available quantity respecting the form factor
        const totalAvailableQuantity = _.sumBy(
          specificConsignment?.workingList.filter(
            (i) => i.sku === itemsCopy[idx].sku && i.formFactor === value,
          ),
          "availableQuantity",
        );
        itemsCopy[idx].quantity = totalAvailableQuantity || 1;
        itemsCopy[idx].availableQuantity = totalAvailableQuantity || 1;
      }
      setCurrentItems(itemsCopy);
    };

    const removeItem = (idx) => {
      let itemsCopy = [...currentItems];
      if (itemsCopy.length > 1) {
        itemsCopy = itemsCopy.filter((item, index) => index !== idx);
        setCurrentItems(itemsCopy);
      } else {
        appState.setAlert(
          `There needs to be at least 1 item`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
    };

    const addItem = () => {
      let itemsCopy = [...currentItems];
      const itemToPush = {
        ...currentSku,
        formFactor: currentSku.formFactor,
        quantity: 1,
        status: "CONFIRMED",
        isHighlighted: true, // Add this flag
      };
      if (
        appState.tenant?.settings?.activities?.receiving?.putawayBinLocation &&
        productSkuBinMappings?.length > 0
      ) {
        itemToPush.putawayBinLocation = productSkuBinMappings[0].binLocation;
      }
      itemsCopy.push(itemToPush);
      setCurrentItems(itemsCopy);
      setTimeout(() => {
        const newItemElement = document.getElementById(
          `item-${itemsCopy.length - 1}`,
        );
        if (newItemElement) {
          newItemElement.scrollIntoView({ behavior: "smooth" });

          // Remove the highlight after a delay
          setTimeout(() => {
            setCurrentItems((prevItems) =>
              prevItems.map((item, index) =>
                index === itemsCopy.length - 1
                  ? { ...item, isHighlighted: false }
                  : item,
              ),
            );
          }, HIGHLIGHT_DURATION_IN_MS);
        }
      }, SCROLL_DELAY_IN_MS);
    };

    const printCode = (code, numberToPrint, dimensions, print = true, data) => {
      if (!code) {
        return appState.setAlert(
          `Generate/Enter a code first.`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (!dimensions) {
        return appState.setAlert(
          `Specify the dimensions`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (data) {
        data.customer = auth.user.customersList.find(
          (customer) => customer.id === currentScan.customer,
        )?.name;
      }

      numberToPrint = numberToPrint || 1;
      printCanvasLabelPdf(
        `${code}`,
        numberToPrint,
        null,
        null,
        dimensions || "4x6",
        appState,
        print,
        data,
      );
    };

    const bulkPrintCodes = (
      codes,
      numberToPrint,
      dimensions,
      print = true,
      data,
    ) => {
      if (!codes) {
        return appState.setAlert(
          `Generate/Enter a code first.`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (!dimensions) {
        return appState.setAlert(
          `Specify the dimensions`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (data) {
        data.customer = auth.user.customersList.find(
          (customer) => customer.id === currentScan.customer,
        )?.name;
      }

      numberToPrint = numberToPrint || 1;
      printMultiPageLpn(
        codes,
        numberToPrint,
        null,
        null,
        dimensions || "4x6",
        appState,
        print,
        data,
      );
    };

    const updateUrlParams = (paramName, paramValue) => {
      // Create a URLSearchParams object based on the current search string
      const searchParams = new URLSearchParams(location.search);

      searchParams.has(paramName)
        ? searchParams.set(paramName, paramValue)
        : searchParams.append(paramName, paramValue);

      // Use history to update the URL without navigating away
      history.push({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    };

    const debounceHandlePalletIdAdded = _.debounce(
      handlePalletIdAdded,
      DEBOUNCE_TIME_FOR_LPN_CHECK,
    );

    async function handlePalletIdAdded(idx, value, warehouse) {
      if (!!value) {
        const item = currentItems[idx];
        const response = await expandStorageEntityQuery.fetchData({
          code: value,
          warehouse,
          codeType: "palletId",
          includeEmptyMappings: true,
        });
        if (response.data && response.data.expandStorageEntity) {
          const skuBinMappings =
            response.data.expandStorageEntity.skuBinMappings;
          const skuBinMappingsWithInventory = skuBinMappings.filter(
            (skuBinMapping) =>
              skuBinMapping.unallocatedQuantity > 0 ||
              skuBinMapping.allocatedQuantity > 0,
          );

          if (skuBinMappingsWithInventory.length > 0) {
            appState.removeLoading();
            return appState.showConfirmation(
              "Confirmation",
              `This Pallet ID already exists at location ${skuBinMappingsWithInventory[0].binLocation}. If you continue, this would automatically be putaway into that location?`,
              appState.hideConfirmation,
              () => {
                updateItem(idx, "palletId", null);
              },
            );
          } else if (skuBinMappings.length > 0) {
            return appState.showConfirmation(
              "Confirmation",
              `The empty 'Pallet' you just scanned with code '${value}' was last seen at location '${skuBinMappings[0].binLocation}'. Are you sure you want to use this?`,
              appState.hideConfirmation,
              () => {
                updateItem(idx, "palletId", null);
              },
            );
          }
        }
      }
    }

    const handleLpnAdded = async () => {
      appState.setLoading();
      const { item } = addingFormFactor;
      if (!item.nestedFormFactor) {
        appState.removeLoading();
        return appState.setAlert(
          `Select a form factor`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
      if (!item.nestedFormFactorId) {
        appState.removeLoading();
        return appState.setAlert(
          `Enter a code or generate it`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      const response = await expandStorageEntityQuery.fetchData({
        code: item.nestedFormFactorId,
        warehouse: item.warehouse,
        codeType: "nestedFormFactorId",
        includeEmptyMappings: true,
      });

      if (response.error) {
        appState.removeLoading();
        appState.setAlert(response.error.message, "error", ALERT_TIMEOUT_IN_MS);
        return;
      }

      if (response.data && response.data.expandStorageEntity) {
        const skuBinMappings = response.data.expandStorageEntity.skuBinMappings;
        const skuBinMappingsWithInventory = skuBinMappings.filter(
          (skuBinMapping) =>
            skuBinMapping.unallocatedQuantity > 0 ||
            skuBinMapping.allocatedQuantity > 0,
        );
        if (skuBinMappingsWithInventory.length > 0) {
          appState.removeLoading();
          return appState.showConfirmation(
            "Confirmation",
            `This LPN already exists at location ${skuBinMappingsWithInventory[0].binLocation}. If you continue, this would automatically be putaway into that location?`,
            () => {
              appState.removeLoading();
              appState.hideConfirmation();
              setAddingFormFactor(null);
            },
            () => {
              appState.removeLoading();
              setAddingFormFactor({
                ...addingFormFactor,
                nestedFormFactorId: null,
              });
              appState.hideConfirmation();
            },
          );
        } else if (skuBinMappings.length > 0) {
          return appState.showConfirmation(
            "Confirmation",
            `The empty '${item.nestedFormFactor}' you just scanned with code '${item.nestedFormFactorId}' was last seen at location '${skuBinMappings[0].binLocation}'. Are you sure you want to use this?`,
            () => {
              appState.removeLoading();
              setAddingFormFactor(null);
              appState.hideConfirmation();
            },
            () => {
              appState.removeLoading();
              setAddingFormFactor({
                ...addingFormFactor,
                nestedFormFactorId: null,
              });
              appState.hideConfirmation();
            },
          );
        }
      }

      appState.setAlert(
        `LPN ${item.nestedFormFactor} ${item.nestedFormFactorId} added successfully.`,
        "success",
        ALERT_TIMEOUT_IN_MS,
      );
      setAddingFormFactor(null);
      appState.removeLoading();
    };

    const addInventoryTrackingIdRecursively = (baseItem, trackingId) => {
      const item = {
        quantity: trackingId.quantity,
        inventoryTrackingId: trackingId.code,
        formFactor: trackingId.formFactor,
        containerStatus: "CLOSED",
        nestedInventoryTrackingIds: [],
        quantityInBaseUom: calculateQuantityInBaseUom(
          trackingId.quantity,
          trackingId.formFactor,
          currentProduct?.uomConfig?.currentConversionMatrix || {},
        ),
      };

      // Add the current tracking ID to the flattened list
      if (trackingId.code) {
        item.nestedInventoryTrackingIds.push(trackingId.code);
      }

      // If there are children, add them as nested tracking IDs and collect their codes
      if (trackingId.children && trackingId.children.length > 0) {
        item.contents = trackingId.children.map((child) =>
          addInventoryTrackingIdRecursively(baseItem, child),
        );

        // Collect all nested tracking IDs from children
        trackingId.children.forEach((child) => {
          // Recursively get all nested codes from this child
          const getAllNestedCodes = (node) => {
            let codes = [];
            if (node.code) {
              codes.push(node.code);
            }
            if (node.children && node.children.length > 0) {
              node.children.forEach((grandChild) => {
                codes = codes.concat(getAllNestedCodes(grandChild));
              });
            }
            return codes;
          };

          item.nestedInventoryTrackingIds =
            item.nestedInventoryTrackingIds.concat(getAllNestedCodes(child));
        });
      }

      return item;
    };

    const calculateQuantityInBaseUom = (
      quantity,
      formFactor,
      conversionMatrix,
    ) => {
      // If no conversion matrix or no form factor, return original quantity
      if (!conversionMatrix || !formFactor || !conversionMatrix[formFactor]) {
        return quantity;
      }

      // Get the conversion matrix for this form factor
      const formFactorMatrix = conversionMatrix[formFactor];

      // Find the base UOM (usually has the highest conversion ratio)
      const baseUomEntry = Object.entries(formFactorMatrix)
        .sort((a, b) => b[1] - a[1])
        .find(([_, ratio]) => ratio > 0);

      // If no valid conversion found, return original quantity
      if (!baseUomEntry) {
        return quantity;
      }

      // Calculate quantity in base UOM
      return quantity * baseUomEntry[1];
    };

    return (
      <WrappedComponent
        currentSku={currentSku}
        showScanner={showScanner}
        closeScanner={() => setShowScanner(false)}
        scanBarcode={scanBarcode}
        checkConsignmentById={checkConsignmentById}
        decreaseItemQuantity={decreaseItemQuantity}
        increaseItemQuantity={increaseItemQuantity}
        confirmPrepItem={confirmPrepItem}
        confirmItem={() => {
          if (
            currentItems.find(
              (item) => isNaN(item.quantity) || item.quantity <= 0,
            )
          ) {
            appState.setAlert(
              `Please input a valid quantity for all items`,
              "error",
              ALERT_TIMEOUT_IN_MS,
            );
            return;
          }

          const itemsToConfirm = {
            item: {
              id: currentSku.id,
              quantity: currentSku.quantity,
              bestByDate: currentSku.bestByDate,
              lotId: currentSku.lotId,
              palletId: currentSku.palletId,
              tote: currentSku.tote,
              binLocation: currentSku.binLocation,
              remarks: currentSku.remarks,
              formFactor: currentSku.formFactor,
              status: currentSku.status,
              serialNumber: currentSku.serialNumber,
              poId: currentSku.poId,
              lineItemId: currentSku.lineItemId,
            },
            items: [],
          };

          for (const item of currentItems) {
            if (item.inventoryTrackingIds?.length > 0) {
              // push one copy for every inventory tracking id
              for (const inventoryTrackingId of item.inventoryTrackingIds) {
                const baseItem = {
                  id: currentSku.id,
                  quantity: 1,
                  bestByDate: item.bestByDate,
                  lotId: item.lotId,
                  palletId: item.palletId,
                  tote: currentSku.tote,
                  binLocation: currentSku.binLocation,
                  remarks: item.remarks,
                  formFactor: item.formFactor,
                  status: item.status,
                  consignmentId: item.consignmentId,
                  nestedFormFactor: item.nestedFormFactor,
                  nestedFormFactorId: item.nestedFormFactorId,
                  serialNumber: item.serialNumber,
                  poId: item.poId,
                  lineItemId: item.lineItemId,
                  isStrictPutaway: item.putawayBinLocation ? true : false,
                  putawayBinLocation: item.putawayBinLocation || null,
                };

                itemsToConfirm.items.push(
                  addInventoryTrackingIdRecursively(
                    baseItem,
                    inventoryTrackingId,
                  ),
                );
                itemsToConfirm.items = itemsToConfirm.items.map((i) => ({
                  ...i,
                  status: item.status,
                  id: currentSku.id,
                }));
              }
            } else {
              itemsToConfirm.items.push({
                id: currentSku.id,
                quantity: item.quantity,
                bestByDate: item.bestByDate,
                lotId: item.lotId,
                palletId: item.palletId,
                tote: currentSku.tote,
                binLocation: currentSku.binLocation,
                remarks: item.remarks,
                formFactor: item.formFactor,
                status: item.status,
                consignmentId: item.consignmentId,
                nestedFormFactor: item.nestedFormFactor,
                nestedFormFactorId: item.nestedFormFactorId,
                serialNumber: item.serialNumber,
                poId: item.poId,
                lineItemId: item.lineItemId,
                isStrictPutaway: item.putawayBinLocation ? true : false,
                putawayBinLocation: item.putawayBinLocation || null,
              });
            }
          }
          confirmItemQuery.fetchData(itemsToConfirm);
        }}
        loading={currentSkuQuery.loading}
        currentScan={currentScan}
        setCurrentScan={setCurrentScan}
        customers={filteredCustomersForInbound(auth?.user?.customersList)}
        submitCustomerCode={submitCustomerCode}
        onDateChange={onDateChange}
        cancelCurrentCustomerAndTracking={() => {
          if (currentScan) {
            if (currentScan.trackingNumber !== undefined) {
              setCurrentScan({
                ...currentScan,
                trackingNumber: undefined,
              });
            } else if (currentScan?.consignmentId !== undefined) {
              setCurrentScan({
                ...currentScan,
                consignmentId: undefined,
              });
            } else if (currentScan?.customer !== undefined) {
              setCurrentScan(null);
              setTrackingNumber(undefined);
              history.push({
                pathname: location.pathname,
                search: "",
              });
            }
          } else {
            setCurrentScan(null);
            setTrackingNumber(undefined);
          }
        }}
        availableInventory={availableInventory}
        addLpnToProduct={addLpnToProduct}
        setAddLpnToProduct={setAddLpnToProduct}
        generateLpn={generateLpn}
        setGenerateLpn={setGenerateLpn}
        inventorySearchKeyword={inventorySearchKeyword}
        setInventorySearchKeyword={setInventorySearchKeyword}
        submitLpn={submitLpn}
        submitNullValue={() => {
          setTrackingNumber(null);
          checkForConsignmentQuery.fetchData({
            trackingNumber: null,
            customer: currentScan.customer,
          });
        }}
        saveAndPrint={saveAndPrint}
        printedCodes={printedCodes}
        setPrintedCodes={setPrintedCodes}
        cancelItem={cancelItem}
        stations={stations}
        generatedLPN={generatedLPN}
        setGeneratedLPN={setGeneratedLPN}
        currentUser={auth.user}
        onSelectStation={(e) => setSelectedStation(e)}
        onSubmitStation={() => {
          if (selectedStation) {
            updateUrlParams("station", selectedStation);
            setStationQuery.fetchData({ station: selectedStation });
          } else {
            appState.setAlert(
              "Please select a station",
              "error",
              ALERT_TIMEOUT_IN_MS,
            );
          }
        }}
        selectedStation={selectedStation}
        confirmItemDamaged={confirmItemDamaged}
        setCurrentSku={setCurrentSku}
        codeToPrint={codeToPrint}
        consignments={consignmentsData.consignments}
        total={consignmentsData.total}
        pageNumber={consignmentsData.pageNumber}
        checkPagination={checkPagination}
        perPage={consignmentsData.perPage}
        specificConsignment={specificConsignment}
        filters={consignmentsData.filters}
        setFilters={(filters) =>
          setConsignmentsData({
            ...consignmentsData,
            filters,
          })
        }
        submitFilters={() =>
          consignmentsQuery.fetchData({
            perPage: 25,
            pageNumber: 1,
            filters: consignmentsData.filters,
          })
        }
        consignmentsData={consignmentsData}
        subdomain={appState.subdomain}
        confirmNumberOfItems={confirmNumberOfItems}
        currentItems={currentItems}
        setCurrentItems={setCurrentItems}
        updateItem={updateItem}
        removeItem={removeItem}
        addItem={addItem}
        logout={() => logoutQuery.fetchData()}
        bolDoc={bolDoc}
        setBolDoc={setBolDoc}
        tenant={appState.tenant}
        statusList={statusList}
        currentProduct={currentProduct}
        printCode={printCode}
        bulkPrintCodes={bulkPrintCodes}
        prepEnabled={
          appState?.tenant?.features?.combinedReceiveAndPrep === true
        }
        eligibleConsignments={consignments}
        productSkuBinMappings={productSkuBinMappings}
        currentParentProduct={currentParentProduct}
        handleLpnAdded={handleLpnAdded}
        addingFormFactor={addingFormFactor}
        setAddingFormFactor={setAddingFormFactor}
      />
    );
  };
};

export default withInwardReceiverLogic;
