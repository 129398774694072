import React, { useState, useEffect } from "react";
import _ from "lodash";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/outline";
import { GET_PRODUCT_VARIANT_COMPONENT } from "#queries";
import { useQuery } from "#hooks/useQuery";
import noFilterIcon from "#static/images/nofilter.png";

const ProductDetails = ({
  product = null,
  allVariantComponents = [],
  setAllVariantComponents = () => {},
}) => {
  const [collapsed, setCollapsed] = useState([]);
  const [allComponentDetails, setAllComponentDetails] = useState([]);
  const productComponentQuery = useQuery(GET_PRODUCT_VARIANT_COMPONENT);

  const toggleCollapse = (indx) => {
    let collapsedData = [...collapsed];
    collapsedData[indx] = !collapsedData[indx];
    setCollapsed(collapsedData);
  };

  const getAllVariantComponentDetails = async (variantComponentsIds) => {
    try {
      const productComponentResponse = await productComponentQuery.fetchData({
        perPage: variantComponentsIds.length,
        pageNumber: 1,
        filters: {
          componentId: variantComponentsIds,
        },
      });
      if (
        productComponentResponse?.data?.getProductVariantComponent?.entities
          ?.length > 0
      ) {
        setAllVariantComponents(
          productComponentResponse.data.getProductVariantComponent.entities,
        );
      }
    } catch (e) {
      console.log("Error while fetching product variant component", e);
    }
  };

  useEffect(() => {
    allComponentDetails?.length > 0 && setAllComponentDetails([]);
    allVariantComponents?.length > 0 && setAllVariantComponents([]);
  }, [product]);

  useEffect(() => {
    if (product) {
      const costPriceDetails = product?.costPrice?.length
        ? product?.costPrice
        : null;
      if (!costPriceDetails) return;
      const hasComponentDetails = costPriceDetails?.some(
        (item) => item?.componentDetails,
      );
      const allVariantComponentsId = hasComponentDetails
        ? costPriceDetails[0]?.componentDetails?.map((item) => item?.id) || []
        : Object.keys(costPriceDetails[0]).filter((key) =>
            _.isObject(costPriceDetails[0][key]),
          );
      if (allVariantComponentsId?.length > 0) {
        setAllComponentDetails(
          costPriceDetails[0]?.componentDetails ||
            Object.keys(costPriceDetails[0])
              ?.filter((key) => _.isObject(costPriceDetails[0][key]))
              ?.map((componentVariantId) => ({
                ...costPriceDetails[0][componentVariantId],
              })) ||
            [],
        );
        getAllVariantComponentDetails([...allVariantComponentsId]);
      }
    }
  }, [product]);

  useEffect(() => {
    if (product?.components?.length > 0) {
      const componentCollapse = Array.from(
        { length: product.components.length },
        () => false,
      );
      setCollapsed(componentCollapse);
    } else {
      collapsed.length > 0 && setCollapsed([]);
    }
  }, [product]);

  return (
    <div className="my-8 rounded-lg p-6 shadow">
      {product && (
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <div>
            <div>
              <h2 className="text-xl font-semibold">{product?.name}</h2>
              <p className="text-gray-600">SKU: {product?.sku}</p>
              {allComponentDetails?.length > 0 && (
                <div className="mt-4 gap-2">
                  <h3 className="mb-2 text-lg font-medium">Components:</h3>
                  <div className="space-y-3">
                    {allComponentDetails?.map((componentObject, indx) => {
                      const selectedComponent = allVariantComponents?.find(
                        (variantObject) =>
                          variantObject.id === componentObject?.id,
                      );
                      return (
                        <div
                          className="space-y-3"
                          key={`priceComponent-${indx}`}>
                          <div className="overflow-hidden rounded-lg bg-gray-50">
                            <button
                              className="flex w-full items-center justify-between bg-gray-100 px-4 py-3 transition-colors hover:bg-gray-200"
                              onClick={() => toggleCollapse(indx)}>
                              <div className="flex items-center space-x-2">
                                <h4 className="font-medium text-gray-900">
                                  {selectedComponent?.componentName}
                                </h4>
                              </div>
                              {collapsed[indx] ? (
                                <ChevronUpIcon className="h-6 w-6 text-gray-500 transition-transform" />
                              ) : (
                                <ChevronDownIcon className="h-6 w-6 text-gray-500 transition-transform" />
                              )}
                            </button>
                            {collapsed[indx] && (
                              <div>
                                <div className="p-4">
                                  <div className="mb-3 grid grid-cols-2 gap-2 text-sm">
                                    <div>
                                      <span className="capitalize text-gray-500">
                                        Weight:
                                      </span>
                                      <span className="font-medium">
                                        {` ${componentObject?.quantity} `}
                                      </span>
                                      <span className="font-small text-xs">
                                        {selectedComponent?.uom}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
          {product?.images?.[0]?.display_url && (
            <div className="flex items-start justify-center">
              <img
                src={product?.images[0]?.display_url}
                alt="Diamond Solitaire Ring"
                className="max-h-48 rounded-lg object-contain shadow-lg"
              />
            </div>
          )}
        </div>
      )}
      {!product && (
        <div className="w-full flex-col items-center justify-center text-center">
          <img src={noFilterIcon} className="mx-auto h-[212px]" />
          <div className="mt-4 text-center font-inter text-2xl font-semibold text-black">
            No product found
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
