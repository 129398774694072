import { useState, useContext } from "react";
import SlideOverPanel from "#components/common/SlideOverPanel";
import { FALLBACK_IMAGE_URL } from "#constants/fallback-image-urls";
import PrimaryButton from "../../newUiComponents/commons/PrimaryButton";
import { PrinterIcon, ClipboardListIcon } from "@heroicons/react/outline";
import { XIcon, DownloadIcon } from "@heroicons/react/outline";
import { twMerge } from "tailwind-merge";
import { AppStateContext } from "#contexts/appState";
import { SUPPORTED_SIZES_WITHOUT_CANVAS } from "#utils/printCanvasLabelPdf";
import Modal from "#components/utils/Modal";
import Dropdown from "#components/utils/Dropdown";
import NumberField from "#components/utils/NumberField";
import QRCode from "qrcode.react";
import _ from "lodash";
import SwitchComponent from "#components/utils/Switch";
import {
  buildHierarchicalInventoryTracking,
  getAllPossibleLevels,
} from "#utils/inventoryTracking";

const ALERT_TIMEOUT_IN_MS = 3000;

// Helper function to collect all codes from an item and its children
const getAllCodes = (item, trackingLevels, enableNestedTracking) => {
  if (!item.code || trackingLevels[item.formFactor] === false) {
    return [];
  }

  let codes = [item];
  if (item.children?.length > 0 && enableNestedTracking) {
    item.children.forEach((child) => {
      codes = codes.concat(
        getAllCodes(child, trackingLevels, enableNestedTracking),
      );
    });
  }
  return codes;
};

const PrintOptions = ({
  codes,
  setPrintingCodes,
  bulkPrintCodes,
  printCode,
  item,
  enableNestedTracking,
}) => {
  const supportedSizes = [...SUPPORTED_SIZES_WITHOUT_CANVAS, "4x6-LPN"];
  const [printOptions, setPrintOptions] = useState({});

  // Helper function to check if there are any tracked children
  const hasTrackedChildren = (trackingIds) => {
    return trackingIds.some(
      (item) =>
        item.children?.length > 0 &&
        item.children.some(
          (child) => item.trackingLevels?.[child.formFactor] !== false,
        ),
    );
  };

  // Check if there are any tracked children in the inventory
  const hasChildren =
    enableNestedTracking &&
    item?.inventoryTrackingIds &&
    hasTrackedChildren(item.inventoryTrackingIds);

  const triggerPrints = (print = false) => {
    if (printOptions.dimensions === "4x6-LPN") {
      bulkPrintCodes(
        codes,
        printOptions.numberOfCodes || 1,
        printOptions.dimensions,
        print,
        printOptions.includeTrackingDetails
          ? { ...item, includeTrackingDetails: true }
          : null,
      );
    } else {
      const printPromises = codes.map((codeObj) =>
        printCode(
          codeObj.code,
          printOptions.numberOfCodes || 1,
          printOptions.dimensions,
          print,
          printOptions.includeTrackingDetails
            ? { ...item, includeTrackingDetails: true }
            : null,
        ),
      );

      // Wait for all prints to complete
      Promise.all(printPromises).then(() => {
        setPrintingCodes(false);
      });
    }
  };

  return (
    <Modal
      open={true}
      negativeText="OK"
      negativeAction={() => setPrintingCodes(false)}
      title="Print Options">
      <div className="mt-4 font-montserrat text-lg font-medium text-454A4F">
        Optional Print
      </div>
      <div className="flex space-x-2">
        <Dropdown
          placeholder={"Select Dimensions"}
          list={_.uniq(supportedSizes).map((i) => ({
            name: i.replace("-LPN", ""),
            value: i,
          }))}
          labelKey="name"
          valueKey="value"
          name="printSize"
          setSelected={(e) =>
            setPrintOptions({ ...printOptions, dimensions: e })
          }
          selectedValue={printOptions.dimensions}
        />
        <NumberField
          onChange={(e) =>
            setPrintOptions({
              ...printOptions,
              numberOfCodes: parseInt(e.target.value),
            })
          }
          placeholder="# to print"
          value={printOptions.numberOfCodes}
          className="p-4 text-lg"
          min="0"
        />
      </div>
      {["4x6-LPN"].includes(printOptions.dimensions) && (
        <>
          <div className="mt-4 flex items-center space-x-2">
            <input
              type="checkbox"
              onChange={(e) =>
                setPrintOptions({
                  ...printOptions,
                  includeTrackingDetails: e.target.checked,
                })
              }
              checked={printOptions.includeTrackingDetails}
              className="h-6 w-6 rounded-md border-2 border-2C7695"
            />
            <span className="font-montserrat text-lg font-medium text-454A4F">
              Include Product and Tracking Details (eg. Name, Expiry, Lot ID,
              Serial Number)
            </span>
          </div>
          {/* Only show nested codes option if there are tracked children */}
          {hasChildren && (
            <div className="mt-4 flex items-center space-x-2">
              <input
                type="checkbox"
                onChange={(e) =>
                  setPrintOptions({
                    ...printOptions,
                    printNestedCodes: e.target.checked,
                  })
                }
                checked={printOptions.printNestedCodes}
                className="h-6 w-6 rounded-md border-2 border-2C7695"
              />
              <span className="font-montserrat text-lg font-medium text-454A4F">
                Print Nested Codes
              </span>
            </div>
          )}
        </>
      )}
      <div className="mt-4 flex space-x-2">
        <button
          type="button"
          className="inline-flex items-center rounded-md border-transparent bg-2C7695 px-4 py-2 font-montserrat text-lg font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0"
          onClick={() => triggerPrints(true)}>
          Print
        </button>
        <button
          type="button"
          className="inline-flex items-center rounded-md border-transparent bg-2C7695 px-4 py-2 font-montserrat text-lg font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0"
          onClick={() => triggerPrints(false)}>
          Download
        </button>
      </div>
    </Modal>
  );
};

const InventoryTrackingItem = ({
  item,
  level = 0,
  uomConfig,
  parentFormFactor,
  parentCode,
  enableNestedTracking = false,
  trackingLevels,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const conversionMatrix =
    uomConfig?.currentConversionMatrix[item.formFactor] || {};

  const childFormFactor = Object.entries(conversionMatrix)
    .sort((a, b) => a[1] - b[1])
    .find(([factor, ratio]) => ratio > 1)?.[0];

  const childQuantity = childFormFactor ? conversionMatrix[childFormFactor] : 0;
  const hasChildren =
    enableNestedTracking && childFormFactor && childQuantity > 1;

  // Display the friendly name instead of the unique ID
  const displayId = `${item.formFactor}-${item.index}`;

  // Check if this level should be rendered based on trackingLevels
  if (trackingLevels[item.formFactor] === false) {
    return null;
  }

  return (
    <div className="w-full">
      <div
        style={{
          gridTemplateColumns: "3fr 3fr 1fr",
          paddingLeft: enableNestedTracking ? `${level * 2}rem` : "0",
        }}
        className="grid grid-cols-6 items-start gap-4 p-4">
        <div className="flex items-center">
          {hasChildren && (
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="mr-2 text-gray-500 hover:text-gray-700">
              {isExpanded ? "−" : "+"}
            </button>
          )}
          <span>
            {displayId}
            {hasChildren && ` (${childQuantity} ${childFormFactor})`}
          </span>
        </div>
        <div>
          <div className="relative flex items-center rounded-md border border-borderGray bg-white p-2">
            <input
              type="text"
              className="w-full border-none bg-white p-2 outline-none ring-0 focus:outline-none focus:ring-0"
              value={item.code}
              placeholder="Enter Code"
              onChange={(e) => props.onCodeChange(e, item.id)}
              id={`${item.formFactor}-${item.id}`}
              onKeyDown={(e) => props.onCodeChange(e, item.id)}
              onBlur={(e) => props.onBlur(e, item.id)}
              onFocus={props.onFocus}
            />
            {item.code && (
              <button onClick={() => props.clearCode(item.id)}>
                <XIcon className="h-6 w-6" />
              </button>
            )}
          </div>
          {item.error && <div className="text-red-500">{item.error}</div>}
        </div>
        <div className="flex items-center space-x-4">
          <div
            className={twMerge(
              `mt-4 flex cursor-pointer rounded-md text-primaryAccent`,
            )}
            onClick={() => props.generateCodeForItem(item.id)}
            title="Generate Code Automatically">
            <ClipboardListIcon className="h-6 w-6" />
          </div>
          <div
            className={twMerge(
              `mt-4 flex ${
                item.code
                  ? "cursor-pointer text-primaryAccent"
                  : "text-gray-500"
              }`,
            )}
            onClick={() =>
              item.code
                ? props.setPrintingCodes(
                    getAllCodes(item, trackingLevels, enableNestedTracking),
                  )
                : null
            }>
            <PrinterIcon className="h-6 w-6" />
          </div>
        </div>
      </div>

      {isExpanded && hasChildren && (
        <div className="ml-8 border-l-2 border-gray-200">
          {item.children?.map((childItem) => (
            <InventoryTrackingItem
              key={childItem.id}
              item={childItem}
              level={level + 1}
              uomConfig={uomConfig}
              parentFormFactor={item.formFactor}
              parentCode={item.code}
              enableNestedTracking={enableNestedTracking}
              trackingLevels={trackingLevels}
              {...props}
            />
          ))}
        </div>
      )}
    </div>
  );
};

// Move both helper functions outside components
const getOrderedHierarchyLevels = (conversionMatrix = {}) => {
  return Object.keys(conversionMatrix).sort((a, b) => a - b);
};

const getHierarchyLevels = (conversionMatrix = {}) => {
  return getOrderedHierarchyLevels(conversionMatrix);
};

// Helper function to get the previous level in the hierarchy
const getPreviousLevel = (currentLevel) => {
  const levels = getHierarchyLevels();
  const currentIndex = levels.indexOf(currentLevel);
  return currentIndex > 0 ? levels[currentIndex - 1] : null;
};

const buildTrackingStructure = (
  item,
  uomConfig,
  trackingLevels,
  parentFormFactor = null,
) => {
  const conversionMatrix =
    uomConfig?.currentConversionMatrix[item.formFactor] || {};

  // Find the next level form factor and its ratio
  const childFormFactor = Object.entries(conversionMatrix)
    .sort((a, b) => a[1] - b[1])
    .find(([factor, ratio]) => ratio > 1)?.[0];

  const childQuantity = childFormFactor ? conversionMatrix[childFormFactor] : 0;

  // Base structure for current item
  const trackingItem = {
    quantity: 1,
    code: trackingLevels[item.formFactor] ? item.code : null,
    formFactor: item.formFactor,
    containerStatus: "CLOSED",
    children: [],
  };

  // If there are no children or no child form factor, return current item
  if (!childFormFactor || childQuantity <= 1) {
    return trackingItem;
  }

  // Handle children based on whether the child level is being tracked
  if (trackingLevels[childFormFactor]) {
    // Create individual entries for each tracked child
    trackingItem.children =
      item.children?.map((child) =>
        buildTrackingStructure(
          child,
          uomConfig,
          trackingLevels,
          item.formFactor,
        ),
      ) || [];
  } else {
    // Create single entry for untracked children with aggregated quantity
    trackingItem.children = [
      {
        quantity: childQuantity,
        code: null,
        formFactor: childFormFactor,
        containerStatus: "CLOSED",
        children: [],
      },
    ];
  }

  return trackingItem;
};

const InventoryTrackingPanel = ({
  receivingItem,
  product,
  closePanel,
  submitInventoryTracking,
  bulkPrintCodes,
  printCode,
}) => {
  const appState = useContext(AppStateContext);
  const [updatedItem, setUpdatedItem] = useState(() => ({
    ...receivingItem,
    // Initialize with existing tracking levels or defaults
    trackingLevels:
      receivingItem.trackingLevels ||
      (() => {
        const levels = getAllPossibleLevels(
          product?.uomConfig,
          receivingItem.formFactor,
        );
        return Object.fromEntries(levels.map((level) => [level, true]));
      })(),
    // Initialize with existing tracking IDs or build new ones
    inventoryTrackingIds:
      receivingItem.inventoryTrackingIds ||
      buildHierarchicalInventoryTracking(
        receivingItem.quantity,
        receivingItem.formFactor,
        product.uomConfig,
        "",
        1,
        receivingItem.trackingLevels,
      ),
    // Initialize enableNestedTracking from receivingItem instead of defaulting to true
    enableNestedTracking: receivingItem.enableNestedTracking ?? true,
  }));
  const [printingCodes, setPrintingCodes] = useState(false);
  const [selectedLevels, setSelectedLevels] = useState(() => {
    // Update this call to pass the conversion matrix
    const levels = getOrderedHierarchyLevels(
      product?.uomConfig?.currentConversionMatrix?.[updatedItem.formFactor] ||
        {},
    );
    return levels.reduce(
      (acc, level) => ({
        ...acc,
        [level]: level !== updatedItem.formFactor,
      }),
      {},
    );
  });
  const [enableNesting, setEnableNesting] = useState(true);

  const checkAndSubmitInventoryTracking = () => {
    const processedItems = updatedItem.inventoryTrackingIds.map((item) =>
      buildTrackingStructure(
        item,
        product.uomConfig,
        updatedItem.trackingLevels,
      ),
    );

    // Validate that all required tracking codes are present
    const hasAllRequiredCodes = processedItems.every((item) => {
      if (updatedItem.trackingLevels[item.formFactor] && !item.code) {
        return false;
      }
      return true;
    });

    if (!hasAllRequiredCodes) {
      appState.setAlert(
        "Please enter a code for all tracked items",
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
      return;
    }

    const itemToSubmit = {
      ...updatedItem,
      inventoryTrackingIds: processedItems,
    };

    submitInventoryTracking(itemToSubmit);
  };

  const generateCodeForItemAndChildren = (item) => {
    const generateCode = () =>
      Math.floor(100000000000 + Math.random() * 900000000000).toString();

    // If this level is not being tracked, return the item without changes
    if (updatedItem.trackingLevels[item.formFactor] === false) {
      return item;
    }

    return {
      ...item,
      code: item.code || generateCode(),
      children:
        item.children?.map((child) => generateCodeForItemAndChildren(child)) ||
        [],
    };
  };

  const generateCodesAutomatically = () => {
    const newInventoryTrackingIds = updatedItem.inventoryTrackingIds.map(
      (item) => generateCodeForItemAndChildren(item),
    );

    setUpdatedItem((prev) => ({
      ...prev,
      inventoryTrackingIds: newInventoryTrackingIds,
    }));
  };

  const generateCodeForItem = (targetId) => {
    const generateCode = () =>
      Math.floor(10000000 + Math.random() * 90000000).toString();

    // Helper function to check if item has children
    const hasChildren = (items, id) => {
      const findItem = (items) => {
        for (const item of items) {
          if (item.id === id) {
            return item.children?.length > 0;
          }
          if (item.children?.length) {
            const found = findItem(item.children);
            if (found !== undefined) return found;
          }
        }
      };
      return findItem(items);
    };

    // Function to update item and optionally its children
    const updateItem = (item, includeChildren = false) => {
      if (item.id === targetId) {
        return {
          ...item,
          code: generateCode(),
          children: includeChildren
            ? item.children?.map((child) => ({
                ...child,
                code: generateCode(),
              }))
            : item.children,
        };
      }

      if (item.children?.length) {
        return {
          ...item,
          children: item.children.map((child) =>
            updateItem(child, includeChildren),
          ),
        };
      }

      return item;
    };

    // Check if target item has children
    const itemHasChildren = hasChildren(
      updatedItem.inventoryTrackingIds,
      targetId,
    );

    if (itemHasChildren) {
      appState.showConfirmation(
        "Generate Codes",
        "Do you want to generate codes for all items inside as well?",
        () => {
          // User clicked Yes - generate codes for parent and children
          const newInventoryTrackingIds = updatedItem.inventoryTrackingIds.map(
            (item) => updateItem(item, true),
          );
          setUpdatedItem((prev) => ({
            ...prev,
            inventoryTrackingIds: newInventoryTrackingIds,
          }));
          appState.hideConfirmation();
        },
        () => {
          // User clicked No - generate code only for parent
          const newInventoryTrackingIds = updatedItem.inventoryTrackingIds.map(
            (item) => updateItem(item, false),
          );
          setUpdatedItem((prev) => ({
            ...prev,
            inventoryTrackingIds: newInventoryTrackingIds,
          }));
          appState.hideConfirmation();
        },
        `Yes`,
        `No, just for the parent`,
      );
    } else {
      // No children - just generate code for the single item
      const newInventoryTrackingIds = updatedItem.inventoryTrackingIds.map(
        (item) => updateItem(item, false),
      );
      setUpdatedItem((prev) => ({
        ...prev,
        inventoryTrackingIds: newInventoryTrackingIds,
      }));
    }
  };

  const handleValidationAndFocusOnNextInputField = (e, id) => {
    // Move focus to next field only if Enter key was pressed
    if (e.type === "keydown" && e.key === "Enter") {
      // Flatten the hierarchy to get a linear list of all input fields, but only for tracked levels
      const getFlattenedItems = (items) => {
        return items.reduce((acc, item) => {
          // Only include items from tracked levels
          if (updatedItem.trackingLevels[item.formFactor] !== false) {
            acc.push(item);
            if (item.children?.length) {
              acc.push(...getFlattenedItems(item.children));
            }
          }
          return acc;
        }, []);
      };

      const flattenedItems = getFlattenedItems(
        updatedItem.inventoryTrackingIds,
      );

      // Find current item's index in flattened list
      const currentIndex = flattenedItems.findIndex((item) => item.id === id);

      // Find next input field
      if (currentIndex !== -1 && currentIndex < flattenedItems.length - 1) {
        const nextItem = flattenedItems[currentIndex + 1];
        const nextInput = document.getElementById(
          `${nextItem.formFactor}-${nextItem.id}`,
        );

        if (nextInput) {
          nextInput.focus();
        }
      }
    }

    // Rest of validation logic remains unchanged
    setUpdatedItem((prev) => {
      const findParentCode = (items, targetId) => {
        for (const item of items) {
          if (item.children?.length) {
            const hasChild = item.children.some(
              (child) => child.id === targetId,
            );
            if (hasChild) {
              return item.code;
            }
            const parentCode = findParentCode(item.children, targetId);
            if (parentCode) return parentCode;
          }
        }
        return null;
      };

      const newCode = e.target.value;
      const parentCode = findParentCode(prev.inventoryTrackingIds, id);

      // Update only the specific item's code using a recursive function
      const updateItemCode = (items) => {
        return items.map((item) => {
          if (item.id === id) {
            return { ...item, code: newCode, error: null };
          }
          if (item.children?.length) {
            return {
              ...item,
              children: updateItemCode(item.children),
            };
          }
          return item;
        });
      };

      // First update the code
      const newState = {
        ...prev,
        inventoryTrackingIds: updateItemCode(prev.inventoryTrackingIds),
      };

      // Validation logic
      if (!newCode) {
        newState.inventoryTrackingIds = updateItemAndChildrenRecursively(
          newState.inventoryTrackingIds,
          id,
          (item) => ({ ...item, error: "Code cannot be empty" }),
        );
        return newState;
      }

      const hasDuplicate = findDuplicateCode(
        newState.inventoryTrackingIds,
        newCode,
        id,
        parentCode,
      );

      if (hasDuplicate) {
        newState.inventoryTrackingIds = updateItemAndChildrenRecursively(
          newState.inventoryTrackingIds,
          id,
          (item) => ({
            ...item,
            code: newCode, // Keep the new code even if duplicate
            error: `Code must be unique. ${newCode} already exists`,
          }),
        );
      }

      return newState;
    });
  };

  // Add these helper functions
  const updateItemAndChildrenRecursively = (items, targetId, updateFn) => {
    return items.map((item) => {
      if (item.id === targetId) {
        return updateFn(item);
      }
      if (item.children?.length) {
        return {
          ...item,
          children: updateItemAndChildrenRecursively(
            item.children,
            targetId,
            updateFn,
          ),
        };
      }
      return item;
    });
  };

  const findDuplicateCode = (items, code, excludeId, parentCode = null) => {
    for (const item of items) {
      // Skip check if this is a child and its parent has the same code
      if (parentCode && parentCode === code) {
        return false;
      }

      // Check if current item has duplicate code
      if (item.code === code && item.id !== excludeId) {
        return true;
      }

      // Check children with current item's code as parent code
      if (item.children?.length) {
        if (findDuplicateCode(item.children, code, excludeId, item.code)) {
          return true;
        }
      }
    }
    return false;
  };

  const clearCode = (itemId) => {
    setUpdatedItem((prev) => ({
      ...prev,
      inventoryTrackingIds: updateItemAndChildrenRecursively(
        prev.inventoryTrackingIds,
        itemId,
        (item) => ({ ...item, code: "", error: null }),
      ),
    }));
  };

  const getLocalHierarchyLevels = () => {
    return getHierarchyLevels(
      product?.uomConfig?.currentConversionMatrix?.[updatedItem.formFactor] ||
        {},
    );
  };

  // Add this helper function
  const handleLevelChange = (level) => {
    const levels = getLocalHierarchyLevels();
    const levelIndex = levels.indexOf(level);

    setSelectedLevels((prev) => {
      const newState = { ...prev };
      // If unchecking, uncheck all child levels
      if (!prev[level]) {
        for (let i = levelIndex; i < levels.length; i++) {
          newState[levels[i]] = false;
        }
      } else {
        // If checking, ensure all parent levels are checked
        for (let i = 0; i <= levelIndex; i++) {
          newState[levels[i]] = true;
        }
      }
      return newState;
    });
  };

  const renderTrackingLevelControls = () => {
    if (!updatedItem.enableNestedTracking) return null;

    const levels = getAllPossibleLevels(
      product.uomConfig,
      updatedItem.formFactor,
    );

    return (
      <div className="flex items-center space-x-4 border-l border-gray-200 py-4">
        <span className="text-sm font-medium text-gray-700">Track Levels:</span>
        <div className="flex space-x-4">
          {levels.map((level, index) => {
            const isParentLevel = index === 0;
            const isChecked = updatedItem.trackingLevels?.[level] ?? true;

            // Parent level (Cases) should always be enabled and checked
            const isDisabled = isParentLevel;

            const handleLevelChange = () => {
              // Don't allow changes to parent level
              if (isParentLevel) return;

              setUpdatedItem((prev) => {
                const newTrackingLevels = { ...(prev.trackingLevels || {}) };
                newTrackingLevels[level] = !isChecked;

                // When disabling Eaches, we want to rebuild with only Cases
                // When enabling Eaches, we want to rebuild with both levels
                const activeFormFactors = !isChecked
                  ? [levels[0]] // Only Cases
                  : levels; // Both Cases and Eaches

                // Rebuild inventory tracking with only the active form factors
                const newInventoryTrackingIds =
                  buildHierarchicalInventoryTracking(
                    prev.quantity,
                    prev.formFactor,
                    product.uomConfig,
                    "",
                    1,
                    Object.fromEntries(activeFormFactors.map((f) => [f, true])),
                  );

                return {
                  ...prev,
                  trackingLevels: newTrackingLevels,
                  inventoryTrackingIds: newInventoryTrackingIds,
                };
              });
            };

            return (
              <label
                key={level}
                className={`flex items-center space-x-2 ${isDisabled ? "opacity-50" : ""}`}>
                <input
                  type="checkbox"
                  checked={isChecked}
                  disabled={isDisabled}
                  onChange={handleLevelChange}
                  className="h-4 w-4 rounded border-gray-300 text-primaryAccent focus:ring-primaryAccent"
                />
                <span
                  className={`text-sm ${isDisabled ? "text-gray-400" : "text-gray-700"}`}>
                  {level}
                </span>
              </label>
            );
          })}
        </div>
      </div>
    );
  };

  const getAllTrackableCodes = () => {
    // Get all codes that should be printed based on tracking levels
    const codes = [];
    const processItem = (item) => {
      if (updatedItem.trackingLevels[item.formFactor] !== false && item.code) {
        codes.push(item);
      }
      if (item.children && updatedItem.enableNestedTracking) {
        item.children.forEach(processItem);
      }
    };

    updatedItem.inventoryTrackingIds.forEach(processItem);
    return codes;
  };

  const handlePrintAllCodes = () => {
    const allCodes = getAllTrackableCodes();
    if (allCodes.length === 0) {
      appState.setAlert(
        "No codes available to print",
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
      return;
    }
    setPrintingCodes(allCodes);
  };

  return (
    <SlideOverPanel
      open={true}
      setOpen={closePanel}
      title="Inventory Tracking"
      containerStyle={"max-w-7xl relative"}>
      <div>
        <div className="flex w-full p-4">
          <div className="flex items-center">
            <img
              src={
                product && product.images?.length > 0
                  ? product.images[0].url
                  : FALLBACK_IMAGE_URL
              }
              alt={product.name}
              className="mr-4 h-16 w-16"
            />
            <div>
              <div className="w-48 truncate text-base font-medium">
                {product.name}
              </div>
              <div className="text-gray-500">SKU: {product.sku}</div>
              <div className="text-gray-500">
                Qty: {receivingItem.quantity} {receivingItem.formFactor}
              </div>
              {product.uomConfig &&
                uomConversationMatrix(
                  product.uomConfig,
                  receivingItem.formFactor,
                  receivingItem.quantity,
                ) !== null && (
                  <div className="font-medium text-primaryAccent">
                    UoM Conversion:{" "}
                    {uomConversationMatrix(
                      product.uomConfig,
                      receivingItem.formFactor,
                      receivingItem.quantity,
                    )}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-1 flex-col">
        <div className="overflow-auto p-4">
          <div className="mt-4 min-w-full">
            <div className="flex w-full items-center">
              <div className="flex text-lg font-medium">
                Total Codes: {updatedItem.inventoryTrackingIds.length}
              </div>
              <div className="flex flex-1 justify-end space-x-4">
                <div className="flex items-center space-x-4">
                  <div className="flex items-center space-x-2 border-l border-gray-200 pl-4">
                    <div className="relative inline-block select-none">
                      <div className="flex items-center justify-center space-x-2">
                        <div>Enable Nested Tracking</div>
                        <SwitchComponent
                          enabled={updatedItem.enableNestedTracking}
                          setEnabled={() => {
                            setUpdatedItem((prev) => ({
                              ...prev,
                              enableNestedTracking: !prev.enableNestedTracking,
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex text-lg font-medium">
                  <button
                    className="mt-2 rounded-md border border-primaryAccent p-2 text-lg font-medium"
                    onClick={generateCodesAutomatically}>
                    <div className="flex items-center text-primaryAccent underline">
                      <ClipboardListIcon className="mr-2 h-6 w-6" />
                      Generate Codes Automatically
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col text-lg">
              {renderTrackingLevelControls()}
            </div>
            <div
              style={{ gridTemplateColumns: "3fr 3fr 1fr" }}
              className="sticky top-0 z-10 grid h-14 grid-cols-6 items-center gap-4 rounded-md border border-borderGray bg-bgGray px-4 text-left text-sm font-medium tracking-wider text-gray-500">
              <div>Item</div>
              <div>Code</div>
              <div>Action</div>
            </div>
            <div className="min-w-full overflow-auto">
              <div className="mt-4 space-y-4">
                {updatedItem.inventoryTrackingIds.map((item) => (
                  <InventoryTrackingItem
                    key={item.id}
                    item={item}
                    uomConfig={product.uomConfig}
                    enableNestedTracking={updatedItem.enableNestedTracking}
                    trackingLevels={updatedItem.trackingLevels}
                    generateCodeForItem={generateCodeForItem}
                    onCodeChange={handleValidationAndFocusOnNextInputField}
                    clearCode={clearCode}
                    setPrintingCodes={setPrintingCodes}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="sticky bottom-0 z-30 flex w-full flex-none items-center justify-between border-t border-gray-200 bg-white p-4">
          <div className="flex">
            <button
              className="mt-2 border-0 border-transparent text-lg font-medium"
              onClick={handlePrintAllCodes}>
              <div className="flex items-center text-[#6E7173] underline">
                <PrinterIcon className="mr-2 h-6 w-6" />
                Print All Codes
              </div>
            </button>
          </div>
          <div className="flex gap-6">
            <PrimaryButton
              height="3rem"
              width="7rem"
              className="mt-2 text-lg font-medium"
              onClick={closePanel}>
              Cancel
            </PrimaryButton>
            <PrimaryButton
              height="3rem"
              minWidth="7rem"
              maxWidth="15rem"
              variant="primary"
              className="mt-2 text-lg font-medium"
              onClick={checkAndSubmitInventoryTracking}>
              Confirm
            </PrimaryButton>
          </div>
        </div>
      </div>
      {printingCodes && (
        <PrintOptions
          codes={printingCodes}
          setPrintingCodes={setPrintingCodes}
          bulkPrintCodes={bulkPrintCodes}
          item={updatedItem}
          enableNestedTracking={updatedItem.enableNestedTracking}
          printCode={printCode}
        />
      )}
    </SlideOverPanel>
  );
};

const getHumanReadableUomEquation = (
  conversionMatrix,
  formFactor,
  quantity = 1,
) => {
  const validEntries = Object.entries(conversionMatrix)
    .filter(([key, value]) => key !== formFactor && value !== -1)
    .sort((a, b) => a[1] - b[1]);

  if (validEntries.length === 0) return null;

  const equation = validEntries
    .map(([key, value]) => {
      const total = value * quantity;
      return `${total} ${key}`;
    })
    .join(" = ");

  return `${quantity} ${formFactor} = ${equation}`;
};

export const uomConversationMatrix = (uomConfig, formFactor, quantity = 1) => {
  const conversionMatrix = uomConfig.currentConversionMatrix[formFactor];
  if (!conversionMatrix) {
    return null;
  }

  return getHumanReadableUomEquation(conversionMatrix, formFactor, quantity);
};

export default InventoryTrackingPanel;

// <ul className="w-16 overflow-visible whitespace-nowrap [&_&]:border-l [&_&]:pl-5 [&_&]:pt-4">
// <li>
//   <p>A comment with no replies</p>
// </li>
// <li>
//   <p>A comment with one reply</p>
//   <ul className="w-16 overflow-visible whitespace-nowrap [&_&]:border-l [&_&]:pl-5 [&_&]:pt-4">
//     <li>
//       <p>First reply</p>
//     </li>
//     <li>
//       <p>A comment with one reply</p>
//       <ul className="w-16 overflow-visible whitespace-nowrap [&_&]:border-l [&_&]:pl-5 [&_&]:pt-4">
//         <li>
//           <p>First reply</p>
//         </li>
//         <li></li>
//       </ul>
//     </li>
//   </ul>
// </li>
// </ul>
