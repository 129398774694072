import React from "react";
import { render } from "react-dom";
import { createBrowserHistory } from "history";
import "regenerator-runtime/runtime.js";
import * as Sentry from "@sentry/react";
import App from "#pages/App";
import config from "config";
import { AuthProvider } from "#contexts/auth";
import { AppStateProvider } from "#contexts/appState";
import { MasterDataProvider } from "#contexts/masterData";
import { NotificationProvider } from "#contexts/notification";
// import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { gitSha } from "./gitSha";
import { Provider } from "react-redux";
import store from "./redux/store";
const robots = require("../public/robots.txt");
import { DropdownProvider } from "#contexts/dropdown";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { FeatureFlagsProvider } from "#contexts/featureFlags";
import { AppLoadingProvider } from "#newUiComponents/commons/AppLoadingProvider";

if (config.enableSentry) {
  Sentry.init({
    dsn: config.sentryDsn,
    ignoreErrors: [
      "Non-Error exception captured",
      "Non-Error promise rejection captured",
    ],
    release: gitSha,
    debug: !!config.sentryDebug,
    integrations: [
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: true,
        mutationBreadcrumbLimit: 1000,
        mutationLimit: 1500,
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: config.LAUNCH_DARKLY_CLIENT_ID,
    options: {
      bootstrap: "localStorage",
      streaming: true,
    },
  });

  render(
    <LDProvider>
      <AppLoadingProvider>
        <Provider store={store}>
          <AuthProvider>
            <AppStateProvider>
              <FeatureFlagsProvider>
                <MasterDataProvider>
                  <NotificationProvider>
                    <DropdownProvider>
                      <App history={history} />
                    </DropdownProvider>
                  </NotificationProvider>
                </MasterDataProvider>
              </FeatureFlagsProvider>
            </AppStateProvider>
          </AuthProvider>
        </Provider>
      </AppLoadingProvider>
    </LDProvider>,
    document.getElementById("root"),
  );
})();
