import React, { useState, useEffect, useMemo, useRef } from "react";
import { Tooltip } from "antd";
import CustomTable from "#newUiComponents/commons/CustomTable";
import { FALLBACK_IMAGE_URL } from "#constants/fallback-image-urls";
import dayjs from "dayjs";
import {
  TrashIcon,
  PencilIcon,
  CheckIcon,
  XIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import AutoCompleteSingleSelect from "#newUiComponents/commons/AutoCompleteSingleSelect";
import CustomMultiSelect from "#newUiComponents/commons/CustomMultiSelect";
import NewModal from "#newUiComponents/commons/NewModal";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";

const FbaProductTableReadOnly = ({
  inventoryData,
  isSearchable = true,
  fromPackingScreen = false,
  workingList,
  onDropItem = () => {},
  handleSetPrepDetailsInAmazon,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [tableData, setTableData] = useState([]);
  const [editingRowId, setEditingRowId] = useState(null);
  const [editedRow, setEditedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const transformedData = useMemo(
    () =>
      inventoryData.map((item) => ({
        id: item.id,
        productId: item.productId,
        images: item?.images,
        productName: item?.name || `SKU: ${item.sku} ASIN: ${item.asin}`,
        expiryDate:
          item.expiryDate || item.bestByDate
            ? dayjs(item.expiryDate || item.bestByDate).isValid()
              ? dayjs(item.expiryDate || item.bestByDate).format("YYYY-MM-DD")
              : "-"
            : "-",
        unitsAvailable: item.availableToShip || "-",
        unitsEligibleToShip: item.quantity || item.availableQuantity || "-",
        fnSku: item.fnSku || item.fnsku || "",
        sku: item.sku || "",
        sellerSku: item.SellerSKU || "-",
        asin: item.asin || "",
        name: item.name || "",
        upc: item.upc || "",
        lpn: item.lpn || "",
        prepCategory: item.prepDetails?.prepCategory || "",
        prepTypes: item.prepDetails?.prepTypes || [],
        prepCategoryDescription: item.prepCategoryDescription || {},
        prepTypeAndDescription: item.prepTypeAndDescription || {},
      })),
    [inventoryData],
  );

  useEffect(() => {
    setTableData(transformedData);
  }, [transformedData]);

  const preparePrepCategoryOptions = (item) => {
    return Object.entries(item.prepCategoryDescription || {}).map(([key]) => ({
      id: key,
      label: key,
      value: key,
      description: item.prepCategoryDescription[key],
    }));
  };

  const preparePrepTypesOptions = (item) => {
    return Object.entries(item.prepTypeAndDescription || {}).map(
      ([key, value]) => ({
        id: key,
        name: key,
        description: value,
      }),
    );
  };

  const handleSavePrep = (row, prepCategory, prepTypes) => {
    const updatedRow = {
      ...row,
      prepCategory,
      prepTypes: prepTypes || [],
    };

    // const updatedData = tableData.map((item) =>
    //   item.id === row.id ? updatedRow : item,
    // );
    // setTableData(updatedData);
    // console.log("updatedRow", updatedRow);
    handleSetPrepDetailsInAmazon(updatedRow);
    setEditingRowId(null);
    setEditedRow(null);
  };

  const handleTrashClick = (item) => {
    const affectedItems = workingList.filter((wItem) => wItem.sku === item.sku);
    const groupedByBinLocation = affectedItems.reduce((acc, item) => {
      const key = item.binLocation;
      if (!acc[key]) {
        acc[key] = {
          binLocation: item.binLocation,
          productName: item.productName,
          sku: item.sku,
          quantity: 0,
          items: [],
        };
      }
      acc[key].quantity += 1;
      acc[key].items.push(item);
      return acc;
    }, {});

    setSelectedItem([...Object.values(groupedByBinLocation)]);
    setIsModalOpen(true);
  };

  const columns = [
    {
      key: "productName",
      title: "Product Name",
      render: (value, row) => (
        <div className="flex items-center">
          <img
            src={
              row.images
                ? row?.images[0]?.display_url ||
                  row?.images[0]?.url ||
                  row?.images[0]
                : FALLBACK_IMAGE_URL
            }
            alt="Product"
            className="mr-3 h-12 w-12 object-cover"
          />
          <div>
            <Tooltip
              title={value}
              placement="topLeft"
              trigger={["hover"]}
              mouseEnterDelay={0.5}
              overlayClassName="max-w-lg">
              <p
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  wordWrap: "break-word",
                  width: "300px",
                }}
                className="max-w-full truncate text-base font-medium">
                {value}
              </p>
            </Tooltip>
            <p className="text-sm text-gray-500">{`ASIN: ${row.asin}`}</p>
            <p className="text-sm text-gray-500">{`FNSKU: ${row.fnSku}`}</p>
            <p className="text-sm text-gray-500">{`SKU: ${row.sku}`}</p>
          </div>
        </div>
      ),
    },
    {
      key: "expiryDate",
      title: "Expiry Date",
      render: (value) => <span className="text-sm text-gray-700">{value}</span>,
    },
    fromPackingScreen
      ? {
          key: "prepDetails",
          title: "Prep Details",
          render: (_, row) =>
            editingRowId === row.id ? (
              <div className="flex gap-3">
                <div className="flex flex-col gap-4">
                  <AutoCompleteSingleSelect
                    labelText={`Prep Category`}
                    options={preparePrepCategoryOptions(row)}
                    value={editedRow?.prepCategory}
                    onChange={(newValue) =>
                      newValue &&
                      newValue !== "UNKNOWN" &&
                      setEditedRow((prev) => ({
                        ...prev,
                        prepCategory: newValue,
                      }))
                    }
                    isOptionDisabled={(option) => option.value === "UNKNOWN"}
                    required={true}
                    labelKey="label"
                    valueKey="value"
                    placeholder="Select Category"
                    labelClasses="text-base text-gray-500"
                    CustomOptionRender={({ option }) => (
                      <div
                        className={`flex items-center gap-2 ${option.value === "UNKNOWN" ? "cursor-not-allowed opacity-50" : ""}`}>
                        <span>{option.label}</span>
                        <Tooltip title={option.description}>
                          <InformationCircleIcon className="h-5 w-5 cursor-pointer text-gray-500 hover:text-gray-700" />
                        </Tooltip>
                      </div>
                    )}
                  />
                  <div>
                    <div className="mb-2 flex items-center">
                      <div className="text-base text-gray-500">Prep Types</div>
                    </div>
                    <CustomMultiSelect
                      options={preparePrepTypesOptions(row)}
                      selectedValues={editedRow?.prepTypes}
                      onChange={(newValues) => {
                        if (newValues.length >= 1) {
                          setEditedRow((prev) => ({
                            ...prev,
                            prepTypes: newValues.map((val) => val.id),
                          }));
                        }
                      }}
                      required={true}
                      CustomOptionRender={({ option }) => (
                        <div className="flex items-center gap-2">
                          <span>{option.name}</span>
                          <Tooltip title={option.description}>
                            <InformationCircleIcon className="h-5 w-5 cursor-pointer text-gray-500 hover:text-gray-700" />
                          </Tooltip>
                        </div>
                      )}
                      placeholder="Select Types"
                    />
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <Tooltip title="Save Changes">
                    <CheckIcon
                      className="h-5 w-5 cursor-pointer font-semibold text-primaryAccent hover:text-primaryAccent"
                      onClick={() =>
                        handleSavePrep(
                          row,
                          editedRow.prepCategory,
                          editedRow.prepTypes,
                        )
                      }
                    />
                  </Tooltip>
                  <Tooltip title="Cancel">
                    <XIcon
                      className="h-5 w-5 cursor-pointer font-semibold text-red-500 hover:text-red-700"
                      onClick={() => setEditingRowId(null)}
                    />
                  </Tooltip>
                </div>
              </div>
            ) : (
              <div className="flex items-center gap-4">
                <div className="w-64">
                  <p>
                    <span className="text-base text-gray-500">
                      Prep Category:
                    </span>{" "}
                    {row.prepCategory || "-"}
                  </p>
                  <Tooltip title={row.prepTypes.join(", ") || "-"}>
                    <p className="w-full truncate">
                      <span className="text-base text-gray-500">
                        Prep Types:
                      </span>{" "}
                      {row.prepTypes.join(", ") || "-"}
                    </p>
                  </Tooltip>
                </div>
                <Tooltip title="Edit prep details">
                  <PencilIcon
                    className="h-5 w-5 cursor-pointer font-semibold text-primaryAccent hover:text-primaryAccent"
                    onClick={() => {
                      setEditingRowId(row.id);
                      setEditedRow(row);
                    }}
                  />
                </Tooltip>
              </div>
            ),
        }
      : null,
    fromPackingScreen
      ? {
          key: "actions",
          title: "Actions",
          render: (_, row) => (
            <Tooltip title="Drop Item">
              <TrashIcon
                className="h-5 w-5 cursor-pointer text-red-500 hover:text-red-700"
                onClick={() => handleTrashClick(row)}
              />
            </Tooltip>
          ),
        }
      : null,
  ].filter(Boolean);

  const modalTableColumns = [
    {
      key: "productName",
      title: "Product Name",
      render: (_, row) => (
        <div className="max-w-[200px]">
          <Tooltip
            title={row.productName}
            placement="topLeft"
            mouseEnterDelay={0.3}
            overlayClassName="max-w-lg">
            <p className="w-36 truncate text-sm">{row.productName}</p>
          </Tooltip>
        </div>
      ),
    },
    {
      key: "sku",
      title: "SKU",
      render: (_, row) => row.sku,
    },
    {
      key: "quantity",
      title: "Quantity",
      render: (_, row) => row?.quantity,
    },
    {
      key: "binLocation",
      title: "Bin Location",
      render: (_, row) => row?.binLocation,
    },
  ];

  const filteredData = useMemo(() => {
    if (!searchTerm || searchTerm.trim() === "") {
      return tableData;
    }

    const searchTermLower = searchTerm.toLowerCase().trim();

    return tableData.filter(
      (item) =>
        item.asin?.toLowerCase().includes(searchTermLower) ||
        item.sku?.toLowerCase().includes(searchTermLower) ||
        item.fnSku?.toLowerCase().includes(searchTermLower) ||
        item.name?.toLowerCase().includes(searchTermLower) ||
        item.upc?.includes(searchTermLower) ||
        item.lpn?.includes(searchTermLower),
    );
  }, [tableData, searchTerm]);

  const hasUnknownPrepCategory = useMemo(() => {
    return inventoryData.some(
      (item) => item.prepDetails?.prepCategory === "UNKNOWN",
    );
  }, [inventoryData]);

  const handleBulkUpdateUnknownPrep = () => {
    const itemsWithUnknownPrep = inventoryData.filter(
      (item) => item.prepDetails?.prepCategory === "UNKNOWN",
    );

    const updatePromises = itemsWithUnknownPrep.map((item) =>
      handleSetPrepDetailsInAmazon({
        ...item,
        prepCategory: "NONE",
        prepTypes: ["ITEM_NO_PREP"],
      }),
    );

    Promise.all(updatePromises);
  };

  return (
    <div className="flex h-full flex-col justify-between font-inter">
      {hasUnknownPrepCategory && (
        <div className="mb-4 flex justify-end">
          <PrimaryButton
            onClick={handleBulkUpdateUnknownPrep}
            className="flex items-center gap-2"
            height="2.5rem">
            Update All Unknown Prep to No Prep
          </PrimaryButton>
        </div>
      )}
      <CustomTable
        columns={columns}
        data={filteredData}
        isSearchable={isSearchable}
        isFilters={false}
        isPagination={false}
        isInfiniteScroll={false}
        pageSizeOptions={["5", "10", "20"]}
        onChangeSearchTerm={setSearchTerm}
        totalDataCountLable="Total Products: "
        searchPlaceholder={"Search by ASIN/SKU/FNSKU/Name"}
        totalDataCount={tableData.length}
      />

      <NewModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Are you sure you want to drop this item?"
        maxWidth="740px">
        <div className="font-inter">
          <p className="mb-4 text-lg font-medium">Items Affected</p>

          <CustomTable
            columns={modalTableColumns}
            data={selectedItem ? selectedItem : []}
            isSearchable={false}
            isFilters={false}
            isPagination={false}
          />

          <p className="mt-6 text-sm text-gray-600">
            All allocated stock for this order will be transferred back to
            inventory to the original storage locations from which they were
            allocated. Please ensure it is putaway into the respective storage
            locations.
          </p>

          <div className="mt-6 flex justify-end gap-4">
            <PrimaryButton
              height="3rem"
              minWidth="7rem"
              maxWidth="15rem"
              variant="secondary"
              className="text-lg font-medium"
              onClick={() => setIsModalOpen(false)}>
              Cancel
            </PrimaryButton>
            <PrimaryButton
              height="3rem"
              minWidth="7rem"
              maxWidth="15rem"
              variant="primary"
              className="text-lg font-medium"
              danger
              onClick={() => {
                if (onDropItem && selectedItem) {
                  onDropItem(
                    selectedItem.map((item) => item?.items)?.flat(),
                    "",
                  );
                }
                setIsModalOpen(false);
              }}>
              Confirm
            </PrimaryButton>
          </div>
        </div>
      </NewModal>
    </div>
  );
};

export default FbaProductTableReadOnly;
