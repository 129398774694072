import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_CONSOLIDATED_OUTBOUND_BATCHES,
  GET_USERS,
  FETCH_SPECIFIC_BATCH,
} from "#queries";
import {
  PRIORITIZE_BATCH,
  ASSIGN_BATCH_PICKER,
  DELETE_BATCH,
} from "#mutations";
import _ from "lodash";
import moment from "moment-timezone";
import { AppStateContext } from "#contexts/appState";
import { EntityContext } from "#contexts/entity";
import { AuthContext } from "#contexts/auth";
import { useCubeQuery } from "@cubejs-client/react";
import { downloadPickListPdf } from "#components/pickings/PickListPdf";
import Papa from "papaparse";
import {
  FETCH_SHIPPING_LABEL_GENERATION_INFO,
  FETCH_SPECIFIC_ORDER,
} from "#queries/index";
import {
  GENERATE_SHIPPING_LABELS,
  MANUALLY_MARK_ORDERS,
  VOID_SHIPPING_LABELS,
} from "#mutations/index";
const ALERT_TIMEOUT_IN_MS = 5000;

const pickingBatchEnums = {
  VIEW_LABEL_GENERATION_STATUS: "view_label_generation_status",
  MARK_ORDERS_PACKING_COMPLETE: "mark_orders_packing_complete",
};

const shippingLabelEnums = {
  ALL_ORDERS: "allOrders",
  ORDERS_WITH_SHIPPING_LABEL: "ordersWithShippingLabel",
  ORDERS_WITHOUT_SHIPPING_LABEL: "ordersWithoutShippingLabel",
};

const shippingLabelFilterValues = [
  {
    key: shippingLabelEnums.ALL_ORDERS,
    label: "All Orders",
  },
  {
    key: shippingLabelEnums.ORDERS_WITH_SHIPPING_LABEL,
    label: "Orders having Shipping Label",
  },
  {
    key: shippingLabelEnums.ORDERS_WITHOUT_SHIPPING_LABEL,
    label: "Orders not having Shipping Label",
  },
];

const withOutboundSummaryLogic = (WrappedComponent) => {
  return (props) => {
    const [expandedBatch, setExpandedBatch] = useState(null);
    const entity = useContext(EntityContext);
    const appState = useContext(AppStateContext);
    const fetchSpecificBatch = useQuery(FETCH_SPECIFIC_BATCH);
    const fetchBatchForPickListPDF = useQuery(FETCH_SPECIFIC_BATCH);
    const batchesQuery = useQuery(GET_CONSOLIDATED_OUTBOUND_BATCHES);
    const usersQuery = useQuery(GET_USERS);

    const [selectedBatch, setSelectedBatch] = useState(null);
    const [showPickerSelector, setShowPickerSelector] = useState(false);

    const [showFilters, setShowFilters] = useState(false);
    const [batchItems, setBatchItems] = useState(false);

    const [showView, setShowView] = useState("BATCHES");
    const [selectedPicker, setSelectedPicker] = useState(null);

    const auth = useContext(AuthContext);
    const [users, setUsers] = useState([]);
    const [usersMap, setUsersMap] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);
    const [allRowsSelected, setAllRowsSelected] = useState(false);
    const [showOnlySelected, setShowOnlySelected] = useState(false);
    const [labelGenerationData, setLabelGenerationData] = useState({});
    const [labelGenerationFailure, setLabelGenerationFailure] = useState("");
    const [voidLabelConfirmation, setVoidLabelConfirmation] = useState({});
    const [labelGenerationConfirmation, setLabelGenerationConfirmation] =
      useState({});
    const [shipmentDetails, setShipmentDetails] = useState([]);
    const [pickingBatchAction, setPickingBatchAction] = useState("");
    const [allOrdersSelected, setAllOrdersSelected] = useState(false);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [shippingLabelFilter, setShippingLabelFilter] = useState(
      shippingLabelFilterValues[0],
    );
    const [packingCompleteOrders, setPackingCompleteOrders] = useState([]);
    const [showPackingCompleteModal, setShowPackingCompleteModal] =
      useState(false);

    const prioritizeBatchQuery = useQuery(PRIORITIZE_BATCH);
    const assignBatchPickerQuery = useQuery(ASSIGN_BATCH_PICKER);
    const deleteBatchQuery = useQuery(DELETE_BATCH);
    const startLabelGenerationQuery = useQuery(GENERATE_SHIPPING_LABELS);
    const fetchShippingLabelGenerationInfo = useQuery(
      FETCH_SHIPPING_LABEL_GENERATION_INFO,
    );
    const voidShippingLabelsQuery = useQuery(VOID_SHIPPING_LABELS);
    const fetchOrderForLabelGeneration = useQuery(FETCH_SPECIFIC_ORDER);
    const fetchSpecificOrder = useQuery(FETCH_SPECIFIC_ORDER);
    const manuallyMarkOrdersQuery = useQuery(MANUALLY_MARK_ORDERS);

    useEffect(() => {
      usersQuery.fetchData({
        perPage: 1000,
        paginated: false,
      });
    }, []);

    useEffect(() => {
      if (usersQuery.data?.users?.entities) {
        let usersData = usersQuery.data.users.entities;
        let usersDataMap = {};
        usersData.forEach((user) => {
          usersDataMap[`${user.id}`] = user;
        });
        setUsers(usersData);
        setUsersMap(usersDataMap);
      }
    }, [usersQuery.data]);

    useEffect(() => {
      if (fetchSpecificBatch.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (fetchSpecificBatch.data) {
        setExpandedBatch(fetchSpecificBatch.data.batch);
      }

      if (fetchSpecificBatch.error) {
        setExpandedBatch(null);
      }
    }, [
      fetchSpecificBatch.loading,
      fetchSpecificBatch.error,
      fetchSpecificBatch.data,
    ]);

    useEffect(() => {
      loadFirstTimeData();

      return () => {
        entity.setFilters({});
        entity.resetEntities();
      };
    }, []);

    const loadFirstTimeData = () => {
      const setFilters = {
        typeOfBatch: "PICKING",
        startEndDate: [
          moment().format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
      };
      entity.setFilters(setFilters);

      batchesQuery.fetchData({
        perPage: entity.perPage,
        filters: setFilters,
        paginated: false,
        pageNumber: 1,
        sort: entity.sort,
      });
    };

    useEffect(() => {
      if (batchesQuery.loading) appState.setLoading();
      else appState.removeLoading();
      if (
        batchesQuery.data &&
        batchesQuery.data.getConsolidatedOutboundBatches
      ) {
        console.log(batchesQuery.data.getConsolidatedOutboundBatches);
        entity.setEntities({
          ...batchesQuery.data.getConsolidatedOutboundBatches,
          ...batchesQuery.variables,
        });
      }
    }, [batchesQuery.loading, batchesQuery.error, batchesQuery.data]);

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          paginated: true,
          sort: entity.sort,
        };
        return batchesQuery.fetchData(vars);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    const selectAllRows = () => {
      if (allRowsSelected === true) {
        setSelectedRows([]);
        setAllRowsSelected(false);
      } else {
        setAllRowsSelected(true);
        setSelectedRows(entity.entities.map((item) => item.id));
      }
    };

    /** Action to select a row in the table and add it to selectedRows */
    const selectRow = (index) => {
      const selectedRowsCopy = [...selectedRows];
      if (selectedRowsCopy.includes(entity.entities[index].id)) {
        selectedRowsCopy.splice(
          selectedRowsCopy.indexOf(entity.entities[index].id),
          1,
        );
      } else {
        selectedRowsCopy.push(entity.entities[index].id);
      }
      setSelectedRows(selectedRowsCopy);
    };

    const displayBatches = () => {
      if (showOnlySelected === true) {
        return entity.entities.filter((item) => selectedRows.includes(item.id));
      } else {
        return entity.displayEntities;
      }
    };

    /** utility function to download csv file */
    const downloadCsv = (csvString, fileName) => {
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    /** generate csv string for batces based on selected rows in the table */
    const generateBatchesCsvString = () => {
      let csvHeaders = [
        "Batch ID",
        "Order ID",
        "SKU",
        "Item Name",
        "Storage location",
        "Required Quantity",
        "Picked Quantity",
        "Picker",
        "Assigned Time(batch)",
        "First Pick(batch)",
        "Last Pick(batch)",
        "Batch Status",
        "Batch Classification",
        "Rows",
      ];
      let csvData = [csvHeaders];

      selectedRows.forEach((batchId) => {
        const batch = entity.entities.find((item) => item.id === batchId);
        const firstCompletedTime = batch?.attributes?.firstCompletedTime;
        const firstPickTime = firstCompletedTime
          ? moment(firstCompletedTime).format("MMM Do YYYY, h:mm a")
          : null;

        const lastCompletedTime = batch?.attributes?.lastCompletedTime;
        const lastPickTime = lastCompletedTime
          ? moment(lastCompletedTime).format("MMM Do YYYY, h:mm a")
          : null;

        const assignedTime = batch?.assignedTime
          ? moment(batch.assignedTime).format("MMM Do YYYY, h:mm a")
          : null;

        batch.items.forEach((item) => {
          csvData.push([
            batch.id,
            batch?.attributes?.humanReadableOrderMapping?.[item.order],
            item.sku,
            item.productName,
            item.binLocation,
            item.quantity,
            item.availableQuantity,
            usersMap[batch.user]?.name,
            assignedTime,
            firstPickTime,
            lastPickTime,
            batch.status,
            batch.classification,
            batch.rows,
          ]);
        });
      });
      return Papa.unparse(csvData);
    };

    /** Action to download batches csv file based on selected rows in the table */
    const downloadBatchesCsv = () => {
      const csvString = generateBatchesCsvString();
      const fileName = `${new Date()
        .toDateString()
        .replace(/\s+/g, "-")}-Picking-Batch-List.csv`;
      downloadCsv(csvString, fileName);
    };

    useEffect(() => {
      if (assignBatchPickerQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (assignBatchPickerQuery.data) {
        appState.setAlert(
          assignBatchPickerQuery.data.updateBatchUser?.message ||
            "User is assigned to given batch",
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (assignBatchPickerQuery.error) {
        appState.setAlert(
          assignBatchPickerQuery.error?.message || "Error in assigning picker",
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
    }, [
      assignBatchPickerQuery.loading,
      assignBatchPickerQuery.error,
      assignBatchPickerQuery.data,
    ]);

    useEffect(() => {
      if (prioritizeBatchQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (prioritizeBatchQuery.data) {
        appState.setAlert(
          prioritizeBatchQuery.data.updateBatchPriority?.message ||
            "Successfully prioritized batch",
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (prioritizeBatchQuery.error) {
        appState.setAlert(
          prioritizeBatchQuery.error?.message || "Error in prioritizing batch",
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
    }, [
      prioritizeBatchQuery.loading,
      prioritizeBatchQuery.error,
      prioritizeBatchQuery.data,
    ]);

    useEffect(() => {
      if (deleteBatchQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (deleteBatchQuery.data) {
        appState.setAlert(
          deleteBatchQuery.data.deleteBatch.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        batchesQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: entity.pageNumber,
          sort: entity.sort,
        });
      }

      if (deleteBatchQuery.error) {
        appState.setAlert(
          deleteBatchQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
    }, [
      deleteBatchQuery.loading,
      deleteBatchQuery.error,
      deleteBatchQuery.data,
    ]);

    useEffect(() => {
      if (fetchShippingLabelGenerationInfo.data) {
        const shipmentLabels =
          fetchShippingLabelGenerationInfo.data
            ?.fetchShippingLabelGenerationInfo?.shipmentLabels;

        shipmentLabels.forEach(async (label) => {
          const shipmentDetailsResponse =
            await fetchOrderForLabelGeneration.fetchData({
              orderId: label.order,
            });

          if (shipmentDetailsResponse.data?.order) {
            setShipmentDetails((prev) => [
              ...prev,
              {
                order: label.order,
                ...shipmentDetailsResponse.data?.order,
                ...shipmentDetailsResponse.data?.order?.shipmentDetails,
              },
            ]);
          }
        });
      }
    }, [fetchShippingLabelGenerationInfo.data]);

    const onAssignBatchPicker = () => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want assign a different Picker?",
        () => {
          assignBatchPickerQuery
            .fetchData({
              batchId: selectedBatch.id,
              userId: selectedBatch.user,
            })
            .then(() => {
              const vars = {
                perPage: entity.perPage,
                pageNumber: entity.pageNumber,
                filters: entity.filters,
                paginated: true,
                sort: entity.sort,
              };
              batchesQuery.fetchData(vars).then((r) => {
                setShowPickerSelector(false);
                appState.hideConfirmation();
              });
            });
        },
        appState.hideConfirmation,
      );
    };

    const onPrioritizeBatch = (batch) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want prioritize this batch?",
        () => {
          prioritizeBatchQuery.fetchData({ batchId: batch.id });
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
        // "The batch was prioritized",
        // "Batch prioritizing failed",
      );
    };

    const onDeleteBatch = (batch) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want delete this batch and unallocate all orders from it?",
        () => {
          deleteBatchQuery.fetchData({ batchId: batch.id });
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
        // "The batch was prioritized",
        // "Batch prioritizing failed",
      );
    };

    const handleDateRangeClear = () => {
      const setFilters = {
        typeOfBatch: "PICKING",
        startEndDate: [
          moment().subtract(1, "weeks").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
      };
      entity.setFilters(setFilters);
    };

    const onClickDownloadPickList = async (batchId) => {
      const pickingBatch = (
        await fetchBatchForPickListPDF.fetchData({
          batchId,
        })
      ).data.batch;
      await downloadPickListPdf(
        pickingBatch,
        auth.user.warehousesList,
        auth.user.customersList,
        usersMap,
      );
    };

    const handleStartLabelGeneration = async (batch, orders = []) => {
      const payload = {
        pickingBatchId: batch.id,
      };

      if (orders?.length) {
        payload.orders = orders;
      }

      appState.setLoading();

      const startLabelGenerationResponse =
        await startLabelGenerationQuery.fetchData(payload);

      if (
        startLabelGenerationResponse.data?.generateShippingLabels?.message ===
        "SUCCESS"
      ) {
        appState.setAlert(
          `Label generation started for ${batch.id}`,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (startLabelGenerationResponse.error) {
        appState.setAlert(
          startLabelGenerationResponse.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      appState.removeLoading();

      setLabelGenerationConfirmation({});
    };

    const handleViewLabelStatus = async (pickingBatchId) => {
      if (!pickingBatchId) {
        appState.setAlert(
          "Picking batch not found",
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        return;
      }

      const labelGenerationResponse =
        await fetchShippingLabelGenerationInfo.fetchData({
          pickingBatchId,
        });

      setLabelGenerationData(
        labelGenerationResponse?.data?.fetchShippingLabelGenerationInfo || {},
      );
    };

    const handleClickVoidLabels = async (order) => {
      if (!order?.order) {
        appState.setAlert("Order not found", "error", ALERT_TIMEOUT_IN_MS);
        return;
      }

      const voidLabelResponse = await voidShippingLabelsQuery.fetchData({
        orders: [order.order],
      });

      if (voidLabelResponse.data?.voidShippingLabels?.message === "SUCCESS") {
        appState.setAlert(
          `${order.orderId} voided successfully`,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (voidLabelResponse.error) {
        appState.setAlert(
          voidLabelResponse.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      setVoidLabelConfirmation({});
    };

    const selectAllOrders = () => {
      if (allOrdersSelected === true) {
        setSelectedOrders([]);
        setAllOrdersSelected(false);
      } else {
        setAllOrdersSelected(true);
        setSelectedOrders(packingCompleteOrders.map((order) => order.id));
      }
    };

    const selectOrder = (id) => {
      if (selectedOrders.includes(id)) {
        const ordersCopy = selectedOrders.filter((item) => item !== id);

        if (ordersCopy.length === 0) setAllOrdersSelected(false);
        setSelectedOrders(ordersCopy);
      } else {
        const ordersCopy = [...selectedOrders, id];

        if (ordersCopy.length === shipmentDetails.length)
          setAllOrdersSelected(true);
        setSelectedOrders(ordersCopy);
      }
    };

    const clearSelectedOrders = () => {
      setSelectedOrders([]);
      setAllOrdersSelected(false);
    };

    const bulkMarkCompleted = async (orderIds) => {
      try {
        const eligibleStatuses = [
          "UNPROCESSED",
          "BATCHED",
          "IN-PROCESS",
          "BATCHED_PARTIALLY",
          "PARTIAL_COMPLETED",
          "INCOMPLETE",
          "EXCEPTION",
        ];

        const orderStatuses = await Promise.all(
          orderIds.map(async (id) => {
            try {
              const response = await fetchSpecificOrder.fetchData({
                orderId: id,
              });

              return {
                orderId: id,
                status: response?.data?.order?.orderStatus,
              };
            } catch (error) {
              return { orderId: id, status: null };
            }
          }),
        );

        const allEligible = orderStatuses.every((order) =>
          eligibleStatuses.includes(order.status),
        );

        if (allEligible) {
          appState.showConfirmation(
            "Confirm",
            "This action marks the orders COMPLETE and cannot be reversed.",
            async () => {
              appState.setLoading();

              const markOrderResponse = await manuallyMarkOrdersQuery.fetchData(
                {
                  ids: orderIds,
                  status: "COMPLETED",
                  markCompleteOnMarketplace: true,
                },
              );

              if (markOrderResponse.data.manuallyMarkOrders.message) {
                appState.setAlert(
                  markOrderResponse.data.manuallyMarkOrders.message,
                  "success",
                  ALERT_TIMEOUT_IN_MS,
                );
                clearSelectedOrders();
                handleMarkPackingComplete(orderIds);
                appState.hideConfirmation();
              }

              appState.removeLoading();
            },
            appState.hideConfirmation,
          );
        } else {
          appState.setAlert(
            "Some orders are not eligible to be marked as Complete.",
            "error",
            ALERT_TIMEOUT_IN_MS,
          );
        }
      } catch (error) {
        console.error(
          "An error occurred while marking orders as completed:",
          error,
        );
      }
    };

    const handleMarkPackingComplete = (orderIds) => {
      if (!orderIds?.length) {
        appState.setAlert(
          "No orders found for current batch",
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        return;
      }

      orderIds.forEach(async (orderId) => {
        const fetchOrderResponse = await fetchOrderForLabelGeneration.fetchData(
          {
            orderId,
          },
        );

        if (fetchOrderResponse.data?.order) {
          const orderExists = packingCompleteOrders.find(
            (order) => order.id === fetchOrderResponse.data.order.id,
          );
          if (orderExists) {
            setPackingCompleteOrders((prev) =>
              prev.map((order) =>
                order.id === fetchOrderResponse.data?.order.id
                  ? fetchOrderResponse.data?.order
                  : order,
              ),
            );
          } else {
            setPackingCompleteOrders((prev) => [
              ...prev,
              {
                ...fetchOrderResponse.data?.order,
                ...fetchOrderResponse.data?.order?.shipmentDetails,
              },
            ]);
          }
        }
      });

      setShowPackingCompleteModal(true);
    };

    return (
      <WrappedComponent
        {...props}
        batchItems={batchItems}
        setBatchItems={setBatchItems}
        allData={batchesQuery.data ? batchesQuery.data.getAllBatches : {}}
        batches={entity.entities}
        displayBatches={displayBatches()}
        total={entity.total}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        perPage={entity.perPage}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          batchesQuery.fetchData({
            perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        submitFilters={() => {
          setShowFilters(false);
          batchesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          batchesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: null },
            sort: entity.sort,
          });
        }}
        filters={entity.filters}
        onChangeFilter={(field, value, autoSubmit = false) => {
          entity.setFilters({
            ...entity.filters,
            [field]: value,
          });
          if (autoSubmit) {
            batchesQuery.fetchData({
              perPage: entity.perPage,
              pageNumber: 1,
              filters: {
                ...entity.filters,
                [field]: value,
              },
              sort: entity.sort,
            });
          }
        }}
        handleDateRangeClear={handleDateRangeClear}
        onChangeSearchKeyword={(e) =>
          entity.setFilters({
            ...entity.filters,
            keyword: e.target.value,
          })
        }
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          batchesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort,
          });
        }}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        clearFilters={loadFirstTimeData}
        showView={showView}
        setShowView={setShowView}
        users={users}
        usersMap={usersMap}
        expandBatch={(batchId) => fetchSpecificBatch.fetchData({ batchId })}
        expandedBatch={expandedBatch}
        setExpandedBatch={setExpandedBatch}
        setSelectedPicker={setSelectedPicker}
        selectedPicker={selectedPicker}
        warehouses={auth?.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth?.user?.customersList ? auth.user.customersList : []}
        selectedBatch={selectedBatch}
        setSelectedBatch={setSelectedBatch}
        showPickerSelector={showPickerSelector}
        setShowPickerSelector={setShowPickerSelector}
        selectedRows={selectedRows}
        selectRow={selectRow}
        selectAllRows={selectAllRows}
        allRowsSelected={allRowsSelected}
        showOnlySelected={showOnlySelected}
        setShowOnlySelected={setShowOnlySelected}
        clearSelectedBatches={() => {
          setSelectedRows([]);
          setAllRowsSelected(false);
          setShowOnlySelected(false);
        }}
        downloadBatchesCsv={downloadBatchesCsv}
        prioritizeBatch={onPrioritizeBatch}
        deleteBatch={onDeleteBatch}
        onAssignBatchPicker={onAssignBatchPicker}
        onClickDownloadPickList={onClickDownloadPickList}
        labelGenerationData={labelGenerationData}
        setLabelGenerationData={setLabelGenerationData}
        handleViewLabelStatus={handleViewLabelStatus}
        handleStartLabelGeneration={handleStartLabelGeneration}
        handleClickVoidLabels={handleClickVoidLabels}
        labelGenerationFailure={labelGenerationFailure}
        setLabelGenerationFailure={setLabelGenerationFailure}
        voidLabelConfirmation={voidLabelConfirmation}
        setVoidLabelConfirmation={setVoidLabelConfirmation}
        labelGenerationConfirmation={labelGenerationConfirmation}
        setLabelGenerationConfirmation={setLabelGenerationConfirmation}
        shipmentDetails={shipmentDetails}
        pickingBatchEnums={pickingBatchEnums}
        pickingBatchAction={pickingBatchAction}
        setPickingBatchAction={setPickingBatchAction}
        allOrdersSelected={allOrdersSelected}
        setAllOrdersSelected={setAllOrdersSelected}
        selectAllOrders={selectAllOrders}
        selectedOrders={selectedOrders}
        setSelectedOrders={setSelectedOrders}
        selectOrder={selectOrder}
        bulkMarkCompleted={bulkMarkCompleted}
        shippingLabelFilter={shippingLabelFilter}
        setShippingLabelFilter={setShippingLabelFilter}
        shippingLabelFilterValues={shippingLabelFilterValues}
        shippingLabelEnums={shippingLabelEnums}
        handleMarkPackingComplete={handleMarkPackingComplete}
        packingCompleteOrders={packingCompleteOrders}
        setPackingCompleteOrders={setPackingCompleteOrders}
        showPackingCompleteModal={showPackingCompleteModal}
        setShowPackingCompleteModal={setShowPackingCompleteModal}
      />
    );
  };
};

export default withOutboundSummaryLogic;
