import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import { GET_DELMAR_PARCEL_STATUSES } from "#queries";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { EntityContext } from "#contexts/entity";
import { AuthContext } from "#contexts/auth";

import moment from "moment-timezone";

const withDelmarShippingsLogic = (WrappedComponent) => {
  return (props) => {
    const [expandedBatch, setExpandedBatch] = useState(null);
    const entity = useContext(EntityContext);
    const appState = useContext(AppStateContext);
    const delmarShippingsQuery = useQuery(GET_DELMAR_PARCEL_STATUSES);
    const [showFilters, setShowFilters] = useState(false);
    const auth = useContext(AuthContext);

    useEffect(() => {
      loadFirstTimeData();
      return () => {
        entity.setFilters({});
        entity.resetEntities();
      };
    }, []);

    const loadFirstTimeData = () => {
      const setFilters = {
        startEndDate: [
          moment().format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
      };
      entity.setFilters(setFilters);

      delmarShippingsQuery.fetchData({
        perPage: entity.perPage,
        filters: setFilters,
        paginated: false,
        pageNumber: 1,
        sort: entity.sort,
      });
    };

    const handleDateRangeClear = () => {
      const setFilters = {
        startEndDate: [
          moment().utc().startOf("day").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
      };
      entity.setFilters(setFilters);
    };
    useEffect(() => {
      if (delmarShippingsQuery.loading) appState.setLoading();
      else appState.removeLoading();

      if (
        delmarShippingsQuery.data &&
        delmarShippingsQuery.data.delmarParcelStatuses
      ) {
        entity.setEntities({
          ...delmarShippingsQuery.data.delmarParcelStatuses,
          ...delmarShippingsQuery.variables,
        });
      }
    }, [
      delmarShippingsQuery.loading,
      delmarShippingsQuery.error,
      delmarShippingsQuery.data,
    ]);

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          paginated: true,
          sort: entity.sort,
        };
        return delmarShippingsQuery.fetchData(vars);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    return (
      <WrappedComponent
        entities={entity.entities}
        displayEntities={entity.displayEntities}
        total={entity.total}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        perPage={entity.perPage}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          delmarShippingsQuery.fetchData({
            perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        submitFilters={() => {
          setShowFilters(false);
          delmarShippingsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          delmarShippingsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: null },
            sort: entity.sort,
          });
        }}
        filters={entity.filters}
        handleDateRangeClear={handleDateRangeClear}
        onChangeFilter={(field, value, autoSubmit = false) => {
          entity.setFilters({
            ...entity.filters,
            [field]: value,
          });
          if (autoSubmit) {
            delmarShippingsQuery.fetchData({
              perPage: entity.perPage,
              pageNumber: 1,
              filters: {
                ...entity.filters,
                [field]: value,
              },
              sort: entity.sort,
            });
          }
        }}
        onChangeSearchKeyword={(e) =>
          entity.setFilters({
            ...entity.filters,
            keyword: e.target.value,
          })
        }
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          delmarShippingsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort,
          });
        }}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        clearFilters={loadFirstTimeData}
        warehouses={auth?.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth?.user?.customersList ? auth.user.customersList : []}
      />
    );
  };
};

export default withDelmarShippingsLogic;
