import React, { useContext } from "react";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import ProductPrices from "#components/products/ProductPrices";

const CatalogProductPrices = ({
  selectedProduct,
  setSelectedProduct,
  salesPrice,
  setSalesPrice,
  customerList,
  onChange,
  validateCostPriseSalesPrice,
}) => {
  const appState = useContext(AppStateContext);

  return (
    <div className="relative">
      <div className="h-[calc(100%-96px)]">
        <ProductPrices
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          onChange={onChange}
          // salesPrice={salesPrice}
          // setSalesPrice={setSalesPrice}
          isRenderingFromCatalogEdit={true}
          customerList={customerList}
        />
      </div>
      <div
        style={{ display: "flex", justifyContent: "flex-end" }}
        className="border-grey-300 sticky bottom-0 z-10 -mx-6 h-24 gap-4 border-t bg-white">
        <button
          onClick={() => {
            setSelectedProduct(null);
          }}
          className="mb-4 mt-6 flex h-14 w-32 cursor-pointer items-center justify-center rounded-md border-[1px] border-primaryAccent bg-white p-2 font-sans text-lg font-medium text-primaryAccent hover:border-[#4096ff] hover:text-[#4096ff]">
          Cancel
        </button>
        <button
          type="primary"
          onClick={validateCostPriseSalesPrice}
          className="mb-4 mr-6 mt-6 flex h-14 w-32 cursor-pointer items-center justify-center rounded-md border bg-primaryAccent p-2 font-sans text-lg font-medium text-white hover:bg-[#4096ff] hover:text-white">
          Save
        </button>
      </div>
    </div>
  );
};

export default CatalogProductPrices;
