import React from "react";

const ErrorNotification = ({ errors, isErrorFromCatalog = false }) => {
  const commonError = (
    <p className={`${isErrorFromCatalog ? `mt-2` : ""}`}>
      You can make the changes and try to upload again!
    </p>
  );
  return (
    <div className="flex justify-start space-x-2 rounded-md border border-red-400 bg-red-100 px-4 py-3 text-red-700">
      <div className="h-auto w-7 flex-none">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="h-6 w-6 text-red-500">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
          />
        </svg>
      </div>
      <div className="h-auto grow">
        <div className="flex flex-col">
          <p className="font-semibold">Errors</p>
          {!isErrorFromCatalog && commonError}
        </div>
        <ul className="mt-2 list-outside list-disc space-y-2 pl-5">
          {errors.map((error, index) => (
            <li key={index}>
              <span dangerouslySetInnerHTML={{ __html: error?.message }} />
            </li>
          ))}
        </ul>
        {isErrorFromCatalog && commonError}
      </div>
    </div>
  );
};
export default ErrorNotification;
