import _ from "lodash";
import JSPM from "jsprintmanager";
import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

export const printBol = async (
  id,
  noOfCopies = 1,
  printer = null,
  download = false,
  appState = null,
) => {
  if (!id) {
    return appState && appState.setAlert("Could not print", "error", 5000);
  }

  try {
    htmlToImage
      .toPng(document.getElementById(id), { quality: 1 })
      .then((dataUrl) => {
        const pdf = new jsPDF({
          orientation: "portrait",
          format: "a4",
        });

        // Get PDF page dimensions
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        // Create temporary image to get dimensions
        const img = new Image();
        img.src = dataUrl;

        // Once image is loaded, handle the PDF creation
        img.onload = () => {
          // Calculate the scaled dimensions to fit width
          const imgAspectRatio = img.height / img.width;
          const scaledWidth = pageWidth;
          const scaledHeight = pageWidth * imgAspectRatio;

          // Calculate dimensions
          const padding = 15;
          const availableHeight = pageHeight - 2 * padding;

          // Check if the image needs to be split
          if (scaledHeight <= availableHeight) {
            // Image fits on one page, no need to split
            // Add page number text in top right
            pdf.setFontSize(10);
            pdf.text("Page 1 of 1", pageWidth - 30, 5);

            pdf.addImage(
              dataUrl,
              "PNG",
              0,
              padding,
              pageWidth,
              scaledHeight,
              "",
              "FAST",
            );
          } else {
            // Image needs to be split into multiple pages
            const totalPages = Math.ceil(scaledHeight / availableHeight);

            // Create a temporary canvas to help split the image
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            const ctx = canvas.getContext("2d");

            for (let page = 0; page < totalPages; page++) {
              if (page > 0) {
                pdf.addPage();
              }

              // Add page number text in top right
              pdf.setFontSize(10);
              pdf.text(`Page ${page + 1} of ${totalPages}`, pageWidth - 30, 5);

              // Calculate the height for this section
              const remainingHeight =
                img.height -
                page * ((availableHeight / scaledHeight) * img.height);
              const currentSectionHeight = Math.min(
                (availableHeight / scaledHeight) * img.height,
                remainingHeight,
              );

              // Adjust canvas height for this section
              canvas.height = currentSectionHeight;

              // Clear canvas and draw section of original image
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(
                img,
                0, // source x
                page * ((availableHeight / scaledHeight) * img.height), // source y
                img.width, // source width
                currentSectionHeight, // source height
                0, // dest x
                0, // dest y
                canvas.width, // dest width
                canvas.height, // dest height
              );

              // Calculate the scaled height for this section
              const sectionScaledHeight =
                (currentSectionHeight / img.height) * scaledHeight;

              // Add the section to the PDF
              const sectionDataUrl = canvas.toDataURL("image/png");
              pdf.addImage(
                sectionDataUrl,
                "PNG",
                0, // x position
                padding, // y position
                pageWidth,
                sectionScaledHeight,
                "",
                "FAST",
              );
            }
          }

          if (download) {
            window.open(pdf.output("bloburl"));
            pdf.save(`${id}.pdf`);
            return;
          } else {
            const out = pdf.output("datauristring");
            const cpj = new JSPM.ClientPrintJob();
            let myPrinter;
            if (printer) {
              myPrinter = new JSPM.InstalledPrinter(printer);
            } else {
              myPrinter = new JSPM.DefaultPrinter();
            }
            cpj.clientPrinter = myPrinter;
            for (let i = 0; i < noOfCopies; i++) {
              const myFile = new JSPM.PrintFilePDF(
                out,
                JSPM.FileSourceType.URL,
                `${id}_${i}.pdf`,
                1,
              );
              cpj.files.push(myFile);
            }
            cpj.sendToClient();
          }
        };
      });
  } catch (err) {
    throw err;
  }
};
