import { useState, useEffect, useContext } from "react";
import HeaderWithArrow from "#newUiComponents/commons/HeaderWithArrow";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { useFeatureFlags } from "#contexts/featureFlags";
import { AuthContext } from "#contexts/auth";
import ForbiddenPage from "#pages/ForbiddenError";

const useNotificationAccess = (isV2Enabled) => {
  const auth = useContext(AuthContext);
  const { notificationV2Enabled } = useFeatureFlags();
  const [isLoading, setIsLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    const access =
      auth?.role?.toLowerCase() === "admin" &&
      auth?.permissions?.some(
        (permission) =>
          permission.route === "/notifications" && permission.readable,
      ) &&
      (isV2Enabled ? notificationV2Enabled : !notificationV2Enabled);

    const timer = setTimeout(() => {
      setHasAccess(access);
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [auth, notificationV2Enabled, isV2Enabled]);

  return { isLoading, hasAccess };
};

const NotificationV2LoaderUI = () => (
  <div className="min-h-screen bg-white font-inter">
    <div className="mb-4 px-8 pt-8 font-inter">
      <div className="flex items-center font-inter">
        <div className="flex-1">
          <HeaderWithArrow
            headerTitle="Notification Management"
            description="Effortlessly manage and streamline all consumer and internal notifications from one central hub"
            isArrow={false}
            isLearnMore={false}
          />
        </div>
      </div>
    </div>
    <div className="grid animate-pulse grid-cols-2 gap-4 px-8">
      {[1, 2].map((_, index) => (
        <div
          key={index}
          className="flex items-center rounded-lg border p-6 shadow-sm transition hover:shadow-md">
          <div className="mr-4 flex items-center justify-center">
            <div
              className="h-20 rounded-md bg-slate-300"
              style={{ width: "5.1rem" }}
            />
          </div>
          <div className="flex-1 space-y-2">
            <div className="h-5 w-64 rounded-md bg-slate-300" />
            <div
              className="h-4 w-full rounded-md bg-slate-300"
              style={{ height: "0.7rem" }}
            />
            <div
              className="h-4 w-36 rounded-md bg-slate-300"
              style={{ height: "0.7rem" }}
            />
          </div>
          <div className="ml-4 font-semibold">
            <ChevronRightIcon className="h-6 w-6" />
          </div>
        </div>
      ))}
    </div>
  </div>
);

const NotificationLoaderUI = () => (
  <div className="min-h-screen bg-white font-inter">
    <div className="mb-4 px-8 pt-8 font-inter">
      <h1 className="py-2 pt-0 font-montserrat text-2xl font-medium text-primaryAccent">
        Notifications
      </h1>
    </div>
    <div className="mb-4 flex animate-pulse flex-wrap gap-4 px-4">
      <div className="flex w-full max-w-md items-center space-x-4">
        <div className="h-10 w-36 rounded-md bg-slate-300"></div>
        <div className="h-10 w-36 rounded-md bg-slate-300"></div>
      </div>
    </div>
    <div className="mb-4 flex animate-pulse justify-between gap-2 px-4">
      <div className="h-10 w-96 rounded-md bg-slate-300"></div>
      <div className="h-10 w-52 rounded-md bg-slate-300"></div>
      <div className="h-10 w-52 rounded-md bg-slate-300"></div>
      <div className="h-10 w-52 rounded-md bg-slate-300"></div>
    </div>
    <div className="relative h-[70vh] w-full animate-pulse overflow-auto px-4">
      <table className="w-full divide-y divide-gray-200">
        <thead className="sticky top-0 bg-primaryAccent">
          <tr>
            {[...Array(8)].map((_, i) => (
              <th key={i} className="px-4 py-3 text-left">
                <div className="h-6 w-32 rounded-md bg-slate-300"></div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[...Array(5)].map((_, i) => (
            <tr key={i} className="bg-white">
              {[...Array(8)].map((_, j) => (
                <td key={j} className="px-4 py-3">
                  <div className="h-6 w-32 rounded-md bg-slate-300"></div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

export const NotificationV2Loader = () => {
  const { isLoading, hasAccess } = useNotificationAccess(true);

  if (isLoading || hasAccess) return <NotificationV2LoaderUI />;

  return <ForbiddenPage />;
};

export const NotificationLoader = () => {
  const { isLoading, hasAccess } = useNotificationAccess(false);

  if (isLoading || hasAccess) return <NotificationLoaderUI />;

  return <ForbiddenPage />;
};

export const NotificationAlertsLoaderUI = ({ className }) => (
  <div className="w-full space-y-4 font-inter">
    <div className={`animate-pulse pr-2 ${className}`}>
      {[...Array(15)].map((_, index) => (
        <div className="flex w-full flex-1 flex-row gap-4 px-0" key={index}>
          <div className="flex w-full items-center border-b px-0 py-4">
            <div className="flex flex-1 items-center justify-between">
              <div className="flex flex-row items-center justify-start space-x-2">
                <div className="h-10 w-10 rounded-full bg-slate-300" />
                <div className="space-y-1">
                  <p className="h-4 w-96 rounded-md bg-slate-300" />
                  <p className="h-3 w-28 rounded-md bg-slate-300" />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);
