import _ from "lodash";

export const pluralize = (count, noun, suffix = "s") => {
  if (count === 1) return `${count} ${noun}`;
  return `${count} ${noun}${suffix}`;
};

export const handlePositiveValue = (value, type = "int") => {
  if (isNaN(value) || parseFloat(value) < 0) {
    return 0;
  }

  return type === "int" ? parseInt(value) : parseFloat(value);
};

export const getBulkUploadTemplateURL = (fileName) => {
  let templateEnvironmentBucket = "uat";
  if (process.env.NODE_ENV === "uat") {
    templateEnvironmentBucket = "uat";
  } else if (process.env.NODE_ENV === "development") {
    templateEnvironmentBucket = "uat";
  } else if (process.env.NODE_ENV === "production") {
    templateEnvironmentBucket = "prod";
  }
  return `https://templates-onboarding.s3.amazonaws.com/${templateEnvironmentBucket}/${fileName}.csv`;
};

export const generatePlantingZoneCodes = () => {
  return Array.from({ length: 13 }, (_, i) => {
    const baseHz = i + 1;
    return [
      { label: `${baseHz}a`, value: `${baseHz}a`, hz: baseHz + 0.1 },
      { label: `${baseHz}b`, value: `${baseHz}b`, hz: baseHz + 0.2 },
    ];
  }).flat();
};

export const capitalizeLabel = (field) => {
  if (!field) return "";
  return field
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

export const formatTwoDigits = (num) => (num < 10 ? `0${num}` : num);

export const formateInitials = (inputString) => {
  return inputString
    ? inputString
        .split(" ")
        .map((word) => word.charAt(0))
        .join("")
        .toUpperCase()
    : "NA";
};

export const formatCount = (count) => {
  if (count) {
    if (count >= 1000) {
      return `${(count / 1000).toFixed(1)}k`;
    }
    return count.toString();
  }
  return 0;
};

export const notificationPermissionAccess = (auth) => {
  if (auth) {
    return (
      auth?.role?.toLowerCase() === "admin" &&
      auth?.permissions?.some(
        (permission) =>
          permission.route === "/notifications" && permission.readable,
      )
    );
  }
  return false;
};

export const updateNotificationsRoutes = (
  routes,
  notificationEnabled,
  user,
) => {
  const hasAdminAccess = notificationPermissionAccess(user);
  return routes
    .map((route) => {
      if (route.name === "Notifications") {
        if (!hasAdminAccess || notificationEnabled) {
          return null;
        }
      }
      if (route.name === "Setup" && route.children) {
        route.children = route.children
          .map((child) => {
            if (child.name === "Notification Management") {
              if (hasAdminAccess && notificationEnabled) {
                return {
                  ...child,
                  href: "/notificationsv2",
                  isCurrent: (pathname) => pathname === "/notificationsv2",
                };
              }
              return null;
            }
            return child;
          })
          .filter(Boolean);
      }
      return route;
    })
    .filter(Boolean);
};

export const transformCostPrice = (
  costPrice,
  transform,
  pastAddedCostPrice,
) => {
  if (transform) {
    let selectedCostPrice = costPrice;
    let isCpModified = false;
    if (selectedCostPrice) {
      if (
        _.values(_.omit(selectedCostPrice, ["currency"])).filter((val) => !!val)
          .length > 0
      ) {
        let breakdownCost = _.omit(selectedCostPrice, ["total", "currency"]);
        if (_.keys(breakdownCost).length > 0) {
          _.keys(breakdownCost).forEach((key) => {
            if (_.isObject(breakdownCost[key])) {
              if (selectedCostPrice.currency)
                breakdownCost[key].currency = selectedCostPrice.currency;
              breakdownCost[key].quantity = Number(
                breakdownCost[key].selectedQuantity,
              );
              breakdownCost[key] = _.omit(breakdownCost[key], [
                "selectedQuantity",
                "selectedPrice",
              ]);
            } else breakdownCost[key] = Number(breakdownCost[key]) * 100;
          });
          if (!_.isEmpty(_.omit(breakdownCost, ["discount"]))) {
            selectedCostPrice = [
              {
                ..._.omit(selectedCostPrice, ["total"]),
                ...breakdownCost,
              },
            ];
          } else
            selectedCostPrice = [
              {
                ...selectedCostPrice,
                ...breakdownCost,
                total: Number(selectedCostPrice.total) * 100,
              },
            ];
        } else {
          const total = Number(selectedCostPrice.total) * 100;
          selectedCostPrice = [{ ...selectedCostPrice, total }];
        }
        if (_.isEqual(selectedCostPrice, pastAddedCostPrice))
          isCpModified = false;
        else isCpModified = true;
      } else {
        if (pastAddedCostPrice && pastAddedCostPrice?.length > 0) {
          selectedCostPrice = [];
          isCpModified = false;
        } else {
          const { costPrice, ...restObjects } = selectedCostPrice;
          selectedCostPrice = restObjects;
        }
      }
    }
    selectedCostPrice =
      (selectedCostPrice?.length &&
        selectedCostPrice?.map((item) => {
          const componentDetails = Object.values(item).filter(
            (value) => typeof value === "object" && value.id,
          );
          const { currency, ...rest } = item;
          const filteredRest = Object.keys(rest).reduce((acc, key) => {
            if (!item[key]?.id) {
              acc[key] = rest[key];
            }
            return acc;
          }, {});
          return {
            currency,
            componentDetails,
            ...filteredRest,
          };
        })) ||
      [];

    return {
      costPrice: selectedCostPrice,
      isCpModified: isCpModified,
    };
  }
  if (!transform) {
    return (
      costPrice?.map((item) => {
        const { currency, componentDetails, ...rest } = item;

        const components = componentDetails?.reduce((acc, component) => {
          acc[component.id] = component;
          return acc;
        }, {});

        return {
          currency,
          ...components,
          ...rest,
        };
      }) || []
    );
  }
};
