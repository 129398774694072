import ModalV2 from "../utils/ModalV2";
import _ from "lodash";

const NestedInventoryModal = ({ contents, setContents }) => {
  return (
    <ModalV2
      noPadding={true}
      title={`Nested Inventory (${_.sumBy(contents, "quantity")} ${contents[0].formFactor})`}
      xIconClicked={() => setContents(null)}
      onClose={() => setContents(null)}>
      <table className="min-w-full">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="text-md px-6 py-3 text-left font-medium tracking-wider text-gray-500">
              Code
            </th>
            <th
              scope="col"
              className="text-md px-6 py-3 text-left font-medium tracking-wider text-gray-500">
              UoM
            </th>
            <th
              scope="col"
              className="text-md px-6 py-3 text-left font-medium tracking-wider text-gray-500">
              Nested Inventory
            </th>
            <th
              scope="col"
              className="text-md px-6 py-3 text-left font-medium tracking-wider text-gray-500">
              Quantity
            </th>
            <th
              scope="col"
              className="text-md px-6 py-3 text-left font-medium tracking-wider text-gray-500">
              Container Status
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {contents?.length &&
            contents.map((item) => (
              <tr>
                <td className="text-md px-6 py-3 text-left font-medium tracking-wider text-gray-500">
                  {item.inventoryTrackingId || "-"}
                </td>
                <td className="text-md px-6 py-3 text-left font-medium tracking-wider text-gray-500">
                  {item.formFactor || "-"}
                </td>
                <td className="text-md px-6 py-3 text-left font-medium tracking-wider text-gray-500">
                  {item.contents?.length > 0 ? "View" : "-"}{" "}
                </td>
                <td className="text-md px-6 py-3 text-left font-medium tracking-wider text-gray-500">
                  {item.quantity || "-"}
                </td>
                <td className="text-md px-6 py-3 text-left font-medium tracking-wider text-gray-500">
                  {item.containerStatus || "-"}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </ModalV2>
  );
};

export default NestedInventoryModal;
