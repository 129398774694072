import { useState } from "react";
import PageTitle from "#components/utils/PageTitle";
import TextField from "#components/utils/TextField";
import { buildMarketplaceHyperlink } from "#utils/buildMarketplaceHyperlink";
import QRCode from "react-qr-code";
import _ from "lodash";
import WorkflowProductImages from "#components/products/WorkflowProductImages";

const DetailedView = ({
  currentItem,
  currentBatch,
  scanBarcode,
  confirmPickItem,
  skipItem,
  onChangeScannedQuantity,
  displayScan,
  setDisplayScan,
  currentProduct,
}) => {
  const [manualValue, setManualValue] = useState(null);
  const scannedBin = currentItem.scannedBin === true;
  const scannedSubTote = currentItem.scannedSubTote === true;

  const isPlanSatisfied = (pickingPlan) => {
    return pickingPlan.every((item) => item.status === "PICKED");
  };

  const renderPickingPlan = () => {
    // find first object with status PENDING
    const pendingItem = currentItem.pickingPlan.find(
      (item) => item.status === "PENDING",
    );

    if (!pendingItem) {
      return null;
    }

    // if pending item has breakdown, we need to indicate to the picker that they need to scan the formFactor AND remove the breakdown quantity from it
    // if pending item doesn't have breakdown, we need to indicate to the picker that they need to scan the quantity of formFactors

    if (pendingItem.breakdown) {
      return (
        <>
          <div className="mt-4 flex items-center justify-center text-2xl text-454A4F">
            {pendingItem.isPartialContainer
              ? `Scan ${!pendingItem.isBaseUom ? "any unopened" : "any"} ${pendingItem.formFactor} or an open ${pendingItem.formFactor} with at least ${pendingItem.breakdown[0].quantity} ${pendingItem.breakdown[0].formFactor} from this location`
              : `Scan ${!pendingItem.isBaseUom ? "any unopened" : "any"} ${pendingItem.formFactor} from this location`}
          </div>
          <div className="mt-4 flex items-center justify-center text-2xl text-454A4F">
            You need to pick exactly {pendingItem.breakdown[0].quantity}{" "}
            {pendingItem.breakdown[0].formFactor}
            {pendingItem.breakdown[0].quantity > 1 && "(s)"} from this{" "}
            {pendingItem.formFactor}.
          </div>
        </>
      );
    }

    return (
      <div className="mt-4 flex items-center justify-center text-2xl text-454A4F">
        {pendingItem.isPartialContainer
          ? `Scan ${pendingItem.quantity} ${!pendingItem.isBaseUom ? "unopened" : ""} ${pendingItem.formFactor} or an open ${pendingItem.formFactor} with at least ${pendingItem.baseUomEquivalent} ${pendingItem.baseUom}(s) from this location`
          : `Scan ${pendingItem.quantity} ${!pendingItem.isBaseUom ? "unopened" : ""} ${pendingItem.formFactor} from this location`}
      </div>
    );
  };
  return (
    <div className="mx-auto max-w-7xl flex-col items-center justify-center">
      <div className="pt-8">
        <PageTitle>Product Details</PageTitle>
        <div className="grid grid-cols-3">
          <div
            className={`w-full flex-1 bg-gray-100 ${
              currentProduct?.images?.length > 0 ? "col-span-2" : "col-span-3"
            }`}>
            <div className="space-y-2 text-xl font-medium text-454A4F">
              <LineItemValue
                value={currentItem.binLocation}
                title="Bin Location"
                idx={2}
              />
              {currentItem.tote && (
                <LineItemValue value={currentItem.tote} title="Tote" idx={3} />
              )}
              {currentItem.bestByDate && (
                <LineItemValue
                  value={currentItem.bestByDate}
                  title="Expiry Date"
                  idx={3}
                />
              )}
              <LineItemValue
                value={currentItem.quantity}
                title="Quantity"
                idx={5}
              />
              {currentItem.formFactor && (
                <LineItemValue
                  value={currentItem.formFactor}
                  title="Form Factor"
                  idx={6}
                />
              )}
              <LineItemValue value={currentItem.sku} title="SKU" idx={3} />
              {currentItem.productAlias && (
                <LineItemValue
                  title={"Customer ID"}
                  value={currentItem.productAlias}
                  idx={3}
                />
              )}

              {currentItem.customAttributes?.stockMoveSize && (
                <LineItemValue
                  value={currentItem.customAttributes.stockMoveSize}
                  title="Size"
                  idx={4}
                />
              )}
              {currentItem.fnSku && (
                <LineItemValue
                  value={currentItem.fnSku}
                  title="FN SKU"
                  idx={4}
                />
              )}
              {currentItem.asin && (
                <LineItemValue value={currentItem.asin} title="ASIN" idx={5} />
              )}
              {currentItem.upc && (
                <LineItemValue
                  value={currentItem?.upc?.join(", ")}
                  title="UPC"
                  idx={5}
                />
              )}
              <LineItemValue
                value={currentItem.productName}
                title="Product Name"
                idx={4}
              />
            </div>
          </div>
          {currentProduct?.images && (
            <div className="flex flex-1 items-center justify-center bg-gray-300">
              <WorkflowProductImages
                images={[
                  currentProduct.images.find(
                    (i) => i.purpose === "OPERATIONS",
                  ) || currentProduct.images[0],
                ].filter(Boolean)}
              />
            </div>
          )}
        </div>
      </div>

      <div
        onClick={() => {
          if (scannedBin) {
            if (currentItem.tote && !currentItem.scannedTote) {
              scanBarcode(currentItem.tote);
              return;
            }
            scanBarcode(currentItem.sku);
          } else {
            scanBarcode(currentItem.binLocation);
          }
        }}>
        <div className="mt-4 flex items-center justify-center">
          <div
            className={`mt-4 rounded-full border-4 border-FC8862 p-5 shadow-md ${
              scannedBin && scannedSubTote && "border-gray-600 bg-gray-400"
            }`}>
            <QRCode
              value={qrcodeValue(scannedBin, currentItem.binLocation)}
              size="70"
            />
          </div>
        </div>
        <div className="mt-4 flex items-center justify-center text-2xl text-454A4F">
          {scanItemMessage(scannedBin)}
        </div>
      </div>

      <div className="mt-2 flex-col items-center justify-center bg-white p-10 pb-5 pt-5">
        <div>
          <TextField
            type="text"
            id="name"
            label="Scan Bin/Tote/Item"
            placeholder=" "
            onChange={(e) => setManualValue(e.target.value)}
            value={manualValue}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                scanBarcode(manualValue);
                setManualValue("");
              }
            }}
            autoFocus={true}
          />
        </div>
      </div>

      {!scannedBin && (
        <div className="mt-4 flex items-center justify-center text-2xl text-454A4F">
          Please Scan Bin Location
        </div>
      )}

      {scannedBin &&
        !currentItem?.pickingPlan &&
        currentBatch?.skuConfirmationNeeded && (
          <div className="mt-4 flex items-center justify-center text-2xl text-454A4F">
            Please Scan Item
          </div>
        )}

      {scannedBin && currentItem?.pickingPlan && renderPickingPlan()}

      {currentItem?.scannedSkus > 0 || displayScan ? (
        <div className="mt-10 grid grid-cols-2 self-center">
          <div className="justify-cente flex items-center bg-white p-6 py-2 text-3xl">
            <div className="py-2 text-3xl text-5F666B">Picked Quantity</div>
          </div>
          <div className="flex items-center justify-center bg-white p-6 py-2 text-3xl text-5F666B">
            <TextField
              type="number"
              id="scannedSkus"
              label="Picked Quantity"
              placeholder=" "
              onChange={(e) => {
                onChangeScannedQuantity(e.target.value);
                setDisplayScan(true);
              }}
              value={currentItem.scannedSkus}
            />
          </div>
        </div>
      ) : null}

      <div className="mt-20 flex items-center justify-center">
        {scannedBin &&
          ((!currentItem.pickingPlan && currentItem.scannedSkus > 0) ||
            (currentItem.pickingPlan &&
              isPlanSatisfied(currentItem.pickingPlan) && (
                <div
                  className="w-64 cursor-pointer rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
                  onClick={confirmPickItem}>
                  Confirm Item
                </div>
              )))}
        {(!scannedBin ||
          (currentItem.pickingPlan &&
            !isPlanSatisfied(currentItem.pickingPlan)) ||
          (!currentItem.pickingPlan && currentItem.scannedSkus === 0)) && (
          <div className="w-64 rounded-md bg-gray-400 py-4 text-center text-2xl text-white">
            Confirm Item
          </div>
        )}
        {skipItem && (
          <div
            className="ml-4 w-64 cursor-pointer rounded-md bg-red-600 py-4 text-center text-2xl text-white"
            onClick={skipItem}>
            Skip Item
          </div>
        )}
      </div>
    </div>
  );
};

const LineItemValue = ({ title, value, idx }) => (
  <div className="flex w-full max-w-4xl space-x-1 shadow-md" key={idx}>
    <div
      className={`flex w-64 items-end justify-end rounded-bl-md rounded-tl-md border-l-8 bg-white p-4 text-2C7695 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div
      className={`flex flex-1 items-center rounded-br-md rounded-tr-md border bg-white p-4`}>
      {["ASIN"].includes(title)
        ? buildMarketplaceHyperlink(value, title)
        : value}
    </div>
  </div>
);

const qrcodeValue = (scannedBin, binLocation) => {
  if (scannedBin) {
    return `NOVALUE`;
  }

  return binLocation;
};

const scanItemMessage = (scannedBin) => {
  if (scannedBin) {
    return `Scanned`;
  }

  if (!scannedBin) {
    return "Scan Bin";
  }
};

export default DetailedView;
