import { useEffect, useRef } from "react";
const POLLING_TIMER = 1800000; // 30 min polling interval

const useNotifications = (
  notificationV2Enabled,
  fetchNotifications,
  notification,
) => {
  const autoRefreshInterval = useRef(null);

  const fetchAndSetNotifications = async (filters) => {
    try {
      const response = await fetchNotifications(filters);
      if (response?.data) {
        const notificationsResponse = response.data.listNotificationsV1 || {};
        const entities = notificationsResponse.entities || [];

        notification.setEntities({
          entities,
          total: notificationsResponse?.total || 0,
          unreadTotal: notificationsResponse?.totalUnreadCount || 0,
          paginated: false,
          pageNumber: 1,
          notification: "notificationV2",
          markAsRead: false,
        });
      }
    } catch (error) {
      clearInterval(autoRefreshInterval.current);
    }
  };

  useEffect(() => {
    if (notificationV2Enabled) {
      if (autoRefreshInterval.current) {
        clearInterval(autoRefreshInterval.current);
      }

      fetchAndSetNotifications({
        filters: {
          subCategory: "ALL",
        },
        pageNumber: 1,
        perPage: notification?.perPage,
      });

      autoRefreshInterval.current = setInterval(() => {
        fetchAndSetNotifications({
          filters: {
            subCategory: "ALL",
          },
          pageNumber: 1,
          perPage: notification?.perPage,
        });
      }, POLLING_TIMER);
    }

    return () => {
      if (autoRefreshInterval.current) {
        clearInterval(autoRefreshInterval.current);
      }
    };
  }, [notificationV2Enabled]);

  useEffect(() => {
    if (notification?.markAsRead) {
      fetchAndSetNotifications({
        filters: {
          subCategory: "ALL",
        },
        pageNumber: 1,
        perPage: notification?.perPage,
      });
    }
  }, [notification?.markAsRead]);
};

export default useNotifications;
