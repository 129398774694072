import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_CURRENT_PREPPER_BATCH,
  GET_STATIONS,
  FETCH_SPECIFIC_ORDER,
  GET_ORDERS,
  GET_WORKFLOW_BY_ATTRIBUTES,
  GET_NEW_PREPPER_BATCH,
  GET_NEW_PACKER_BATCH,
  GET_SKU,
} from "#queries";
import {
  SCAN_PACKER_BARCODE,
  SCAN_PREPPER_BARCODE,
  CONFIRM_PREP_ITEM,
  CONFIRM_PREPPER_DROPOFF,
  LOGOUT_USER,
  SET_STATION,
  CANCEL_PREP_ITEM,
  SET_ACTIVITY,
  RESTART_PREP,
  PAUSE_PREP,
  UNPREP_ITEM,
  LABEL_EVENT,
} from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import { EntityContext } from "#contexts/entity";
import io from "socket.io-client";
import { printCanvasLabel } from "#utils/printCanvasLabel";
import { printCanvasLabelRemote } from "#utils/printCanvasLabelRemote";
import { printThirtyUpLabel } from "#utils/printThirtyUpLabel";
import { printCanvasLabelPdf } from "#utils/printCanvasLabelPdf";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import {
  TENANT_FNSKU_LABEL_PRINTER_ENUM,
  TENANT_FNSKU_LABEL_PRINTING_SIZE_ENUM,
} from "../../constants/printer-settings";
import { GET_LOGGED_IN_USER } from "#queries/index";

const ALERT_TIMEOUT_MS = 5000;
const withPrepperLogic = (WrappedComponent) => {
  return (props) => {
    const [currentBatch, setCurrentBatch] = useState(null);
    const [fnSkus, setFnSkus] = useState(null);
    const [customer, setCustomer] = useState(undefined);
    const [workflow, setWorkflow] = useState(undefined);
    const [weight, setWeight] = useState(0);
    const entity = useContext(EntityContext);
    const [order, setOrder] = useState(null);
    const [currentUser, setCurrentUser] = useState({});
    const fetchSpecificOrder = useQuery(FETCH_SPECIFIC_ORDER);
    const [stations, setStations] = useState([]);
    const [selectedStation, setSelectedStation] = useState(null);
    const logoutQuery = useQuery(LOGOUT_USER);
    const setStationQuery = useQuery(SET_STATION);
    const getUserQuery = useQuery(GET_LOGGED_IN_USER);
    const [currentItemMismatch, setCurrentItemMismatch] = useState(null);
    const confirmDropoffQuery = useQuery(CONFIRM_PREPPER_DROPOFF);
    const confirmDropoffSwitchQuery = useQuery(CONFIRM_PREPPER_DROPOFF);
    const scanPackerBarcodeQuery = useQuery(SCAN_PACKER_BARCODE);
    const appState = useContext(AppStateContext);
    const auth = useContext(AuthContext);
    const stationsQuery = useQuery(GET_STATIONS);
    const currentBatchQuery = useQuery(GET_CURRENT_PREPPER_BATCH);
    const ordersQuery = useQuery(GET_ORDERS);
    const getWorkflowByAttributesQuery = useQuery(GET_WORKFLOW_BY_ATTRIBUTES);
    const getNewBatchQuery = useQuery(GET_NEW_PREPPER_BATCH);
    const getNewPackerBatchQuery = useQuery(GET_NEW_PACKER_BATCH);
    const cancelItemQuery = useQuery(CANCEL_PREP_ITEM);
    const setActivityQuery = useQuery(SET_ACTIVITY);
    const restartActivityQuery = useQuery(RESTART_PREP);
    const labelEventQuery = useQuery(LABEL_EVENT);
    const pauseActivityQuery = useQuery(PAUSE_PREP);
    const unprepItemQuery = useQuery(UNPREP_ITEM);
    const [ordersData, setOrdersData] = useState({});
    const [moveToPacker, setMoveToPacker] = useState(false);
    const scanBarcodeQuery = useQuery(SCAN_PREPPER_BARCODE);
    const confirmPrepItemQuery = useQuery(CONFIRM_PREP_ITEM);
    const getCurrentProductQuery = useQuery(GET_SKU);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [isConfirmedDropoff, setIsConfirmedDropoff] = useState(false);

    useEffect(() => {
      if (getCurrentProductQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getCurrentProductQuery.data) {
        setCurrentProduct(getCurrentProductQuery.data.specificInventory);
      }

      if (getCurrentProductQuery.error) {
        setCurrentProduct(null);
      }
    }, [
      getCurrentProductQuery.loading,
      getCurrentProductQuery.data,
      getCurrentProductQuery.error,
    ]);

    useEffect(() => {
      const client = new W3CWebSocket("ws://127.0.0.1:8456");
      client.onopen = () => {
        console.log("Connected to electron app");
      };
      client.onmessage = (message) => {
        console.log("message received", message);
        const parsed = JSON.parse(message.data);
        let relevant = parsed.data;
        if (relevant && relevant.data) {
          relevant = relevant.data;
        }

        switch (relevant.key) {
          case "scanner-data":
            if (relevant.message) {
              return scanBarcodeQuery.fetchData({
                code: relevant.message,
              });
            }
          case "dymo-m10-attach":
            if (relevant.message === "attached") {
            } else if (relevant.message === "detached") {
            }
            break;
          case "dymo-m10-data":
            try {
              const checkWeight = relevant.message.split(" ");
              let weightVar = parseInt(checkWeight[0]);
              if (isNaN(weightVar) === false) {
                setWeight(weightVar / 10);
              } else {
                setWeight(0);
              }
            } catch (err) {
              console.log(err);
            }

            break;
          case "dymo-m10-error":
            break;
        }
      };
    }, []);

    useEffect(() => {
      return () => {
        entity.resetEntities();
      };
    }, []);

    useEffect(() => {
      getWorkflowByAttributesQuery.fetchData({
        attributes: { name: "Prepping" },
      });
      stationsQuery.fetchData({
        filters: {
          all: true,
          hopstackModule: ["Prepping"],
        },
      });
    }, []);

    useEffect(() => {
      if (auth?.user?.station) {
        const filtersSet = {
          orderStatus: ["IN-PROCESS"],
          subStatus: ["PICKING_COMPLETED"],
          typeOfBatch: "PREPPING",
          batchStatus: ["STARTED", "PAUSED", "COMPLETED"],
          station: auth.user.station,
        };
        ordersData.filters = filtersSet;
        currentBatchQuery.fetchData();
        setOrdersData({
          ...ordersData,
          pageNumber: 1,
          perPage: 25,
          sort: "orderDate",
        });
        ordersQuery.fetchData({
          perPage: 25,
          pageNumber: 1,
          filters: ordersData.filters,
          paginated: false,
          sort: "orderDate",
        });
      }
    }, [auth]);

    useEffect(() => {
      if (setStationQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (setStationQuery.error) {
        if (
          setStationQuery.error?.message &&
          setStationQuery.error.message.indexOf("already") !== -1
        ) {
          appState.showConfirmation(
            "Error logging in",
            setStationQuery.error.message,
            () => {
              setStationQuery.fetchData({
                station: selectedStation,
                logoutAll: true,
              });
              appState.hideConfirmation();
            },
            appState.hideConfirmation,
          );
        } else {
          appState.setAlert(setStationQuery.error.message, "error", 5000);
        }
      }

      if (setStationQuery.data) {
        window.location = "/prepper";
      }
    }, [setStationQuery.data, setStationQuery.error, setStationQuery.loading]);

    useEffect(() => {
      if (ordersQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (ordersQuery.error) {
        setOrdersData({});
      }

      if (ordersQuery.data) {
        setOrdersData({
          ...ordersData,
          orders: ordersQuery.data.orders.entities,
          total: ordersQuery.data.orders.total,
        });

        entity.setEntities({
          ...ordersQuery.data.orders,
          ...ordersQuery.variables,
        });
        appState.removeLoading();
      }
    }, [ordersQuery.loading, ordersQuery.error, ordersQuery.data]);

    useEffect(() => {
      if (auth && auth.user && auth.user.id) {
        const socket = io(localStorage.socketService, {
          path: "/socket-service/socket.io",
        });

        socket.on("observeMessages", (payload) => {
          if (payload.error === true) {
            // dispatch(setAlert(payload.message, "error", 5000));
            appState.setAlert(payload.message, "error", 5000);
          } else if (payload.message) {
            appState.setAlert(payload.message, "success", 5000);
            currentBatchQuery.fetchData();
          }
        });

        socket.on("connect", function () {
          console.log("connected!");
        });

        socket.on("message", (message) => {
          console.log(message);
        });
        socket.emit("subscribe", { roomId: auth.user.id });
      }
    }, [auth]);

    useEffect(() => {
      if (getWorkflowByAttributesQuery.data) {
        setWorkflow(getWorkflowByAttributesQuery.data.getWorkflowByAttributes);
      }

      if (getWorkflowByAttributesQuery.error) {
        setWorkflow(null);
      }

      if (getWorkflowByAttributesQuery.loading) {
        appState.setLoading();
      }
    }, [
      getWorkflowByAttributesQuery.loading,
      getWorkflowByAttributesQuery.error,
      getWorkflowByAttributesQuery.data,
    ]);

    useEffect(() => {
      if (getNewBatchQuery.loading) {
        appState.setLoading();
      } else {
        if (
          getNewBatchQuery.data &&
          getNewBatchQuery.data.getNewPrepperBatch.message
        ) {
          currentBatchQuery.fetchData();
        } else if (getNewBatchQuery.error) {
          appState.setAlert(getNewBatchQuery.error.message, "error", 5000);
        }
        appState.removeLoading();
        // setLoadingFirstTime(false);
      }
    }, [
      getNewBatchQuery.loading,
      getNewBatchQuery.data,
      getNewBatchQuery.error,
    ]);

    useEffect(() => {
      if (logoutQuery.data) {
        appState.setAlert(logoutQuery.data.logout.message);
        auth.logout();
      }

      if (logoutQuery.error) {
        auth.logout();
      }
    }, [logoutQuery.data, logoutQuery.loading, logoutQuery.error]);

    useEffect(() => {
      if (stationsQuery.error) {
        setStations([]);
      }

      if (stationsQuery.data && stationsQuery.data.stations) {
        const foundStations = stationsQuery.data.stations.entities.filter(
          (item) => item.hopstackModule.toString().toLowerCase() === "prepping",
        );
        setStations(foundStations);
      }
    }, [stationsQuery.data, stationsQuery.loading, stationsQuery.error]);

    const proceedToPacking = () => {
      // setActivityQuery.fetchData({ activity: "Packing" });
      if (isConfirmedDropoff) {
        scanPackerBarcodeQuery.fetchData({
          code: order.orderId,
        });
      } else {
        confirmDropoffSwitchQuery.fetchData({
          id: currentBatch.id,
        });
      }
    };

    const handleSetBatchDetails = (batch) => {
      if (batch?.currentItem) {
        batch.currentItem = {
          ...batch.currentItem,
          quantity: 1,
        };
      }

      setCurrentBatch(batch);
      setCustomer(batch?.customer);
      setCurrentItemMismatch(null);
      try {
        fetchSpecificOrder.fetchData({
          orderId: batch?.workingList[0].order,
        });
      } catch (err) {
        appState.setAlert(
          "Could not fetch specific order details. Please contact support.",
        );
      }
      if (batch?.currentItem?.productId) {
        getCurrentProductQuery.fetchData({
          id: batch.currentItem.productId,
        });
      }
    };

    useEffect(() => {
      if (getNewPackerBatchQuery.data) {
        window.location = "/prepper";
      }

      if (getNewPackerBatchQuery.error) {
        window.location = "/prepper";
      }
    }, [
      getNewPackerBatchQuery.data,
      getNewPackerBatchQuery.loading,
      getNewPackerBatchQuery.error,
    ]);

    useEffect(() => {
      if (setActivityQuery.data) {
        getNewPackerBatchQuery.fetchData({
          orderId: order.id,
        });
      }
    }, [
      setActivityQuery.loading,
      setActivityQuery.error,
      setActivityQuery.data,
    ]);

    useEffect(() => {
      if (currentBatchQuery.loading) {
        appState.setLoading();
      } else {
        if (
          currentBatchQuery.data &&
          currentBatchQuery.data.getCurrentPrepperBatch
        ) {
          handleSetBatchDetails(currentBatchQuery.data.getCurrentPrepperBatch);
        }
        appState.removeLoading();
      }

      if (currentBatchQuery.error) {
        setCurrentBatch(null);
        setOrder(null);
      }
    }, [
      currentBatchQuery.loading,
      currentBatchQuery.data,
      currentBatchQuery.error,
    ]);

    useEffect(() => {
      if (fetchSpecificOrder.data) {
        setOrder(fetchSpecificOrder.data.order);
      }

      if (fetchSpecificOrder.error) {
        appState.setAlert(fetchSpecificOrder.error.message, "error", 5000);
        setOrder(null);
      }

      if (fetchSpecificOrder.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      fetchSpecificOrder.loading,
      fetchSpecificOrder.error,
      fetchSpecificOrder.data,
    ]);

    useEffect(() => {
      if (scanBarcodeQuery.error) {
        appState.setAlert(scanBarcodeQuery.error.message, "error", 5000);
        appState.removeLoading();
        window.location.reload();
      }
      if (scanBarcodeQuery.loading) {
        appState.setLoading();
        setTimeout(() => {
          appState.removeLoading();
        }, 500);
      } else if (
        scanBarcodeQuery.data &&
        scanBarcodeQuery.data.scanPrepperBarcode &&
        scanBarcodeQuery.data.scanPrepperBarcode.message
      ) {
        appState.setAlert(
          scanBarcodeQuery.data.scanPrepperBarcode.message,
          "success",
          2000,
        );

        if (scanBarcodeQuery.data.scanPrepperBarcode?.batch) {
          handleSetBatchDetails(scanBarcodeQuery.data.scanPrepperBarcode.batch);
        } else {
          // when batch is null
          window.location.reload();
        }
        // currentBatchQuery.fetchData();
        appState.removeLoading();
      }
    }, [
      scanBarcodeQuery.loading,
      scanBarcodeQuery.data,
      scanBarcodeQuery.error,
    ]);
    useEffect(() => {
      if (confirmPrepItemQuery.loading) {
        appState.setLoading();
      } else {
        if (
          confirmPrepItemQuery.data &&
          confirmPrepItemQuery.data.confirmPrepItem &&
          confirmPrepItemQuery.data.confirmPrepItem.message
        ) {
          setCurrentItemMismatch(null);
          // currentBatchQuery.fetchData();
          if (confirmPrepItemQuery.data.confirmPrepItem?.batch) {
            handleSetBatchDetails(
              confirmPrepItemQuery.data.confirmPrepItem.batch,
            );
          }
        } else if (confirmPrepItemQuery.error) {
          appState.setAlert(confirmPrepItemQuery.error.message, "error");
          currentBatchQuery.fetchData();
        }
        appState.removeLoading();
      }
    }, [
      confirmPrepItemQuery.loading,
      confirmPrepItemQuery.data,
      confirmPrepItemQuery.error,
    ]);

    useEffect(() => {
      (async () => {
        const getUserQueryResponse = await getUserQuery.fetchData();
        if (getUserQueryResponse.data.me) {
          setCurrentUser(getUserQueryResponse.data.me);
        }
      })();
    }, [auth.user]);

    useEffect(() => {
      if (cancelItemQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (cancelItemQuery.error) {
        currentBatchQuery.fetchData();
        appState.setAlert(cancelItemQuery.error.message, "error", 5000);
      }

      if (cancelItemQuery.data) {
        currentBatchQuery.fetchData();
      }
    }, [cancelItemQuery.loading, cancelItemQuery.data, cancelItemQuery.error]);

    useEffect(() => {
      if (confirmDropoffQuery.loading) {
        appState.setLoading();
      } else {
        if (
          confirmDropoffQuery.data &&
          confirmDropoffQuery.data.confirmPrepperDropoff.message
        ) {
          setIsConfirmedDropoff(true);
          appState.setAlert(
            confirmDropoffQuery.data.confirmPrepperDropoff.message,
            "success",
            5000,
          );
          currentBatchQuery.fetchData();
          ordersQuery.fetchData({
            perPage: 25,
            pageNumber: 1,
            filters: ordersData.filters,
            paginated: false,
            sort: "orderDate",
          });
        }
        if (confirmDropoffQuery.error) {
          currentBatchQuery.fetchData();
        }
        appState.removeLoading();
      }
    }, [
      confirmDropoffQuery.loading,
      confirmDropoffQuery.data,
      confirmDropoffQuery.error,
    ]);

    useEffect(() => {
      if (scanPackerBarcodeQuery.error) {
        let errorMessage = scanPackerBarcodeQuery.error.message;
        if (
          errorMessage ===
          "Either you've already packed this item or this \
is an Invalid SKU barcode for this batch."
        ) {
          errorMessage =
            "Another packing batch is in progress. \
It must be completed before initiating new packing.";
        }
        appState.setAlert(errorMessage, "error", 5000);
        currentBatchQuery.fetchData();
        appState.removeLoading();
      }
      if (scanPackerBarcodeQuery.loading) {
        appState.setLoading();
        setTimeout(() => {
          appState.removeLoading();
        }, 500);
      } else {
        if (scanPackerBarcodeQuery.data) {
          appState.setAlert(
            scanPackerBarcodeQuery.data.scanPackerBarcode.message,
            "success",
            2000,
          );
          setMoveToPacker(true);
          setActivityQuery.fetchData({ activity: "Packing" });
          appState.removeLoading();
        }
      }
    }, [
      scanPackerBarcodeQuery.loading,
      scanPackerBarcodeQuery.data,
      scanPackerBarcodeQuery.error,
    ]);

    useEffect(() => {
      if (confirmDropoffSwitchQuery.loading) {
        appState.setLoading();
      } else {
        if (
          confirmDropoffSwitchQuery.data &&
          confirmDropoffSwitchQuery.data.confirmPrepperDropoff.message
        ) {
          setIsConfirmedDropoff(true);
          scanPackerBarcodeQuery.fetchData({
            code: order.orderId,
          });
        }
        appState.removeLoading();
      }
    }, [
      confirmDropoffSwitchQuery.loading,
      confirmDropoffSwitchQuery.data,
      confirmDropoffSwitchQuery.error,
    ]);

    const triggerConfirmPrepItem = (currentItem, status) => {
      appState.showConfirmation(
        "Confirmation",
        `Are you sure you want to mark this item as ${status}?`,
        () => {
          // alert(currentBatch.currentItem.quantity);
          // confirmPrepItemQuery.fetchData({
          //   itemId: currentItem,
          //   id: currentBatch.id,
          //   status,
          //   quantity: currentBatch.currentItem.quantity,
          // });
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
      );
    };

    const simulateTote = async () => {
      if (entity.entities.length === 0) {
        appState.setAlert("No open orders found", "error", 5000);
        return;
      }

      const findOrder = entity.entities.find(
        (item) => item.orderStatus === "IN-PROCESS" && item.tote !== null,
      );
      if (!findOrder) {
        appState.setAlert("No open order found", "error", 5000);
        return;
      }

      return scanBarcodeQuery.fetchData({
        code: findOrder.tote,
      });
    };

    const simulateItemScan = (item) => {
      // return axios.post(`${config.BASE_URL}/api/scan`, {
      //   hostname: "Prep-1",
      //   datalabel: item.upc[0],
      //   simulate: true,
      // });
      let code = null;

      if (item.upc && item.upc[0]) {
        code = item.upc[0];
      } else if (item.lpn && item.lpn[0]) {
        code = item.lpn[0];
      } else {
        code = item.sku;
      }
      return scanBarcodeQuery.fetchData({
        code,
      });
    };

    const printTestFnSkus = () => {
      appState.setAlert(`Printing FN SKUs`, "success", 3000);
      printCanvasLabel(`30up_fnSKUs`, 1, "Printing FN SKUs");
      labelEventQuery.fetchData({
        warehouse: order.warehouse,
        customer: order.customer,
        order: order.id,
        quantity: 1,
        labelType: "FNSKU",
        dropship: false,
      });
    };

    const onSubmitCustomer = (selectedCustomer) => {
      setCustomer(selectedCustomer.value);
    };

    const onSkipCustomer = () => {
      setCustomer(null);
    };

    const getNewBatch = () => {
      if (customer) {
        getNewBatchQuery.fetchData({
          customer,
          warehouse: auth.user.customersList.find((i) => i.id === customer)
            ?.warehouses[0],
        });
      } else {
        getNewBatchQuery.fetchData();
      }
    };

    const printAllFnSkus = () => {
      appState.setAlert(`Triggering print`, "success", 3000);
      appState.setLoading();
      const fns = currentBatch.workingList.map((item) => item.fnSku);
      if (fns && fns.length > 0) {
        setFnSkus(fns);
        setTimeout(() => {
          printThirtyUpLabel(
            fns,
            1,
            TENANT_FNSKU_LABEL_PRINTER_ENUM[appState.subdomain] ||
              TENANT_FNSKU_LABEL_PRINTING_SIZE_ENUM.default,
            appState,
          );
        }, 1000);
        labelEventQuery.fetchData({
          warehouse: order.warehouse,
          customer: order.customer,
          order: order.id,
          quantity: 1,
          labelType: "FNSKU",
          dropship: false,
        });
      } else {
        appState.setAlert(`No FNSKUs to Print`);
      }
    };

    const updateBestByDate = (e) => {
      setCurrentBatch({
        ...currentBatch,
        currentItem: {
          ...currentBatch.currentItem,
          bestByDate: e,
        },
      });
    };

    /**
     * Updates the current batch state with new values for a specified field within the currentItem.
     * This function is typically used as a handler for multi-select input changes.
     *
     * @param {string} field - The field within currentItem that needs to be updated.
     * @param {Array} values - The new values to be set for the specified field.
     */
    const onChangeMultiSelect = (field, values) => {
      setCurrentBatch((currentBatch) => {
        const newBatch = { ...currentBatch };

        if (!newBatch.currentItem) {
          newBatch.currentItem = {};
        }
        newBatch.currentItem[field] = values;

        return newBatch;
      });
    };

    const changeQuantity = (e) => {
      if (e >= 0) {
        setCurrentBatch({
          ...currentBatch,
          currentItem: {
            ...currentBatch.currentItem,
            quantity: e,
          },
        });
      }

      if (isNaN(e)) {
        setCurrentBatch({
          ...currentBatch,
          currentItem: {
            ...currentBatch.currentItem,
            quantity: null,
          },
        });
      }
    };

    const checkPagination = (direction) => {
      let vars = {
        filters: ordersData.filters,
        sort: "orderDate",
        perPage: 25,
      };
      if (direction === "backward") {
        vars["pageNumber"] = ordersData.pageNumber - 1;
      } else {
        vars["pageNumber"] = ordersData.pageNumber + 1;
      }
      setOrdersData({
        ...ordersData,
        pageNumber: vars["pageNumber"],
      });
      return ordersQuery.fetchData(vars);
    };

    const restartActivity = () => {
      appState.showConfirmation(
        "Confirmation",
        `Are you sure you want to restart this activity? All changes will be lost.`,
        () => {
          restartActivityQuery.fetchData({ id: currentBatch.id });
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
      );
    };

    const pauseActivity = () => {
      appState.showConfirmation(
        "Confirmation",
        `Are you sure you want to pause this activity?`,
        () => {
          pauseActivityQuery.fetchData({ id: currentBatch.id });
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
      );
    };

    useEffect(() => {
      if (restartActivityQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (restartActivityQuery.data) {
        appState.setAlert(
          restartActivityQuery.data.restartPrep.message,
          "success",
          5000,
        );
        appState.hideConfirmation();
        // currentBatchQuery.fetchData();
        if (restartActivityQuery.data.restartPrep?.batch) {
          handleSetBatchDetails(restartActivityQuery.data.restartPrep.batch);
        }
      }

      if (restartActivityQuery.error) {
        appState.setAlert(restartActivityQuery.error.message, "error", 5000);
        appState.hideConfirmation();
        currentBatchQuery.fetchData();
      }
    }, [
      restartActivityQuery.loading,
      restartActivityQuery.data,
      restartActivityQuery.error,
    ]);

    useEffect(() => {
      if (pauseActivityQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (pauseActivityQuery.data) {
        appState.setAlert(
          pauseActivityQuery.data.pausePrep.message,
          "success",
          5000,
        );
        appState.hideConfirmation();
        currentBatchQuery.fetchData();
      }

      if (pauseActivityQuery.error) {
        appState.setAlert(pauseActivityQuery.error.message, "error", 5000);
        appState.hideConfirmation();
        currentBatchQuery.fetchData();
      }
    }, [
      pauseActivityQuery.loading,
      pauseActivityQuery.data,
      pauseActivityQuery.error,
    ]);

    useEffect(() => {
      if (unprepItemQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (unprepItemQuery.data) {
        appState.setAlert(
          unprepItemQuery.data.unprepItem.message,
          "success",
          5000,
        );
        appState.hideConfirmation();
        // currentBatchQuery.fetchData();
        if (unprepItemQuery.data.unprepItem?.batch) {
          handleSetBatchDetails(unprepItemQuery.data.unprepItem.batch);
        }
      }

      if (unprepItemQuery.error) {
        appState.setAlert(unprepItemQuery.error.message, "error", 5000);
        appState.hideConfirmation();
        currentBatchQuery.fetchData();
      }
    }, [unprepItemQuery.loading, unprepItemQuery.data, unprepItemQuery.error]);

    const unprepItems = (item, quantity) => {
      if (quantity > item.ids.length) {
        appState.setAlert(
          "Please enter quantity less than or equal to available quantity",
          "error",
          ALERT_TIMEOUT_MS,
        );
        return false;
      }
      appState.showConfirmation(
        "Confirmation",
        `Are you sure you want to re-do this item? This will mark the item as unprocessed.`,
        () => {
          unprepItemQuery.fetchData({
            id: currentBatch.id,
            itemIds: item.ids.splice(0, quantity),
          });
        },
        appState.hideConfirmation,
      );
      return true;
    };

    return (
      <WrappedComponent
        currentBatch={currentBatch}
        scanBarcode={(code) => scanBarcodeQuery.fetchData({ code })}
        currentItemMismatch={currentItemMismatch}
        setCurrentItemMismatch={(status, forcePrint = false) => {
          setCurrentItemMismatch(status);
          if (
            status === "CONFIRMED" &&
            (!appState.tenant?.settings?.activities?.prepping
              ?.disableAutomaticPrinting ||
              forcePrint)
          ) {
            const currentProduct = currentBatch.currentItem;
            const code = currentProduct.fnSku;
            appState.setAlert(`Printing FN SKU`, "success", 3000);
            handleFnSkuPrinting(
              appState,
              code,
              currentBatch.currentItem.quantity,
              currentProduct?.name || currentProduct?.productName,
              currentBatch.currentItem.bestByDate,
            );
            labelEventQuery.fetchData({
              warehouse: order.warehouse,
              customer: order.customer,
              order: order.id,
              quantity: 1,
              labelType: "FNSKU",
              dropship: false,
            });
          }
        }}
        confirmPrepItem={() => {
          confirmPrepItemQuery.fetchData({
            itemId: currentBatch.currentItem.uniqueIdentifier,
            id: currentBatch.id,
            status: "CONFIRMED",
            quantity: currentBatch.currentItem.quantity,
            bestByDate: currentBatch.currentItem.bestByDate,
            additionalPrepInstructions:
              currentBatch?.currentItem?.additionalPrepInstructions,
          });
        }}
        confirmDropoff={() =>
          confirmDropoffQuery.fetchData({
            id: currentBatch.id,
          })
        }
        loading={currentBatchQuery.loading}
        currentUser={currentUser}
        logout={() => logoutQuery.fetchData()}
        onSelectStation={(e) => {
          setSelectedStation(e);
        }}
        onSubmitStation={() => {
          if (selectedStation) {
            setStationQuery.fetchData({ station: selectedStation });
          } else {
            appState.setAlert("Please select a station", "error", 5000);
          }
        }}
        selectedStation={selectedStation}
        stations={stations}
        proceedToPacking={proceedToPacking}
        simulateTote={simulateTote}
        simulateItemScan={simulateItemScan}
        weight={weight}
        printTestFnSkus={printTestFnSkus}
        getNewBatch={getNewBatch}
        workflow={workflow}
        warehouses={auth.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth.user?.customersList ? auth.user.customersList : []}
        customer={customer}
        setCustomer={setCustomer}
        onSubmitCustomer={onSubmitCustomer}
        onSkipCustomer={onSkipCustomer}
        order={fetchSpecificOrder.data?.order}
        printAllFnSkus={printAllFnSkus}
        fnSkus={fnSkus}
        changeQuantity={changeQuantity}
        subdomain={appState.subdomain}
        cancelItem={() => cancelItemQuery.fetchData({ id: currentBatch.id })}
        orders={ordersData.orders}
        total={ordersData.total}
        pageNumber={ordersData.pageNumber}
        checkPagination={checkPagination}
        perPage={ordersData.perPage}
        filters={ordersData.filters}
        ordersData={ordersData}
        setFilters={(filters) =>
          setOrdersData({
            ...ordersData,
            filters,
          })
        }
        submitFilters={() =>
          ordersQuery.fetchData({
            perPage: 25,
            pageNumber: 1,
            filters: ordersData.filters,
            sort: "orderDate",
          })
        }
        checkOrderById={(data) => {
          scanBarcodeQuery.fetchData({ code: data });
        }}
        restartActivity={restartActivity}
        pauseActivity={pauseActivity}
        unprepItems={unprepItems}
        updateBestByDate={updateBestByDate}
        moveToPacker={moveToPacker}
        currentProduct={currentProduct}
        onChangeMultiSelect={onChangeMultiSelect}
        tenant={appState.tenant}
      />
    );
  };
};

const truncate = (str) => {
  return str && str.length > 30 ? str.substring(0, 30) + "..." : str;
};

export default withPrepperLogic;
export function handleFnSkuPrinting(
  appState,
  code,
  quantity,
  productName,
  bestByDate,
) {
  if (appState.subdomain === "e53") {
    appState.setAlert(`Triggering 30 up print`, "success", 3000);
    appState.setLoading();
    const fns = [];
    for (let i = 0; i < quantity; i++) {
      fns.push(code);
    }
    printThirtyUpLabel(
      fns,
      1,
      TENANT_FNSKU_LABEL_PRINTER_ENUM[appState.subdomain] ||
        TENANT_FNSKU_LABEL_PRINTER_ENUM.default,
      appState,
    );
  } else {
    printCanvasLabelPdf(
      `${code}`,
      quantity,
      null,
      TENANT_FNSKU_LABEL_PRINTER_ENUM[appState.subdomain] ||
        TENANT_FNSKU_LABEL_PRINTER_ENUM.default,
      TENANT_FNSKU_LABEL_PRINTING_SIZE_ENUM[appState.subdomain] ||
        TENANT_FNSKU_LABEL_PRINTING_SIZE_ENUM.default,
      appState,
      true,
      productName,
      bestByDate && `Best by: ${bestByDate}`,
    );
  }
}
