import React, { createContext, useState, useEffect } from "react";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";

const AppLoadingContext = createContext();

export const AppLoadingProvider = ({ children }) => {
  const [isAppLoading, setIsAppLoading] = useState(true);
  const flags = useFlags();
  const ldClient = useLDClient();

  useEffect(() => {
    const initializeApp = async () => {
      if (localStorage?.getItem("token") && ldClient && flags) {
        const timer = setTimeout(() => setIsAppLoading(false), 2000);
        return () => clearTimeout(timer);
      }
    };

    initializeApp();
  }, [ldClient, flags, localStorage?.getItem("token")]);

  return (
    <AppLoadingContext.Provider value={{ isAppLoading }}>
      {children}
    </AppLoadingContext.Provider>
  );
};

export { AppLoadingContext };
