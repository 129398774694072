import React from "react";
import { DatePicker } from "antd";
import { useEffect, useState, useRef } from "react";
const { RangePicker } = DatePicker;
import dayjs from "dayjs";

const DateRangePicker = ({
  label,
  floating = true,
  value = [],
  onChangeDateRange,
  handleDateRangeClear,
  pickerClasses,
  labelClasses = {
    before: "",
    after: "",
    common: "",
    nonFloatingClasses: "",
  },
  textClasses = "",
  displayText = "",
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const pickerRef = useRef(null);

  const handleOpen = () => {
    setIsFocused(true);
  };

  const handleClear = () => {
    setIsFocused(false);
    if (handleDateRangeClear) handleDateRangeClear();
    else onChangeDateRange([]);
  };

  const handleDateChange = (dates, dateStrings) => {
    // Ensure dates are in correct order regardless of timezone
    if (dates && dates.length === 2) {
      const startDate = dayjs(dates[0]);
      const endDate = dayjs(dates[1]);

      // If dates are reversed, swap them
      if (startDate.isAfter(endDate)) {
        onChangeDateRange([dateStrings[1], dateStrings[0]]);
      } else {
        onChangeDateRange(dateStrings);
      }
    } else {
      onChangeDateRange(dateStrings);
    }
    setIsFocused(false);
  };

  return (
    <div
      className={
        "relative rounded-md border border-gray-300 p-2 " + pickerClasses
      }>
      <label
        className={`absolute bg-white px-1 transition-all duration-200 ${labelClasses.common} ${
          floating
            ? isFocused || displayText
              ? labelClasses.after
              : labelClasses.before
            : labelClasses.nonFloatingClasses
        }`}
        style={{
          pointerEvents: "none",
        }}>
        {label}
      </label>
      <div className="relative h-full w-full cursor-pointer">
        <input
          type="text"
          readOnly
          value={displayText}
          className={`h-full w-full border-none bg-transparent focus:outline-none focus:ring-0 ${textClasses}`}
          onClick={handleOpen}
        />
        {displayText && (
          <button
            type="button"
            onClick={handleClear}
            className="absolute right-2 top-1/2 -translate-y-1/2 transform text-3xl text-gray-500 hover:text-gray-700">
            &times;
          </button>
        )}
      </div>
      <RangePicker
        ref={pickerRef}
        value={value}
        onChange={handleDateChange}
        open={isFocused}
        onOpenChange={(open) => {
          setIsFocused(open);
        }}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
          opacity: 0,
        }}
        {...props}
      />
    </div>
  );
};

export default DateRangePicker;
