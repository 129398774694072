import { useContext, useEffect, useRef, useState } from "react";
import Papa from "papaparse";
import SlideOverPanel from "#components/common/SlideOverPanel";
import CustomNotification from "#newUiComponents/commons/CustomNotification";
import ErrorNotification from "#newUiComponents/commons/ErrorNotification";
import CustomAlert from "#newUiComponents/commons/CustomAlert";
import { AppStateContext } from "#contexts/appState";
import { XCircleIcon } from "@heroicons/react/solid";
import moment from "moment-timezone";
export const FILE_SIZE_25_MB_VALIDATION = 25600; // 25 MB(25600 KB)
export const FILE_SIZE_1_MB_VALIDATION = 1024; // 1 MB (1024 KB)
export const FILE_ROWS_VALIDATION_10000 = 10001;
export const BULK_UPLOAD_DUPLICATE_VALIDATION = [];
export const BULK_UPLOAD_DUPLICATE_ROW_VALIDATION = ["SKU*", "Source*"];
export const BULK_UPLOAD_ANY_ONE_OPTIONAL_ROW_VALIDATION = [
  "Margin",
  "Shipping & Handling",
  "Labor Cost",
  "Discount",
];
export const fileColumns = [
  {
    key: "sku",
    name: "SKU*",
    associateColumns: "",
  },
  {
    key: "source",
    name: "Source*",
    associateColumns: "",
  },
  {
    key: "margin",
    name: "Margin",
    associateColumns: "",
  },
  {
    key: "marginType",
    name: "Margin Type (percentage/flat)(P/F)",
    associateColumns: "Margin",
  },
  {
    key: "shippingHandling",
    name: "Shipping & Handling",
    associateColumns: "",
  },
  {
    key: "laborCost",
    name: "Labor Cost",
    associateColumns: "",
  },
  {
    key: "discount",
    name: "Discount",
    associateColumns: "",
  },
  {
    key: "discountType",
    name: "Discount Type (percentage/flat)(P/F)",
    associateColumns: "Discount",
  },
];

const initialState = {
  selectedFile: null,
  tableHeaders: [],
  tableValues: [],
  errors: null,
  bulkUploadFileContentType: "text/csv",
  isFileVerify: false,
};

const CatalogManagementBulkUpload = ({
  pricingDataBulkUploadOpen,
  setPricingDataBulkUploadOpen,
  catalog,
  setCatalog,
}) => {
  const [isFileVerify, setIsFileVerify] = useState(false);
  const [selectedFileData, setSelectedFileData] = useState(null);

  const onSubmit = () => {
    const selectedFileMappedData = selectedFileData?.slice(1)?.map((row) =>
      row.reduce((acc, value, index) => {
        const column = fileColumns[index];
        if (column) acc[column.key] = value;
        return acc;
      }, {}),
    );

    const mappedDataDict = selectedFileMappedData?.reduce((acc, data) => {
      const key = `${data.sku}_${data.source}`; // Combine sku and source as unique key
      acc[key] = data;
      return acc;
    }, {});

    const updatedProducts = catalog?.products?.map((product) => {
      const key = `${product.sku}_${product.source}`;
      const matchedData = mappedDataDict?.[key]; // Fetch matched data in O(1) time
      if (matchedData) {
        return {
          ...product,
          lockedPriceInfo: {
            margin: matchedData?.margin ? Number(matchedData?.margin) : 0,
            shippingHandling: matchedData?.shippingHandling
              ? Number(matchedData?.shippingHandling)
              : 0,
            laborCost: matchedData?.laborCost
              ? Number(matchedData?.laborCost)
              : 0,
            discount: matchedData?.discount ? Number(matchedData?.discount) : 0,
            lockedInDate: moment().format("DD-MM-YYYY"),
            marginType:
              (matchedData?.marginType || "").toLowerCase() === "p"
                ? "percentage"
                : "flat",
            discountType:
              (matchedData?.discountType || "").toLowerCase() === "p"
                ? "percentage"
                : "flat",
          },
        };
      }
      return product;
    });

    setCatalog((prev) => ({
      ...prev,
      products: updatedProducts,
    }));
    setPricingDataBulkUploadOpen(false);
  };

  return (
    <SlideOverPanel
      open={pricingDataBulkUploadOpen}
      setOpen={setPricingDataBulkUploadOpen}
      title={<span className="text-2xl font-semibold">Upload File</span>}
      subTitle={
        <span className="text-base font-light text-gray-400">
          Please choose the file containing the product information that you
          would like to upload.
        </span>
      }
      containerStyle={"max-w-3xl"}>
      <div className="relative flex h-full w-full flex-col overflow-auto font-inter">
        <main className="my-2 h-full flex-grow overflow-auto">
          <FileUpload
            selectedCatalogProducts={catalog?.products || []}
            setSelectedFileData={setSelectedFileData}
            setIsFileVerify={setIsFileVerify}
          />
        </main>
        <footer className="flex h-16 items-center justify-end">
          <div className="flex space-x-4">
            <button
              className={`mr-2 cursor-pointer py-3 text-base font-semibold text-primaryAccent underline`}
              onClick={() => setPricingDataBulkUploadOpen(false)}>
              Cancel
            </button>
            <button
              onClick={() => {
                isFileVerify && onSubmit();
              }}
              className={`rounded-md px-6 py-3 text-base font-semibold ${
                isFileVerify
                  ? "cursor-pointer bg-primaryAccent text-white"
                  : "cursor-not-allowed border border-mediumGray text-mediumGray"
              }`}>
              Submit
            </button>
          </div>
        </footer>
      </div>
    </SlideOverPanel>
  );
};

const FileUpload = ({
  selectedCatalogProducts,
  setSelectedFileData,
  setIsFileVerify,
}) => {
  const fileInputRef = useRef(null);
  const notify = CustomNotification();
  const appState = useContext(AppStateContext);
  const [fileEntity, setFileEntity] = useState(initialState);

  const handleFileChange = async (file) => {
    setIsFileVerify(false);
    setSelectedFileData(null);
    if (!file || file.type !== fileEntity?.bulkUploadFileContentType) {
      notify.error("Invalid file.");
      return;
    }

    appState.setLoading();
    try {
      const selectedFileReadResponse = await readFileDetails(file);
      const fileSizeInMB = file?.size / FILE_SIZE_1_MB_VALIDATION;
      const fileDataLength = selectedFileReadResponse?.data?.length || 0;

      appState.removeLoading();

      if (
        fileSizeInMB > FILE_SIZE_25_MB_VALIDATION ||
        fileDataLength > FILE_ROWS_VALIDATION_10000
      ) {
        notify.error("File size exceeds 25 MB or 10000 rows.");
        return;
      }

      const fileData = selectedFileReadResponse?.data || [];
      const fileHeaders = fileData?.length > 0 ? fileData[0] : [];

      const requiredHeaders = fileColumns?.map((col) => col.name);

      const isValidColumns =
        fileHeaders?.length === requiredHeaders.length &&
        fileHeaders.every((header, index) => header === requiredHeaders[index]);

      if (!isValidColumns) {
        notify.error(
          "The uploaded file columns do not match the required format. Please ensure the file matches the template.",
        );
        return;
      }

      const duplicateHeaders = validateDuplicateFileHeaders(fileHeaders);
      if (duplicateHeaders?.length > 0) {
        notify.error(
          `The following duplicate headers were found: ${duplicateHeaders.join(", ")}`,
        );
        return;
      }

      setFileEntity((prevState) => ({
        ...prevState,
        selectedFile: file,
        tableHeaders: fileHeaders,
        tableValues: fileData?.length > 1 ? fileData.slice(1) : [],
        fileData: fileData?.length > 1 ? fileData : [],
      }));
    } catch (error) {
      appState.removeLoading();
      notify.error("Failed to read file details.");
    }
  };

  const VerifyFileDetails = async () => {
    if (!fileEntity?.selectedFile) return;
    appState.setLoading();

    try {
      const { tableHeaders, tableValues } = fileEntity;
      if (!tableHeaders?.length || !tableValues?.length) return;
      const fileErrorList = validateFileUploadData(
        tableValues,
        tableHeaders,
        selectedCatalogProducts,
      );
      setFileEntity((prevState) => ({
        ...prevState,
        errors: fileErrorList || [],
        isFileVerify: !fileErrorList?.length,
      }));
      if (!fileErrorList?.length) {
        setIsFileVerify(true);
        setSelectedFileData(fileEntity?.fileData);
      }
    } catch (error) {
      notify.error("An error occurred while processing the file.");
    } finally {
      appState.removeLoading();
    }
  };

  const readFileDetails = async (file) => {
    const fileReadResponse = await new Promise((resolve, reject) => {
      Papa.parse(file, {
        skipEmptyLines: true,
        complete: (parseData) => {
          if (parseData?.data && parseData.data?.length !== 0) {
            parseData.data[0] = parseData.data[0].map((header) =>
              header?.replace(/\n/g, " ")?.trim(),
            );
            parseData.data = parseData.data.filter((row) =>
              row.some((value) => value?.trim() !== ""),
            );
          }
          resolve(parseData);
        },
        error: reject,
      });
    });
    return fileReadResponse;
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = "copy";
  };

  const handleDrop = (event) => {
    resetUploadFile();
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileChange(file);
    }
  };

  const resetUploadFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    setFileEntity(initialState);
    setIsFileVerify(false);
    setSelectedFileData(null);
  };

  const validateDuplicateFileHeaders = (fileHeaders) => {
    const duplicates = fileHeaders.filter(
      (item, index) => fileHeaders.indexOf(item) !== index,
    );
    return [...new Set(duplicates)];
  };

  const validateFileUploadData = (
    fileDataList,
    fileHeaders,
    selectedProducts,
  ) => {
    const errorList = [];
    const uniqueRows = new Set();
    const columnData = {};

    // Helper Functions
    const addError = (type, message) => errorList.push({ type, message });

    const getColumnIndexes = (columns) =>
      columns
        ?.map((col) => fileHeaders?.indexOf(col))
        .filter((index) => index !== -1);

    const validateRequiredField = (cellValue, columnName, rowIndex) => {
      if (!cellValue?.trim()) {
        addError(
          "BLANK_DATA",
          `<span style="font-weight: 600">${columnName}</span> is missing at row ${rowIndex + 1}`,
        );
      }
    };

    const validateDuplicate = (
      columnIndex,
      cellValue,
      columnName,
      rowIndex,
    ) => {
      if (columnData[columnIndex]?.has(cellValue)) {
        addError(
          "DUPLICATE_DATA",
          `<span style="font-weight: 600">${columnName}</span> is duplicate at row ${rowIndex + 1}`,
        );
      } else {
        columnData[columnIndex].add(cellValue);
      }
    };

    const validateNumericField = (cellValue, columnName, rowIndex) => {
      if (isNaN(cellValue) || cellValue < 0 || /[a-zA-Z]/?.test(cellValue)) {
        const errorMessage = `<span style="font-weight: 600">${columnName}</span> must be ${cellValue < 0 ? "a positive number" : "numeric"} at row ${rowIndex + 1}`;
        addError("INVALID_DATA", errorMessage);
      }
    };

    // Check if SKU and Source match with selectedProducts
    const isMatchingProduct = (sku, source) => {
      return selectedProducts?.some(
        (product) => product?.sku === sku && product?.source === source,
      );
    };
    const getColumnName = (key) =>
      fileColumns?.find((column) => column?.key === key)?.name || "";

    // Validate column mismatch
    if (fileColumns?.length > fileHeaders?.length) {
      addError(
        "COLUMN_MISMATCH",
        `Your file has ${fileHeaders?.length} columns, but this template requires ${fileColumns?.length} columns. Download the template for reference`,
      );
      return errorList;
    }

    // Validate duplicate file headers
    validateDuplicateFileHeaders(fileHeaders).forEach((columnName) => {
      if (columnName) {
        addError(
          "Duplicate File Header Entry",
          `<span style="font-weight: 600">${columnName}</span> header is duplicate`,
        );
      }
    });

    // Prepare validation configurations
    const duplicateValueIndexes = getColumnIndexes(
      BULK_UPLOAD_DUPLICATE_VALIDATION,
    );
    duplicateValueIndexes?.forEach((index) => {
      columnData[index] = new Set();
    });

    const duplicateRowIndexes = getColumnIndexes(
      BULK_UPLOAD_DUPLICATE_ROW_VALIDATION || [],
    );
    const optionalColumns = BULK_UPLOAD_ANY_ONE_OPTIONAL_ROW_VALIDATION || [];

    fileDataList?.forEach((row, rowIndex) => {
      const filledOptionalColumns = optionalColumns?.filter((col) => {
        const colIndex = fileHeaders?.indexOf(col);
        return row[colIndex]?.trim();
      });

      // Validate each cell
      Object.keys(row)?.forEach((col) => {
        const colIndex = parseInt(col);
        const columnName = fileHeaders[colIndex];
        const cellValue = row[col]?.trim();

        if (columnName?.includes("*")) {
          validateRequiredField(cellValue, columnName, rowIndex);
        }

        if (duplicateValueIndexes?.includes(colIndex)) {
          validateDuplicate(colIndex, cellValue, columnName, rowIndex);
        }

        if (optionalColumns?.includes(columnName)) {
          validateNumericField(cellValue, columnName, rowIndex);
        }
      });

      // Extracting the column names
      const skuColumn = getColumnName("sku");
      const sourceColumn = getColumnName("source");

      // Helper function to safely get and trim value from row
      const getRowValue = (columnName) => {
        const index = fileHeaders?.indexOf(columnName);
        return index !== -1 ? row[index]?.trim() : null;
      };

      // Extracting sku and source values
      const sku = getRowValue(skuColumn);
      const source = getRowValue(sourceColumn);

      if (sku && source && !isMatchingProduct(sku, source)) {
        addError(
          "INVALID_PRODUCT",
          `<span style="font-weight: 600">${sku}</span> with source <span style="font-weight: 600">${source}</span> does not match any selected product at row ${rowIndex + 1}`,
        );
      }

      // Validate duplicate rows
      if (duplicateRowIndexes?.length) {
        const duplicateRowValue = duplicateRowIndexes
          ?.map((index) => row[index])
          ?.join(",");
        if (uniqueRows?.has(duplicateRowValue)) {
          const duplicateHeaders = duplicateRowIndexes?.map(
            (i) => fileHeaders[i],
          );
          addError(
            "DUPLICATE_ROW_DATA",
            `<span style="font-weight: 600">${(duplicateHeaders[0] || "")?.replace("*", "")}</span> is duplicate at row ${rowIndex + 1}`,
          );
        } else {
          uniqueRows?.add(duplicateRowValue);
        }
      }

      // Validate optional fields
      if (!filledOptionalColumns?.length && optionalColumns?.length) {
        addError(
          "BLANK_DATA",
          `<span style="font-weight: 600">At least one of ${optionalColumns?.join(
            ", ",
          )}</span> must have a value at row ${rowIndex + 1}`,
        );
      }

      const getColumnIndexByAssociateColumns = (name) => {
        const associateColumn =
          fileColumns?.find((col) => col?.name === name)?.associateColumns ||
          null;
        return fileHeaders?.indexOf(associateColumn) ?? -1; // Use `indexOf` directly for matching
      };

      // Additional validations (e.g., margin type, discount type, lock-in date)
      const validateConditionalField = (colIndex, validValues, label) => {
        const value = (row[colIndex]?.trim() || "")?.toLowerCase();

        if (!value || !validValues?.includes(value)) {
          addError(
            "INVALID_DATA",
            `<span style="font-weight: 600">${label}</span> must be ${validValues?.join(" or ")} at row ${rowIndex + 1}`,
          );
        } else if (value === "p") {
          const columnIndex = getColumnIndexByAssociateColumns(
            fileHeaders[colIndex],
          );
          if (columnIndex !== -1 && fileHeaders?.length) {
            const associateValue = row[columnIndex]?.trim();
            if (
              isNaN(associateValue) ||
              !(associateValue >= 0 && associateValue <= 100)
            ) {
              addError(
                "INVALID_DATA",
                `<span style="font-weight: 600">${fileHeaders[columnIndex]}</span> has an incorrect format at row ${rowIndex + 1}. The value must be between 0 and 100.`,
              );
            }
          }
        }
      };

      const marginTypeColIndex = fileHeaders?.indexOf(
        fileColumns.find((col) => col.key === "marginType")?.name,
      );
      if (filledOptionalColumns?.includes(fileColumns[2].name)) {
        validateConditionalField(
          marginTypeColIndex,
          ["p", "f"],
          fileColumns.find((col) => col?.key === "marginType")?.name,
        );
      }

      const discountTypeColIndex = fileHeaders?.indexOf(
        fileColumns?.find((col) => col?.key === "discountType")?.name,
      );
      if (filledOptionalColumns?.includes(fileColumns[6]?.name)) {
        validateConditionalField(
          discountTypeColIndex,
          ["p", "f"],
          fileColumns?.find((col) => col?.key === "discountType")?.name,
        );
      }
    });

    return errorList;
  };

  const validateTodayDate = (dateString) => {
    const userTimezone = moment.tz.guess();
    const today = moment().tz(userTimezone).format("YYYY-MM-DD");
    const isValidFormat = moment(dateString, "YYYY-MM-DD", true).isValid();
    const isToday = dateString === today;

    return isValidFormat && isToday;
  };

  return (
    <div className="mx-auto max-w-3xl p-4">
      <div
        className="mb-4 rounded-md border-2 border-dashed border-gray-300 bg-gray-100 p-4 text-center"
        onDragOver={handleDragOver}
        onDrop={handleDrop}>
        <label
          htmlFor="file-input"
          className="flex cursor-pointer flex-col items-center">
          <div className="mb-2 h-10 w-10">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#224E73"
              className="h-10 w-10">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
              />
            </svg>
          </div>
          <span className="text-gray-600">
            <span className="font-semibold">
              Drag &amp; drop files or{" "}
              <span
                className="text-[#224E73] underline"
                onClick={(e) => {
                  e.preventDefault();
                  resetUploadFile();
                  fileInputRef.current.click();
                }}>
                Browse
              </span>
            </span>
            <br />
            <span className="text-gray-400">
              Supported format: <span>CSV </span>
            </span>
          </span>
        </label>
        <input
          ref={fileInputRef}
          id="file-input"
          type="file"
          accept=".csv"
          onChange={(e) => handleFileChange(e.target.files[0])}
          onClick={resetUploadFile}
          className="hidden"
        />
      </div>

      {fileEntity?.selectedFile && (
        <div className="mb-4 flex items-center justify-start gap-4">
          <div className="bottom-1 flex w-2/3 items-center justify-between border-2 px-3 py-2">
            <p className="text-gray-600">{fileEntity?.selectedFile?.name}</p>
            <XCircleIcon
              onClick={resetUploadFile}
              className="bg-white-800 h-6 w-6 cursor-pointer text-gray-400"
            />
          </div>

          {!fileEntity?.errors && (
            <button
              className={`mr-2 py-3 font-semibold text-primaryAccent underline ${fileEntity?.isFileVerify ? "cursor-default" : "cursor-pointer"}`}
              onClick={
                !fileEntity?.isFileVerify ? VerifyFileDetails : undefined
              }>
              {fileEntity?.isFileVerify ? "Verified!" : "Verify!"}
            </button>
          )}

          {fileEntity?.errors && fileEntity?.errors?.length > 0 && (
            <button
              className="mr-2 cursor-pointer py-3 font-semibold text-primaryAccent underline"
              onClick={(e) => {
                e.preventDefault();
                resetUploadFile();
                fileInputRef.current.click();
              }}>
              Re-Upload!
            </button>
          )}
        </div>
      )}

      {fileEntity?.isFileVerify && (
        <CustomAlert
          id="alertForFileVerifySuccess"
          type="success"
          message={`Your file ${fileEntity?.selectedFile && fileEntity?.selectedFile?.name} is verified successfully.`}
          options={{
            defaultColors: false,
            bgColor: "bg-green-100",
            textColor: "text-green-700",
            titleColor: "text-green-500",
            borderColor: "border-green-100",
          }}
        />
      )}

      {fileEntity?.errors &&
        fileEntity?.errors?.length > 0 &&
        Array.isArray(fileEntity?.errors) && (
          <ErrorNotification
            errors={fileEntity?.errors}
            isErrorFromCatalog={true}
          />
        )}
    </div>
  );
};

export default CatalogManagementBulkUpload;
