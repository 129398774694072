import { useState, useContext } from "react";
import { InformationCircleIcon } from "@heroicons/react/outline";
import Modal from "#components/utils/Modal";
import Autocomplete from "#components/utils/Autocomplete";
import { SEARCH_LOCATIONS } from "#queries";
import { EXECUTE_INSTANT_BIN_TRANSFER } from "#mutations";
import { useQuery } from "#hooks/useQuery";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import _ from "lodash";
import TextField from "#components/utils/TextField";
import Switch from "#components/utils/Switch";

const ALERT_TIMEOUT_IN_MS = 5000;
const DEBOUNCE_TIME = 500;

const codeTypeMap = {
  lotId: "Lot/Batch ID",
  tote: "Tote",
  palletId: "Pallet",
  nestedFormFactorId: "LPN",
};

const BinTransferEligibilityModal = ({
  binTransferEligibility,
  onClose,
  onSuccess,
  checkTransferSkuBinMapping,
}) => {
  const appState = useContext(AppStateContext);
  const [binLocationOptions, setBinLocationOptions] = useState([]);
  const [selectedTransfer, setSelectedTransfer] = useState({
    skuBinMappingId: binTransferEligibility.selectedSkuBinMapping.id,
    deleteEmptyMappings: true,
    quantityToMove:
      binTransferEligibility.selectedSkuBinMapping.unallocatedQuantity,
  });

  const searchLocationsQuery = useQuery(SEARCH_LOCATIONS);
  const executeInstantBinTransferQuery = useQuery(EXECUTE_INSTANT_BIN_TRANSFER);

  const onChangeBinSearch = async (keyword) => {
    const locationsQuery = await searchLocationsQuery.fetchData({
      perPage: 10,
      pageNumber: 1,
      code: keyword || undefined,
      warehouses: [binTransferEligibility.selectedSkuBinMapping.warehouse],
      pagination: {
        limit: 10,
        offset: 0,
      },
    });
    setBinLocationOptions(
      locationsQuery?.data?.searchLocations?.data?.locations?.filter(
        (i) =>
          i.code !== binTransferEligibility.selectedSkuBinMapping.binLocation,
      ) || [],
    );
  };
  const debouncedBinLocationSearch = _.debounce(
    onChangeBinSearch,
    DEBOUNCE_TIME,
  );

  const submitTransfer = async () => {
    if (appState.loading) {
      appState.setAlert(
        `Transfer already in progress. Please wait.`,
        "info",
        ALERT_TIMEOUT_IN_MS,
      );
      return;
    }

    // validate quantity if editable
    if (binTransferEligibility.isQuantityEditable) {
      if (
        !selectedTransfer.quantityToMove ||
        selectedTransfer.quantityToMove <= 0
      ) {
        appState.setAlert(
          `Please enter a valid quantity greater than 0`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        return;
      }
      if (
        selectedTransfer.quantityToMove >
        binTransferEligibility.selectedSkuBinMapping.unallocatedQuantity
      ) {
        appState.setAlert(
          `Quantity to move cannot exceed unallocated quantity of ${binTransferEligibility.selectedSkuBinMapping.unallocatedQuantity}`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        return;
      }
    }

    // validate reason and bin location selected
    if (!selectedTransfer.binLocation) {
      appState.setAlert(
        `Please select a storage location`,
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
      return;
    }

    if (!selectedTransfer.reason) {
      appState.setAlert(`Please enter a reason`, "error", ALERT_TIMEOUT_IN_MS);
      return;
    }

    appState.setLoading();
    appState.setAlert(`Transfer initiated`, "success", ALERT_TIMEOUT_IN_MS);

    try {
      const payload = {
        skuBinMappingId: binTransferEligibility.selectedSkuBinMapping.id,
        binLocation: selectedTransfer.binLocation,
        reason: selectedTransfer.reason,
        deleteEmptyMappings: selectedTransfer.deleteEmptyMappings,
      };
      if (binTransferEligibility.isQuantityEditable) {
        payload.quantityToMove = selectedTransfer.quantityToMove;
      }
      const executeInstantBinTransfer =
        await executeInstantBinTransferQuery.fetchData(payload);
      if (executeInstantBinTransfer.error) {
        appState.setAlert(
          executeInstantBinTransfer.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        checkTransferSkuBinMapping(
          binTransferEligibility.selectedSkuBinMapping.id,
        );
        appState.removeLoading();
        return;
      }

      if (executeInstantBinTransfer.data) {
        appState.setAlert(
          executeInstantBinTransfer.data.executeInstantBinTransfer.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        onSuccess();
        appState.removeLoading();
      }
    } catch (e) {
      appState.setAlert(e.message, "error", ALERT_TIMEOUT_IN_MS);
      appState.removeLoading();
    }
  };

  return (
    <Modal
      title="Transfer Stock"
      onClose={onClose}
      positiveText={"Submit Transfer"}
      negativeText={"Cancel"}
      positiveAction={
        binTransferEligibility.isEligible &&
        binTransferEligibility.skuBinMappings.length > 0 &&
        submitTransfer
      }
      negativeAction={onClose}
      debounceSubmit={false}>
      <div className="flex flex-col items-center justify-center">
        <InformationCircleIcon
          className="h-20 w-20 rounded-full bg-primaryAccent p-4 font-light text-white"
          aria-hidden="true"
        />
        <h1 className="mt-4 text-center font-montserrat text-2xl text-454A4F">
          Transferring to another location.
        </h1>
      </div>
      {binTransferEligibility.isEligible &&
      binTransferEligibility.skuBinMappings.length > 0 ? (
        <div className="pt-2">
          <div className="mt-4">
            <TextField
              label="Reason"
              value={selectedTransfer.reason}
              onChange={(e) =>
                setSelectedTransfer({
                  ...selectedTransfer,
                  reason: e.target.value,
                })
              }
              autoFocus={false}
            />
          </div>

          {binTransferEligibility.isQuantityEditable && (
            <div className="mt-4">
              <TextField
                label="Quantity to Move"
                value={selectedTransfer.quantityToMove}
                onChange={(e) => {
                  const newValue =
                    e.target.value && !isNaN(parseInt(e.target.value))
                      ? parseInt(e.target.value)
                      : null;
                  setSelectedTransfer({
                    ...selectedTransfer,
                    quantityToMove: newValue,
                  });
                }}
                type="number"
                min={0}
              />
            </div>
          )}

          <div className="mt-4">
            <Autocomplete
              options={binLocationOptions}
              labelKey="code"
              valueKey="code"
              onChange={(e) =>
                setSelectedTransfer({
                  ...selectedTransfer,
                  binLocation: e,
                })
              }
              onKeyDown={(e) =>
                debouncedBinLocationSearch(
                  e,
                  binTransferEligibility.skuBinMappings[0].warehouse,
                )
              }
              value={selectedTransfer.binLocation}
              placeholder="Select Storage Location"
              id="BIN_LOCATION"
            />
          </div>
          <div className="mt-4 flex items-center space-x-2">
            <Switch
              enabled={selectedTransfer.deleteEmptyMappings}
              setEnabled={(e) =>
                setSelectedTransfer({
                  ...selectedTransfer,
                  deleteEmptyMappings: e,
                })
              }
            />
            <p className="text-xl text-black">
              Delete empty sku-bin mappings after transfer
            </p>
          </div>

          <h1 className="pt-4 text-center font-montserrat text-2xl text-green-500">
            The stock is eligible for transfer. All the inventory shown below
            will be transferred to the selected location.
          </h1>
          {binTransferEligibility.selectedSkuBinMapping.binLocation ===
            "VIRTUAL_DAMAGED_LOCATION" && (
            <h1 className="pt-4 text-center font-montserrat text-2xl text-red-500">
              Warning: This is currently marked as damaged inventory. Once
              transferred, it will be indistinguishable from 'Good' condition
              items in the system. This action cannot be undone, and the
              products' condition will no longer be tracked separately.
            </h1>
          )}
          <SkuBinMappingsTable tableData={binTransferEligibility} />
        </div>
      ) : (
        <div>
          <h1 className="pt-4 text-center font-montserrat text-2xl text-red-500">
            {binTransferEligibility.skuBinMappings &&
            binTransferEligibility.skuBinMappings.length > 0
              ? "The stock is not eligible for transfer because there's allocated quantity for some of the inventory affected by this transfer."
              : "There's no inventory for the current selection"}
          </h1>
          <SkuBinMappingsTable tableData={binTransferEligibility} />
        </div>
      )}
    </Modal>
  );
};

export const SkuBinMappingsTable = ({ tableData }) => {
  const auth = useContext(AuthContext);
  return (
    <div className="mt-4 overflow-x-auto">
      {tableData?.requestedEntityDetails && (
        <div>
          <h1 className="text-xl font-medium text-primaryAccent">
            Type:{" "}
            {codeTypeMap[tableData.requestedEntityDetails.codeType] || "-"}
          </h1>
          <h1 className="pt-2 text-xl font-medium text-primaryAccent">
            Code: {tableData.requestedEntityDetails.code}
          </h1>
          <h1 className="pb-2 pt-2 text-xl font-medium text-primaryAccent">
            Warehouse:{" "}
            {auth.findWarehouseById(
              tableData.requestedEntityDetails.warehouseId,
            )?.name || "-"}
          </h1>
        </div>
      )}
      {tableData?.skuBinMappings?.length > 0 && (
        <div>
          <table className="mt-2 w-full border-collapse">
            <thead>
              <tr className="bg-454A4F text-white">
                {tableData.skuBinMappings.some((i) => !!i.palletId) && (
                  <th className="px-4 py-2">Pallet ID</th>
                )}
                {tableData.skuBinMappings.some(
                  (i) => !!i.nestedFormFactorId,
                ) && <th className="px-4 py-2">LPN</th>}
                {tableData.skuBinMappings.some((i) => !!i.nestedFormFactor) && (
                  <th className="px-4 py-2">LPN Form Factor</th>
                )}
                <th className="px-4 py-2">Client</th>
                <th className="px-4 py-2">SKU</th>
                <th className="px-4 py-2">Location</th>
                <th className="px-4 py-2">Unallocated Qty</th>
                <th className="px-4 py-2">Allocated Qty</th>
                <th className="px-4 py-2">UoM</th>
                {tableData.skuBinMappings.some((i) => !!i.lotId) && (
                  <th className="px-4 py-2">Lot ID</th>
                )}
                {tableData.skuBinMappings.some((i) => !!i.bestByDate) && (
                  <th className="px-4 py-2">Expiry Date</th>
                )}
              </tr>
            </thead>
            <tbody>
              {tableData?.skuBinMappings?.map((skuBinMapping) => (
                <tr
                  key={skuBinMapping.id}
                  className={`${
                    !tableData.isEligible
                      ? "bg-red-500 text-white"
                      : skuBinMapping.allocatedQuantity > 0
                        ? "bg-amber-100"
                        : "bg-white"
                  }`}>
                  {tableData.skuBinMappings.some((i) => !!i.palletId) && (
                    <td className="border px-4 py-2">
                      {skuBinMapping.palletId ?? "-"}
                    </td>
                  )}
                  {tableData.skuBinMappings.some(
                    (i) => !!i.nestedFormFactorId,
                  ) && (
                    <td className="border px-4 py-2">
                      {skuBinMapping.nestedFormFactorId ?? "-"}
                    </td>
                  )}
                  {tableData.skuBinMappings.some(
                    (i) => !!i.nestedFormFactor,
                  ) && (
                    <td className="border px-4 py-2">
                      {skuBinMapping.nestedFormFactor ?? "-"}
                    </td>
                  )}
                  <td className="border px-4 py-2">
                    {auth.findCustomerById(skuBinMapping.customer)?.name || "-"}
                  </td>
                  <td className="border px-4 py-2">{skuBinMapping.sku}</td>
                  <td className="border px-4 py-2">
                    {skuBinMapping.binLocation ?? "-"}
                  </td>
                  <td className="border px-4 py-2">
                    {skuBinMapping.unallocatedQuantity ?? "-"}
                  </td>
                  <td className="border px-4 py-2">
                    {skuBinMapping.allocatedQuantity ?? "-"}
                  </td>
                  <td className="border px-4 py-2">
                    {skuBinMapping.formFactor ?? "-"}
                  </td>
                  {tableData.skuBinMappings.some((i) => !!i.lotId) && (
                    <td className="border px-4 py-2">
                      {skuBinMapping.lotId ?? "-"}
                    </td>
                  )}
                  {tableData.skuBinMappings.some((i) => !!i.bestByDate) && (
                    <td className="border px-4 py-2">
                      {skuBinMapping.bestByDate ?? "-"}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {tableData?.skuBinMappings?.length === 0 && (
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto border-collapse">
            <thead>
              <tr>
                <th className="border bg-gray-200 px-4 py-2">No Data</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border px-4 py-2">
                  No inventory available with the specified filters
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default BinTransferEligibilityModal;
