import { useState, useEffect, useContext } from "react";
import PageTitle from "#components/utils/PageTitle";
import _ from "lodash";
import BarcodeForm from "#components/receiver/BarcodeForm";
import ToteSelectForm from "#components/receiver/ToteSelectForm";
import moment from "moment-timezone";
import Dropdown from "#components/utils/Dropdown";
import ReceiverNotes from "./ReceiverNotes";
import TextField from "#components/utils/TextField";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";
import WorkflowProductImages from "#components/products/WorkflowProductImages";
import Modal from "#components/utils/Modal";
import { SUPPORTED_SIZES_WITHOUT_CANVAS } from "#utils/printCanvasLabelPdf";
import AmazonPrepInstructions from "#components/products/AmazonPrepInstructions";
import NumberField from "#components/utils/NumberField";
import ReactTooltip from "react-tooltip";
import AutocompleteSingleSelectDropdown from "#components/utils/AutocompleteSingleSelectDropdown";
import QRCode from "qrcode.react";
import Stats from "#components/common/Stats";
import AutoCompleteSingleSelect from "#newUiComponents/commons/AutoCompleteSingleSelect";
import { twMerge } from "tailwind-merge";
import Popover from "#components/utils/Popover";
import InventoryTrackingPanel from "./InventoryTrackingPanel";
import { uomConversationMatrix } from "./InventoryTrackingPanel";
import { AppStateContext } from "#contexts/appState";
import { XIcon } from "@heroicons/react/solid";
import {
  buildHierarchicalInventoryTracking,
  getAllPossibleLevels,
} from "#utils/inventoryTracking";

const actionTypeEnum = {
  ADD_INVENTORY_TRACKING: "ADD_INVENTORY_TRACKING",
  BUILD_STORAGE_UNIT: "BUILD_STORAGE_UNIT",
};

const AddingFormFactor = ({
  setAddingFormFactor,
  addingFormFactor,
  printCode,
  handleLpnAdded,
}) => {
  const { item, idx, updateItem } = addingFormFactor;

  return (
    <Modal
      title={`Add LPN to Product`}
      negativeAction={() => {
        setAddingFormFactor(null);
        updateItem(idx, "nestedFormFactor", null);
        updateItem(idx, "nestedFormFactorId", null);
        updateItem(idx, "dimensions", null);
        updateItem(idx, "numberOfCodes", null);
      }}
      positiveAction={handleLpnAdded}
      id="userFormModal">
      {item.nestedFormFactorId && (
        <img
          id={`label-placeholder-${item.nestedFormFactorId}`}
          style={{ position: "fixed", top: 10000 }}
        />
      )}
      <div>
        <label className="mb-2 block text-left">
          <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
            Form Factor
          </span>
        </label>
        <Dropdown
          // TODO: HOP-6031
          placeholder={"Select"}
          list={[{ name: "Case" }, { name: "Carton" }, { name: "Pallet" }]}
          labelKey="name"
          valueKey="name"
          name="formFactor"
          setSelected={(e) => updateItem(idx, "nestedFormFactor", e)}
          selectedValue={item.nestedFormFactor}
        />
      </div>
      <div className="mt-2 flex space-x-2">
        <input
          type="text"
          onChange={(e) =>
            updateItem(idx, "nestedFormFactorId", e.target.value)
          }
          placeholder="Code"
          value={item.nestedFormFactorId}
          className="p-4 text-lg"
        />
        <button
          type="button"
          className="inline-flex items-center rounded-md border-transparent bg-2C7695 px-4 py-2 font-montserrat text-lg font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0"
          onClick={() =>
            updateItem(
              idx,
              "nestedFormFactorId",
              Math.floor(10000000 + Math.random() * 90000000).toString(),
            )
          }>
          Auto-Generate
        </button>
      </div>
      {renderPrintOptions(item.nestedFormFactorId, item, printCode)}
    </Modal>
  );
};

const renderPrintOptions = (code, item, printCode) => {
  const supportedSizes = [...SUPPORTED_SIZES_WITHOUT_CANVAS, "4x6-LPN"];
  const [printOptions, setPrintOptions] = useState({});
  return (
    <>
      <div className="mt-4 font-montserrat text-lg font-medium text-454A4F">
        Optional Print
      </div>
      <div className="flex space-x-2">
        <Dropdown
          placeholder={"Select Dimensions"}
          list={_.uniq(supportedSizes).map((i) => ({
            name: i.replace("-LPN", ""),
            value: i,
          }))}
          labelKey="name"
          valueKey="value"
          name="printSize"
          setSelected={(e) =>
            setPrintOptions({ ...printOptions, dimensions: e })
          }
          selectedValue={printOptions.dimensions}
        />
        <NumberField
          onChange={(e) =>
            setPrintOptions({
              ...printOptions,
              numberOfCodes: parseInt(e.target.value),
            })
          }
          placeholder="# to print"
          value={printOptions.numberOfCodes}
          className="p-4 text-lg"
          min="0"
        />
      </div>
      {["4x6-LPN"].includes(printOptions.dimensions) && (
        <div className="mt-4 flex items-center space-x-2">
          <input
            type="checkbox"
            onChange={(e) =>
              setPrintOptions({
                ...printOptions,
                includeTrackingDetails: e.target.checked,
              })
            }
            checked={printOptions.includeTrackingDetails}
            className="h-6 w-6 rounded-md border-2 border-2C7695"
          />
          <span className="font-montserrat text-lg font-medium text-454A4F">
            Include Product and Tracking Details (eg. Name, Expiry, Lot ID,
            Serial Number)
          </span>
          <div
            id={`qrcode_${code}`}
            className="flex items-center justify-center">
            <QRCode
              value={code}
              size={100}
              level="M" // Add error correction level
              style={{
                padding: 16,
                backgroundColor: "white",
                width: "100px",
                height: "100px",
              }}
            />
          </div>
        </div>
      )}
      <div className="mt-4 flex space-x-2">
        <button
          type="button"
          className="inline-flex items-center rounded-md border-transparent bg-2C7695 px-4 py-2 font-montserrat text-lg font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0"
          onClick={() =>
            printCode(
              code,
              printOptions.numberOfCodes,
              printOptions.dimensions,
              true,
              printOptions.includeTrackingDetails
                ? { ...item, includeTrackingDetails: true }
                : null,
            )
          }>
          Print
        </button>
        <button
          type="button"
          className="inline-flex items-center rounded-md border-transparent bg-2C7695 px-4 py-2 font-montserrat text-lg font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0"
          onClick={() =>
            printCode(
              code,
              printOptions.numberOfCodes,
              printOptions.dimensions,
              false,
              printOptions.includeTrackingDetails
                ? { ...item, includeTrackingDetails: true }
                : null,
            )
          }>
          Download
        </button>
      </div>
    </>
  );
};

export const renderVariantDetails = (parentProduct, productVariant) => {
  if (!parentProduct) {
    return null;
  }

  if (!parentProduct.variantCharacteristics) {
    return null;
  }

  if (!productVariant.attributes) {
    return null;
  }

  return (
    <div className="m-auto flex w-full space-x-1 shadow-md">
      <div
        className={`flex w-64 items-end justify-end rounded-bl-md rounded-tl-md border-l-8 border-F4C261 bg-white p-4 text-2C7695`}>
        Variant Details
      </div>
      <div className={`flex-1 rounded-br-md rounded-tr-md border bg-white p-4`}>
        {Object.keys(productVariant.attributes).map((attribute) => {
          if (
            parentProduct.variantCharacteristics
              .map((i) => i.toLowerCase())
              .includes(attribute.toLowerCase())
          ) {
            return (
              <div key={attribute}>
                <span className="font-bold">{attribute?.toUpperCase()}:</span>{" "}
                {productVariant.attributes[attribute]}
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

const DetailedView = ({
  currentSku,
  confirmItem,
  cancelItem,
  scanBarcode,
  currentItems,
  setCurrentItems,
  updateItem,
  removeItem,
  addItem,
  specificConsignment,
  currentProduct,
  printCode,
  prepEnabled,
  confirmPrepItem,
  eligibleConsignments,
  tenant,
  setCurrentSku,
  currentParentProduct,
  handleLpnAdded,
  addingFormFactor,
  setAddingFormFactor,
  productSkuBinMappings,
  bulkPrintCodes,
}) => {
  const appState = useContext(AppStateContext);
  const [activeUomLabelsForProduct, setActiveUomLabelsForProduct] = useState(
    [],
  );
  const [manualValue, setManualValue] = useState(null);
  const [barcode, setBarcode] = useState(null);
  const [notes, setNotes] = useState(null);
  const [confirmedPrep, setConfirmedPrep] = useState(false);
  // Initialize state for each item in currentItems array
  const [productHasSerialNumberArray, setProductHasSerialNumberArray] =
    useState(currentItems.map(() => false));

  const handleSerialNumberChange = (index, item) => {
    const newProductHasSerialNumberArray = [...productHasSerialNumberArray];
    newProductHasSerialNumberArray[index] = item?.serialNumber?.length > 0;
    setProductHasSerialNumberArray(newProductHasSerialNumberArray);
  };

  const manageActionTypes = [
    {
      name: "Add Inventory Tracking",
      value: actionTypeEnum.ADD_INVENTORY_TRACKING,
    },
    {
      // TODO: RENAME TO `Add/Build Storage Unit` later
      name: "Add LPN",
      value: actionTypeEnum.BUILD_STORAGE_UNIT,
    },
    {
      name: "Add Notes",
      value: actionTypeEnum.ADD_NOTES,
    },
  ];

  const handleManageActionClick = (actionType, idx) => {
    switch (actionType) {
      case actionTypeEnum.ADD_INVENTORY_TRACKING:
        const item = currentItems[idx];
        // Only initialize if there's no existing tracking structure
        if (!item.inventoryTrackingIds?.length) {
          const levels = getAllPossibleLevels(
            currentProduct.uomConfig,
            item.formFactor,
          );
          const initialTrackingLevels = Object.fromEntries(
            levels.map((level, index) => [level, true]),
          );

          const initialInventoryTrackingIds =
            buildHierarchicalInventoryTracking(
              item.quantity,
              item.formFactor,
              currentProduct.uomConfig,
              "",
              1,
              initialTrackingLevels,
            );

          const updatedItem = {
            ...item,
            isAddingInventoryTracking: true,
            enableNestedTracking: item.enableNestedTracking ?? true,
            trackingLevels: initialTrackingLevels,
            inventoryTrackingIds: initialInventoryTrackingIds,
          };

          const clonedItems = [...currentItems];
          clonedItems[idx] = updatedItem;
          setCurrentItems(clonedItems);
        } else {
          // Just show the panel if tracking structure exists
          const clonedItems = [...currentItems];
          clonedItems[idx] = {
            ...item,
            isAddingInventoryTracking: true,
          };
          setCurrentItems(clonedItems);
        }
        break;
      case actionTypeEnum.BUILD_STORAGE_UNIT: {
        const item = currentItems[idx];
        setAddingFormFactor({ idx, item, updateItem });
        break;
      }
      case actionTypeEnum.ADD_NOTES: {
        const item = currentItems[idx];
        setNotes({
          ...item,
          idx,
          remarks: item.remarks || "",
        });
        break;
      }
    }
  };

  useEffect(() => {
    const applicableUomLabels = [
      currentProduct?.baseUom,
      ...(currentProduct?.uomConfiguration?.map((config) => config.targetUom) ??
        []),
    ];

    const uniqueLabels = [...new Set(applicableUomLabels)];

    setActiveUomLabelsForProduct(
      uniqueLabels.map((label) => ({ name: label })),
    );
  }, currentProduct);

  useEffect(() => {
    // Item can be added or removed from beginning, between or middle, so have to traverse the array to check for serial number
    const newProductHasSerialNumberArray = [];
    for (const item of currentItems) {
      item?.serialNumber?.length > 0
        ? newProductHasSerialNumberArray.push(true)
        : newProductHasSerialNumberArray.push(false);
    }
    setProductHasSerialNumberArray(newProductHasSerialNumberArray);
  }, [currentItems]);

  const formFactorsFromConsignment = [];
  if (!eligibleConsignments.length && specificConsignment) {
    eligibleConsignments = [specificConsignment];
  }

  if (eligibleConsignments) {
    for (const consignment of eligibleConsignments) {
      for (const item of consignment.workingList.filter(
        (i) => i.sku === currentSku.sku && i.availableQuantity > 0,
      )) {
        if (item.formFactor) {
          formFactorsFromConsignment.push(item.formFactor);
        }
      }
    }
  }

  const getExpectedQtyByUom = () => {
    if (!specificConsignment || !currentSku) return [];

    const skuItems = specificConsignment.workingList.filter(
      (item) => item.sku === currentSku.sku,
    );

    const groupedByUom = _.groupBy(skuItems, "formFactor");

    return Object.entries(groupedByUom).map(([uom, items]) => ({
      uom: uom || "Each", // Default to 'Each' if uom is not specified
      quantity: _.sumBy(items, "quantity"),
    }));
  };

  const getReceivedQtyByUom = () => {
    if (!specificConsignment || !currentSku) return [];

    const skuItems = specificConsignment.workingList.filter(
      (item) => item.sku === currentSku.sku,
    );

    const groupedByUom = _.groupBy(skuItems, "formFactor");

    return Object.entries(groupedByUom)
      .map(([uom, items]) => ({
        uom: uom || "Each", // Default to 'Each' if uom is not specified
        quantity:
          _.sumBy(items, "quantity") - _.sumBy(items, "availableQuantity"),
      }))
      .filter(({ quantity }) => quantity > 0);
  };

  return (
    <div className="mx-auto max-w-7xl flex-col items-center justify-center pb-32">
      {barcode && barcode?.field !== "tote" && (
        <BarcodeForm
          onChange={(e) =>
            setBarcode({
              ...barcode,
              data: e,
            })
          }
          onSubmit={() => {
            scanBarcode({ data: barcode.data });
            setBarcode(null);
          }}
          onClose={() => setBarcode(null)}
          title="Enter Value"
        />
      )}

      {addingFormFactor && (
        <AddingFormFactor
          addingFormFactor={addingFormFactor}
          setAddingFormFactor={setAddingFormFactor}
          printCode={printCode}
          handleLpnAdded={handleLpnAdded}
        />
      )}
      <div className="pt-8">
        <PageTitle>Product Details</PageTitle>
        <div className="grid grid-cols-3">
          <div
            className={`w-full flex-1 bg-gray-100 ${
              currentProduct?.images?.length > 0 ? "col-span-2" : "col-span-3"
            }`}>
            <div className="space-y-2 text-xl font-medium text-454A4F">
              {currentSku.asin && (
                <LineItemValue value={currentSku.asin} title="ASIN" idx={7} />
              )}

              <LineItemValue value={currentSku.name} title="Name" idx={2} />
              <LineItemValue value={currentSku.sku} title="SKU" idx={3} />
              {currentSku.fnSku && (
                <LineItemValue
                  value={currentSku.fnSku}
                  title="Fn SKU"
                  idx={4}
                />
              )}

              {currentSku.upc && (
                <LineItemValue
                  value={currentSku?.upc?.join(", ")}
                  title="UPC"
                  idx={8}
                />
              )}
              {currentParentProduct &&
                renderVariantDetails(currentParentProduct, currentSku)}
              {specificConsignment && (
                <>
                  {specificConsignment.notes && (
                    <LineItemValue
                      value={specificConsignment.notes}
                      title="Client Notes"
                      idx={7}
                    />
                  )}
                  {prepEnabled &&
                    currentProduct &&
                    currentProduct.prepInstructions &&
                    currentProduct.prepInstructions.length > 0 && (
                      <AmazonPrepInstructions
                        prepInstructions={currentProduct.prepInstructions}
                      />
                    )}
                </>
              )}
              {!specificConsignment?.dropship && (
                <>
                  <LineItemValueWithOptions
                    value={currentSku.tote}
                    title="Tote (optional)"
                    idx={5}
                    setBarcode={setBarcode}
                    field="tote"
                    scanBarcode={scanBarcode}
                    barcode={barcode}
                    clearValue={() =>
                      setCurrentSku({ ...currentSku, tote: null })
                    }
                  />
                  {tenant?.settings?.activities?.receiving
                    ?.allowBinLocationScan !== false && (
                    <LineItemValueWithOptions
                      value={currentSku.binLocation}
                      title="Bin (optional)"
                      idx={6}
                      setBarcode={setBarcode}
                      field="bin"
                    />
                  )}
                </>
              )}
              {!specificConsignment?.dropship && (
                <div className="flex-col items-center justify-center bg-white p-10 pb-5 pt-5">
                  <div>
                    <TextField
                      type="text"
                      id="name"
                      label={
                        tenant?.settings?.activities?.receiving
                          ?.allowBinLocationScan !== false
                          ? "Scan Tote/Bin"
                          : "Scan Tote"
                      }
                      placeholder=" "
                      onChange={(e) => setManualValue(e.target.value)}
                      value={manualValue}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          scanBarcode({ data: manualValue });
                          setManualValue("");
                        }
                      }}
                      autoFocus={true}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {currentProduct?.images && (
            <div className="flex flex-1 items-center justify-center bg-gray-300">
              <WorkflowProductImages
                images={[
                  currentProduct.images.find(
                    (i) => i.purpose === "OPERATIONS",
                  ) || currentProduct.images[0],
                ].filter(Boolean)}
              />
            </div>
          )}
        </div>
      </div>

      <div className="mt-8">
        <Stats
          stats={[
            {
              name: "Expected Qty(UoM)",
              typeOfValue: "component",
              renderValue: () => {
                const expectedQtyByUom = getExpectedQtyByUom();
                return (
                  <div className="flex-col">
                    {expectedQtyByUom.map(({ uom, quantity }) => (
                      <div key={uom} className="mr-2 flex flex-1 items-center">
                        {quantity}{" "}
                        <span className="pl-2 text-lg text-[#6E7173]">
                          {uom}
                        </span>
                      </div>
                    ))}
                  </div>
                );
              },
            },
            {
              name: "Received Qty(UoM)",
              typeOfValue: "component",
              renderValue: () => {
                const receivedQtyByUom = getReceivedQtyByUom();
                if (receivedQtyByUom.length === 0) {
                  return <div className="flex-col">0</div>;
                }
                return (
                  <div className="flex-col">
                    {receivedQtyByUom.map(({ uom, quantity }) => (
                      <div key={uom} className="mr-2 flex flex-1 items-center">
                        {quantity}{" "}
                        <span className="pl-2 text-lg text-[#6E7173]">
                          {uom}
                        </span>
                      </div>
                    ))}
                  </div>
                );
              },
            },
          ]}
        />
      </div>
      <div className="mt-8 flex">
        <div className="flex-1 font-inter text-2xl font-semibold text-gray-900">
          Receiving Info
          <>
            <br />
            <span className="text-lg font-normal text-[#717679]">
              To be detailed
            </span>
          </>
        </div>
        <div className="flex flex-none items-end justify-end space-x-2">
          <button
            className="cursor-pointer whitespace-nowrap rounded-lg border-2 border-primaryAccent bg-primaryAccent p-4 px-6 font-semibold text-white"
            onClick={addItem}>
            + Add Item
          </button>
        </div>
      </div>
      <div className="mt-8 flex-col space-y-4">
        {currentItems &&
          currentItems.map((item, idx) => (
            <div className="relative">
              {currentItems.length > 1 && (
                <div
                  className="absolute right-4 top-0"
                  onClick={() => removeItem(idx)}>
                  <div className="flex items-center space-x-4">
                    <div className="mt-4 flex cursor-pointer text-primaryAccent">
                      <XIcon className="h-6 w-6" />
                    </div>
                  </div>
                </div>
              )}

              <div
                className={twMerge(
                  "flex-col bg-white p-4 shadow-md transition-colors duration-1000 ease-out",
                  item.isHighlighted ? "bg-yellow-200" : "bg-white",
                )}
                id={`item-${idx}`}>
                <div className="flex items-center space-x-4">
                  <div className="w-40">
                    <label className="mb-2 block text-left">
                      <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                        Select UoM
                      </span>
                    </label>
                    <AutoCompleteSingleSelect
                      options={formFactorsFromConsignment.map((formFactor) => ({
                        name: formFactor,
                      }))}
                      value={item.formFactor}
                      onChange={(e) => {
                        // Update form factor and rebuild hierarchy if inventory tracking exists
                        if (item.inventoryTrackingIds?.length > 0) {
                          const newItems = buildHierarchicalInventoryTracking(
                            item.quantity,
                            e,
                            currentProduct.uomConfig,
                          );
                          updateItem(idx, "inventoryTrackingIds", newItems);
                        }
                        updateItem(idx, "formFactor", e);
                      }}
                      labelKey="name"
                      valueKey="name"
                      parentClasses="flex flex-col w-full cursor-pointer border border-gray-200 rounded-lg"
                      labelClasses="text-base text-gray-500"
                      displayValueClasses="text-xl p-4"
                      shouldDeselect={false}
                    />
                  </div>
                  <div className="w-40">
                    <label className="mb-2 block text-left">
                      <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                        Quantity
                      </span>
                    </label>
                    <input
                      className="w-full flex-1 rounded-md border border-gray-600 p-4 text-lg"
                      type="number"
                      value={item.quantity}
                      onChange={(e) => {
                        const newQuantity = parseInt(
                          productHasSerialNumberArray[idx] ||
                            item.attributes?.serialNumberRequired
                            ? 1
                            : e.target.value,
                        );

                        // Update quantity
                        updateItem(
                          idx,
                          "quantity",
                          !isNaN(newQuantity) ? newQuantity : null,
                          true,
                        );

                        // Rebuild hierarchy if inventory tracking exists
                        if (item.inventoryTrackingIds?.length > 0) {
                          const newItems = buildHierarchicalInventoryTracking(
                            newQuantity,
                            item.formFactor,
                            currentProduct.uomConfig,
                            "",
                            1,
                            item.trackingLevels,
                          );
                          updateItem(idx, "inventoryTrackingIds", newItems);
                        }
                      }}
                      placeholder="Qty"
                    />
                  </div>
                  <div className="w-48">
                    <label className="mb-2 block text-left">
                      <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                        Condition
                      </span>
                    </label>
                    <AutoCompleteSingleSelect
                      options={[
                        { name: "Good", status: "CONFIRMED" },
                        { name: "Used", status: "USED" },
                        { name: "Damaged", status: "DAMAGED" },
                        { name: "Box Damaged", status: "BOX_DAMAGED" },
                        { name: "No Box", status: "NO_BOX" },
                        { name: "Plain Box", status: "PLAIN_BOX" },
                        { name: "Wrong Item", status: "WRONG_ITEM" },
                      ]}
                      value={item.status}
                      onChange={(e) => updateItem(idx, "status", e)}
                      labelKey="name"
                      valueKey="status"
                      parentClasses="flex flex-col w-full cursor-pointer border border-gray-200 rounded-lg"
                      labelClasses="text-base text-gray-500"
                      displayValueClasses="text-xl p-4"
                      shouldDeselect={false}
                    />
                  </div>
                </div>
                {currentProduct &&
                  uomConversationMatrix(
                    currentProduct.uomConfig,
                    item.formFactor,
                    Math.max(item.quantity, 1),
                  ) !== null && (
                    <div className="mt-4 inline-block rounded-lg bg-[#F1F8FF] p-4 text-xl font-medium text-black">
                      {uomConversationMatrix(
                        currentProduct.uomConfig,
                        item.formFactor,
                        Math.max(item.quantity, 1),
                      )}
                    </div>
                  )}
                <div className="mt-4 flex w-full space-x-4">
                  {tenant?.settings?.activities?.receiving
                    ?.expiryDateEnabled !== false && (
                    <div>
                      <label className="mb-2 block text-left">
                        <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                          Expiry Date
                        </span>
                      </label>
                      <input
                        type="date"
                        onChange={(e) =>
                          updateItem(idx, "bestByDate", e.target.value)
                        }
                        placeholder="Select Expiry date"
                        value={item.bestByDate}
                        className="rounded-md p-4 text-lg"
                        min={moment().format("YYYY-MM-DD")}
                      />
                    </div>
                  )}

                  {tenant?.settings?.activities?.receiving?.lotIdEnabled !==
                    false && (
                    <div>
                      <label className="mb-2 block text-left">
                        <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                          Lot ID
                        </span>
                      </label>
                      <input
                        type="text"
                        onChange={(e) =>
                          updateItem(idx, "lotId", e.target.value)
                        }
                        placeholder="Lot ID"
                        value={item.lotId}
                        className="w-36 rounded-md p-4 text-lg"
                      />
                    </div>
                  )}
                  {tenant?.settings?.activities?.receiving?.poEnabled ===
                    true && (
                    <div>
                      <label className="mb-2 block text-left">
                        <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                          PO ID
                        </span>
                      </label>
                      <input
                        type="text"
                        onChange={(e) =>
                          updateItem(idx, "poId", e.target.value)
                        }
                        placeholder="PO ID"
                        value={item.poId}
                        className="w-36 rounded-md p-4 text-lg"
                      />
                    </div>
                  )}
                  {tenant?.settings?.activities?.receiving?.poEnabled ===
                    true && (
                    <div>
                      <label className="mb-2 block text-left">
                        <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                          PO Line ID
                        </span>
                      </label>
                      <input
                        type="text"
                        onChange={(e) =>
                          updateItem(idx, "lineItemId", e.target.value)
                        }
                        placeholder="PO Line ID"
                        value={item.lineItemId}
                        className="w-36 rounded-md p-4 text-lg"
                      />
                    </div>
                  )}
                  {tenant?.settings?.activities?.receiving
                    ?.serialNumberEnabled !== false && (
                    <div>
                      <label className="mb-2 block text-left">
                        <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                          Serial Number
                        </span>
                      </label>
                      <input
                        type="text"
                        onChange={(e) =>
                          updateItem(idx, "serialNumber", e.target.value)
                        }
                        placeholder="Serial Number"
                        value={item.serialNumber}
                        className="w-36 rounded-md p-4 text-lg"
                      />
                    </div>
                  )}
                  {(item.formFactor === "Pallet" ||
                    tenant?.settings?.activities?.receiving
                      ?.allowPalletIdAgainstLineItem === true) && (
                    <div>
                      <label className="mb-2 block text-left">
                        <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                          Pallet ID
                        </span>
                      </label>
                      <input
                        type="text"
                        onChange={(e) =>
                          updateItem(idx, "palletId", e.target.value)
                        }
                        placeholder="Pallet ID"
                        value={item.palletId}
                        className="w-36 rounded-md p-4 text-lg"
                      />
                    </div>
                  )}
                </div>

                <div className="mt-4 flex items-center space-x-4">
                  {item.nestedFormFactor && (
                    <>
                      <div className="font-montserrat text-xl">
                        LPN Form Factor: {item.nestedFormFactor}
                      </div>
                      <div className="font-montserrat text-xl">
                        LPN: {item.nestedFormFactorId}
                      </div>
                    </>
                  )}

                  {tenant?.settings?.activities?.receiving
                    ?.putawayBinLocation === true && (
                    <div>
                      <AutocompleteSingleSelectDropdown
                        options={productSkuBinMappings}
                        value={item.putawayBinLocation}
                        onChange={(e) =>
                          updateItem(idx, "putawayBinLocation", e)
                        }
                        labelKey="binLocation"
                        valueKey="binLocation"
                        placeholder={
                          item.putawayBinLocation || "Select Putaway Location"
                        }
                        showValueAsSelected={true}
                        clearSelection={() =>
                          updateItem(idx, "putawayBinLocation", "")
                        }
                      />
                    </div>
                  )}
                </div>
                <div className="flex items-center justify-end space-x-4 p-4">
                  <div className="flex items-center justify-center">
                    {(item.remarks || item.status !== "CONFIRMED") && (
                      <button
                        className="flex w-full cursor-pointer items-center justify-center gap-x-1 rounded-lg border-2 border-suspendedTextColor bg-suspendedTextColor px-6 py-4 font-medium text-white outline-none"
                        onClick={() =>
                          setNotes({
                            idx,
                            ...item,
                            remarks: item.remarks || "",
                          })
                        }>
                        View / Add Notes
                      </button>
                    )}
                  </div>
                  <Popover
                    title={"Add Details"}
                    showChevron={true}
                    panelClassName={
                      "mt-2 bg-white z-10 overflow-auto rounded-lg p-1 border border-primaryAccent w-64"
                    }
                    showOverlay={false}
                    buttonClassName="outline-none flex items-center border-2 justify-center font-medium gap-x-1 w-full bg-transparent text-primaryAccent rounded-lg py-4 px-6 cursor-pointer border-primaryAccent">
                    {manageActionTypes.map((actionType, index) => {
                      return (
                        <div
                          key={index}
                          className="w-full cursor-pointer rounded-lg p-3 font-medium text-unselectedTextGray hover:bg-hoverHighlight hover:text-primaryAccent"
                          onClick={() =>
                            handleManageActionClick(actionType.value, idx)
                          }>
                          {actionType.name}
                        </div>
                      );
                    })}
                  </Popover>
                </div>
              </div>
              {item.isAddingInventoryTracking && (
                <InventoryTrackingPanel
                  receivingItem={item}
                  product={currentProduct || {}}
                  submitInventoryTracking={(updatedItem) => {
                    const clonedItems = [...currentItems];
                    // Preserve ALL tracking-related properties
                    clonedItems[idx] = {
                      ...item,
                      ...updatedItem,
                      isAddingInventoryTracking: false,
                    };
                    setCurrentItems(clonedItems);
                  }}
                  closePanel={() => {
                    const clonedItems = [...currentItems];
                    // When closing, preserve the current state of enableNestedTracking
                    clonedItems[idx] = {
                      ...clonedItems[idx],
                      isAddingInventoryTracking: false,
                      enableNestedTracking:
                        clonedItems[idx].enableNestedTracking,
                    };
                    setCurrentItems(clonedItems);
                  }}
                  printCode={printCode}
                  bulkPrintCodes={bulkPrintCodes}
                />
              )}
            </div>
          ))}
      </div>

      <div className="mt-20 flex items-center justify-center space-x-6">
        {prepEnabled &&
          currentProduct?.prepInstructions?.length > 0 &&
          !confirmedPrep && (
            <div className="w-64 py-4 text-center text-2xl text-black">
              Have you prepped this item?
            </div>
          )}
        {prepEnabled && !confirmedPrep && currentProduct?.source === "FBA" && (
          <div
            className="w-64 rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
            onClick={() => {
              confirmPrepItem();
              setConfirmedPrep(true);
            }}>
            Yes
          </div>
        )}

        {(!prepEnabled || confirmedPrep) &&
          currentProduct?.source === "FBA" && (
            <div
              className="w-64 rounded-md bg-2C7695 py-4 text-center text-2xl text-white"
              onClick={() => confirmPrepItem(true)}>
              {tenant?.settings?.activities?.receiving?.disableAutomaticPrinting
                ? "Print FNSKU"
                : "Re-Print FNSKU"}
            </div>
          )}
      </div>
      {notes && (
        <ReceiverNotes
          title="Add Note"
          onClose={() => setNotes(null)}
          onSubmit={() => {
            updateItem(notes.idx, "remarks", notes.remarks);
            setNotes(null);
          }}
          notes={notes.remarks}
          onChange={(e) =>
            setNotes({
              ...notes,
              remarks: e.target.value,
            })
          }
        />
      )}
      <div className="fixed bottom-0 left-0 right-0 w-full bg-white p-10">
        <div className="flex flex-none items-end justify-end space-x-2">
          <button
            className="cursor-pointer whitespace-nowrap rounded-lg border-2 border-primaryAccent p-4 px-6 font-semibold text-primaryAccent"
            onClick={cancelItem}>
            Cancel Receiving
          </button>
          <button
            className={`${
              !prepEnabled || confirmedPrep || currentProduct?.source !== "FBA"
                ? "border-primaryAccent bg-primaryAccent text-white"
                : "border-gray-200 bg-gray-100 text-gray-400"
            } cursor-pointer whitespace-nowrap rounded-lg border-2 p-4 px-6 font-semibold`}
            onClick={confirmItem}>
            Finish Receiving
          </button>
        </div>
      </div>
    </div>
  );
};

const LineItemValue = ({ title, value, idx }) => (
  <div className="m-auto flex w-full space-x-1 shadow-md" key={idx}>
    <div
      className={`flex w-64 items-end justify-end rounded-bl-md rounded-tl-md border-l-8 bg-white p-4 text-2C7695 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div className={`flex-1 rounded-br-md rounded-tr-md border bg-white p-4`}>
      {["ASIN"].includes(title)
        ? buildMarketplaceHyperlink(value, title)
        : value}
    </div>
  </div>
);

const LineItemValueWithOptions = ({
  title,
  value,
  idx,
  setBarcode,
  field,
  scanBarcode,
  barcode,
  clearValue,
}) => (
  <div className="m-auto flex w-full space-x-1 shadow-md" key={idx}>
    <div
      className={`flex w-64 items-end justify-end rounded-bl-md rounded-tl-md border-l-8 bg-white p-4 text-2C7695 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div className="flex-1 space-x-4 rounded-br-md rounded-tr-md border bg-white p-4">
      {field !== "tote" || value ? (
        <>
          {value}
          {field === "tote" && (
            <button
              type="button"
              className="ml-4 inline-flex items-center rounded-md font-montserrat text-lg font-medium text-red-600 outline-none ring-0 focus:outline-none focus:ring-0"
              onClick={clearValue}>
              {value ? "Change" : "Enter"}
            </button>
          )}
        </>
      ) : (
        <ToteSelectForm
          onChange={(e) => scanBarcode({ data: e })}
          onSubmit={() => {
            scanBarcode({ data: barcode.data });
          }}
          title="Enter Value"
          value={value}
        />
      )}
    </div>
    {field !== "tote" && (
      <div className="flex-1 rounded-br-md rounded-tr-md border bg-white p-4">
        Scan or{" "}
        <span
          className="cursor-pointer text-blue-600"
          onClick={() => setBarcode({ data: "" })}>
          Enter Manually
        </span>
      </div>
    )}
  </div>
);

export default DetailedView;
