/**
 * created by Omkar Gavade
 * 19th July 2024
 *
 * This is a new modal component for the application.
 */

import { useState, Fragment } from "react";
import { XIcon } from "@heroicons/react/outline";
import { Transition, Dialog } from "@headlessui/react";
import PropTypes from "prop-types";

const NewModal = ({
  children,
  isOpen,
  onClose,
  title,
  subtitle,
  minWidth = "640px",
  maxWidth = "1280px",
  maxHeight = "480px",
  resetModal,
  childrenStyle,
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="userFormModal fixed inset-0 z-30 overflow-y-auto"
        initialFocus={null}
        onClose={onClose}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
          </Transition.Child>
          <div className="flex min-h-screen items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-0"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <div
                className={`mx-auto inline-block transform overflow-visible rounded-3xl border-blue-500 bg-white p-8 text-left align-middle shadow-xl transition-all`}
                style={{ minWidth, maxWidth }}>
                <div className="flex items-center justify-between pb-4 font-inter">
                  <div>
                    <span className="font-inter text-2xl font-bold text-[#454A4F]">
                      {title || "Modal Title"}
                    </span>
                    {subtitle && (
                      <div className="text-gray-500">{subtitle}</div>
                    )}
                  </div>
                  <div className="flex flex-row items-center justify-between">
                    {resetModal}
                    <XIcon
                      className="h-6 w-6 cursor-pointer"
                      onClick={onClose}
                    />
                  </div>
                </div>
                <div
                  style={{ maxHeight, ...childrenStyle }}
                  className="max-w-content flex flex-col gap-8 space-y-4 overflow-auto">
                  {children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

NewModal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  maxHeight: PropTypes.string,
  resetModal: PropTypes.node,
  childrenStyle: PropTypes.object,
};

export default NewModal;
