import CustomTable from "#newUiComponents/commons/CustomTable";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";
import { useMemo, useState } from "react";
import CheckBox from "#newUiComponents/commons/CheckBox";

const BoxItemsTable = ({ items, assignedQuantities, onConfirm }) => {
  const [tempQuantities, setTempQuantities] = useState({});
  const [isAutoPack, setIsAutoPack] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  // Helper function to get available quantity for an item
  const getAvailableQuantity = (item) => {
    return item.quantity - (assignedQuantities[item.uniqueId] || 0);
  };

  // Handle auto-pack checkbox change
  const handleAutoPackChange = (e) => {
    const checked = e.target.checked;
    setIsAutoPack(checked);

    if (checked) {
      // Set all quantities to their available amounts
      const newQuantities = {};
      items.forEach((item) => {
        newQuantities[item.uniqueId] = getAvailableQuantity(item);
      });
      setTempQuantities(newQuantities);
    } else {
      // Reset all quantities to 0
      const newQuantities = {};
      items.forEach((item) => {
        newQuantities[item.uniqueId] = 0;
      });
      setTempQuantities(newQuantities);
    }
  };

  const columns = [
    {
      key: "sku",
      title: "SKU",
    },
    {
      key: "name",
      title: "Product Name",
    },
    {
      key: "quantity",
      title: "Order Quantity",
    },
    {
      key: "availableQuantity",
      title: "Available Quantity",
      render: (_, row) => getAvailableQuantity(row),
    },
    {
      key: "packQuantity",
      title: "Pack Quantity",
      render: (_, row) => {
        const available = getAvailableQuantity(row);
        return (
          <input
            type="number"
            min="0"
            max={available}
            className="w-24 rounded border border-gray-300 p-1 focus:border-primaryAccent focus:ring focus:ring-primaryAccent"
            value={tempQuantities[row.uniqueId] || 0}
            onChange={(e) => {
              const value = Math.min(
                available,
                Math.max(0, parseInt(e.target.value) || 0),
              );
              setTempQuantities((prev) => ({
                ...prev,
                [row.uniqueId]: value,
              }));
              // If the value is not equal to available quantity, uncheck the auto-pack
              if (value !== available) {
                setIsAutoPack(false);
              }
            }}
          />
        );
      },
    },
  ];

  const filteredData = useMemo(() => {
    if (!searchTerm || searchTerm.trim() === "") {
      return items;
    }

    const searchTermLower = searchTerm.toLowerCase().trim();

    return items.filter(
      (item) =>
        item.asin?.toLowerCase().includes(searchTermLower) ||
        item.sku?.toLowerCase().includes(searchTermLower) ||
        item.fnSku?.toLowerCase().includes(searchTermLower) ||
        item.name?.toLowerCase().includes(searchTermLower) ||
        item.upc?.includes(searchTermLower) ||
        item.lpn?.includes(searchTermLower),
    );
  }, [items, searchTerm]);

  return (
    <div className="flex h-full flex-col p-6 font-inter">
      <div className="mb-4 flex items-center">
        <CheckBox
          checked={isAutoPack}
          onChange={handleAutoPackChange}
          id="auto-pack"
        />
        <label
          htmlFor="auto-pack"
          className="ml-2 cursor-pointer text-sm text-gray-500">
          Pack Qty same as Available qty
        </label>
      </div>

      <div className="flex-grow overflow-auto">
        <CustomTable
          columns={columns}
          data={filteredData}
          isSearchable={true}
          searchPlaceholder={"Search by ASIN/SKU/FNSKU/Name"}
          onChangeSearchTerm={(searchText) => {
            setSearchTerm(searchText);
          }}
        />
      </div>

      <div className="mt-4 flex justify-end gap-4 border-t pt-4">
        <PrimaryButton
          height="2.5rem"
          width="7rem"
          variant="secondary"
          onClick={() => onConfirm(null)}>
          Cancel
        </PrimaryButton>
        <PrimaryButton
          height="2.5rem"
          width="7rem"
          variant="primary"
          onClick={() => onConfirm(tempQuantities)}>
          Confirm
        </PrimaryButton>
      </div>
    </div>
  );
};

export default BoxItemsTable;
