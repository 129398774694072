import React, { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import NewModal from "#newUiComponents/commons/NewModal";

const OrderDetailsModal = ({
  isOpen,
  onClose,
  orders,
  customers,
  hideShippingAddress = false,
}) => {
  const [expandedOrders, setExpandedOrders] = useState({});

  const toggleOrder = (orderId) => {
    setExpandedOrders((prev) => ({
      ...prev,
      [orderId]: !prev[orderId],
    }));
  };

  const renderShippingAddress = (address) => {
    if (!address) return "No shipping address available";
    const parts = [];
    if (address.line1) parts.push(address.line1);
    if (address.line2) parts.push(address.line2);
    if (address.city) parts.push(address.city);
    if (address.state) parts.push(address.state);
    if (address.country) parts.push(address.country);
    if (address.zip) parts.push(address.zip);
    return parts.join(", ") || "No shipping address available";
  };

  const renderOrderItems = (orderDetails) => {
    if (!orderDetails || orderDetails.length === 0) return "No items available";

    return (
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-500">
              SKU
            </th>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-500">
              NAME
            </th>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-500">
              FORM FACTOR
            </th>
            <th className="px-4 py-2 text-right text-sm font-medium text-gray-500">
              QTY
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {orderDetails.map((item, index) => (
            <tr key={index} className="hover:bg-gray-50">
              <td className="px-4 py-2 text-sm text-gray-900">{item.sku}</td>
              <td className="px-4 py-2 text-sm text-gray-900">
                {item.name || "-"}
              </td>
              <td className="px-4 py-2 text-sm text-gray-900">
                {item.formFactor || "-"}
              </td>
              <td className="px-4 py-2 text-right text-sm text-gray-900">
                {item.quantity}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <NewModal
      isOpen={isOpen}
      onClose={onClose}
      title="Orders"
      maxHeight="80vh"
      minWidth="800px">
      <div className="flex flex-col space-y-4 font-inter">
        {Object.values(orders).map((order) => (
          <div
            key={order.id}
            className="rounded-lg border border-gray-200 bg-white shadow-sm">
            {/* Order Header */}
            <div
              className="flex cursor-pointer items-center justify-between p-4"
              onClick={() => toggleOrder(order.id)}>
              <div className="grid grid-cols-4 gap-4 text-sm">
                <div>
                  <div className="font-medium text-gray-500">Order ID</div>
                  <div>{order.orderId}</div>
                </div>
                <div>
                  <div className="font-medium text-gray-500">Client</div>
                  <div>
                    {(customers || []).find((c) => c.id === order.customer)
                      ?.name || "N/A"}
                  </div>
                </div>
                <div>
                  <div className="font-medium text-gray-500">Source</div>
                  <div>{order.source}</div>
                </div>
                <div>
                  <div className="font-medium text-gray-500">Date</div>
                  <div>{new Date(order.orderDate).toLocaleDateString()}</div>
                </div>
              </div>
              {expandedOrders[order.id] ? (
                <ChevronUpIcon className="h-5 w-5 text-gray-500" />
              ) : (
                <ChevronDownIcon className="h-5 w-5 text-gray-500" />
              )}
            </div>

            {/* Order Details */}
            {expandedOrders[order.id] && (
              <div className="border-t border-gray-200 p-4">
                {!hideShippingAddress && (
                  <div className="mb-4">
                    <h3 className="mb-2 text-sm font-medium text-gray-500">
                      Shipping Address
                    </h3>
                    <p className="text-sm text-gray-900">
                      {renderShippingAddress(order.shippingAddress)}
                    </p>
                  </div>
                )}
                <div>
                  <h3 className="mb-2 text-sm font-medium text-gray-500">
                    Order Items
                  </h3>
                  {renderOrderItems(order.orderDetails)}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </NewModal>
  );
};

export default OrderDetailsModal;
